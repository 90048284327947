export default {
	title: "The Boss",
	rating: "5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>beef patty</li>
					<li>truffle aioli</li>
					<li>Stilton cheese</li>
					<li>caramelized onion marmalade</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Some may argue it would be preposterous to claim this is one of humanity's greatest achievements. I disagree.",
			content: `
				<p>I like easy reviews. And this one is as easy as they come. Meat blend? Perfect. Bun? Perfect. Other stuff? Perfect. Balance? Perfect. There was only one person I introduced to this burger who didn't immediately declare their favorite. They went with "top three".</p>
				<p>The only (illegitimate) complaint one might possibly have against this chef-d'œuvre will stem from their dislike of truffles. Which - if you think about it - is actually another benefit of this burger, since you can henceforth safely disregard any opinions about food such person might have.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | February 2023"
		},
		{
			type: "image",
			url: require("./The Boss.jpg")
		}
	]
}
