export default {
	title: "Holy Cow Burger",
	rating: "3",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>	
					<li>American Angus beef patty</li>
					<li>house mayo with wasabi</li>
					<li>onions</li>
					<li>lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A fair attempt entirely overshadowed by the far superior flagship.",
			content: `
				<p>A lesser version of the <a href="/ranking/kralj-zara/dry-aged-holy-cow-burger" class="reference">Dry Aged Holy Cow Burger</a> in every important aspect. Well, the meat and onions, specifically. It's a solid contender on its own but I would skip every time it in favour of its far superior counterpart. It's not even close.</p>
				<p>And the price difference is so small that you might as well splurge that extra cash to get the superior model. You'll probably need to take out a loan well outside what you can afford regardless of which one you go for. But you know what? Totally worth it.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2016"
		}
	]
}
