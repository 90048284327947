import Klasik from "./Klasik/Klasik.js"

export default {
	title: "Dvorni Bar",
	tags: [],
	burgers: [
		Klasik
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://dvornibar.net/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/GuinnessPub.DvorniBar/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d3812736-Reviews-Dvorni_Bar-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
