export default {
	title: "Krušič Burger",
	rating: "2.5",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["horse"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>horse meat patty</li>
					<li>horse bacon</li>
					<li>house sauce</li>
					<li>onions</li>
					<li>lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "So-so.",
			content: `
				<p>The meat turned out pretty well, given that it was well done. Everything else was pretty average. Except the price - it's a bit high.</p>
				<p>I know this isn't a satisfying review but I just don't have much to say.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | 2017"
		},
		{
			type: "image",
			url: require("./Krusic.jpg")
		}
	]
}
