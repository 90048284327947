import LogistBurger from "./Logist Burger/Logist Burger.js"

export default {
	title: "Pišek Bar",
	tags: [],
	burgers: [
		LogistBurger
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.pisekbar.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/PisekBar/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274868-d15693900-Reviews-Pisek_Bar-Celje_Styria_Region.html"
		}
	],
	review: ""
}
