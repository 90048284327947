export default {
	title: "Billy Bob Bacon",
	rating: "3.5",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>grass-fed beef patty</li>
					<li>BBQ sauce</li>
					<li>crispy pancetta</li>
					<li>apple slaw</li>
					<li>tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Bacon. Coleslaw. BBQ sauce. Cheddar. What's not to like?",
			content: `
				<p>Hood introduces this as - to paraphrase - a taste of the American South. I've never been there. But if this is truly representative, I must say I'm not opposed to visiting.</p>
				<p>The first thing I noticed was BBQ sauce - or rather, the relative lack thereof. These days, when I see BBQ sauce on the spec sheet, the feeling I get is generally one of concern. In most cases BBQ sauce becomes 90% of the taste. Not so here. This one was subtle and adds just enough to add to the balance and no more. The pancetta was perfect, both, in taste and in texture. The apple slaw added the much needed freshness. There really isn't a lot of room to complain.</p>
				<p>I have only one minor gripe. The tomato was a bit of a miss in my opinion. It clashed with the BBQ sauce. If they used a different sauce it probably would have worked better. So my advice is to order without it.</p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;"><strong>Warning:</strong> I have noticed the taste was pretty substantially different almost every time I ordered it (four times as of this writing) so I had to lower the score a bit. Will revisit if consistency is improved in the future.</div>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | May 2018"
		},
		{
			type: "image",
			url: require("./Billy Bob Bacon.jpg")
		}
	]
}
