import Balbo from "./Balbo/Balbo.js"
import Balbo2022 from "./Balbo (2022)/Balbo (2022).js"
import BigHood from "./Big Hood/Big Hood.js"
import BillyBobBacon from "./Billy Bob Bacon/Billy Bob Bacon.js"
import Chicken from "./Chicken/Chicken.js"
import ChilliCheeseSteakSandwich from "./Chilli Cheese Steak Sandwich/Chilli Cheese Steak Sandwich.js"
import Classic from "./Classic/Classic.js"
import DeLaBoca from "./De La Boca/De La Boca.js"
import FatboySlim from "./Fatboy Slim/Fatboy Slim.js"
import HoodChick from "./Hood Chick/Hood Chick.js"
import HoodSteak from "./Hood Steak/Hood Steak.js"
import JakenJay from "./Jake'n'Jay/Jake'n'Jay.js"
import JohnGoat from "./John Goat/John Goat.js"
import Kahuna from "./Kahuna/Kahuna.js"
import LadyMarmalade from "./Lady Marmalade/Lady Marmalade.js"
import LeBrie from "./Le Brie/Le Brie.js"
import LouisLunch from "./Louis Lunch/Louis Lunch.js"
import TheCubanSandwich from "./The Cuban Sandwich/The Cuban Sandwich.js"
import TheDuke from "./The Duke/The Duke.js"
import TheDuke2 from "./The Duke 2/The Duke 2.js"
import TheDuke20 from "./The Duke 2.0/The Duke 2.0.js"
import TheDukeII from "./The Duke II/The Duke II.js"
import TheKliffhanger from "./The Kliffhanger/The Kliffhanger.js"
import DerWurstmeister from "./Der Wurstmeister/Der Wurstmeister.js"

export default {
	title: "Hood Burger",
	tags: [],
	burgers: [
		TheDuke20,
		TheDukeII,
		Classic,
		JakenJay,
		TheDuke2,
		ChilliCheeseSteakSandwich,
		LeBrie,
		Balbo2022,
		BigHood,
		DeLaBoca,
		BillyBobBacon,
		Chicken,
		Balbo,
		HoodSteak,
		FatboySlim,
		LouisLunch,
		JohnGoat,
		LadyMarmalade,
		HoodChick,
		TheKliffhanger,
		TheDuke,
		DerWurstmeister,
		Kahuna,
		TheCubanSandwich
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.hoodburger.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/hoodburger.ljubljana/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d4216760-Reviews-Hood_Burger-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
