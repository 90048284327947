import Burger from "./Burger/Burger.js"

export default {
	title: "Zanoodle",
	tags: [],
	burgers: [
		Burger
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/zanoodle/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
