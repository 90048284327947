export default {
	title: "Mac 'n' Cheese",
	rating: "3",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>ketchup</li>
					<li>macaroni & cheese</li>
					<li>lettuce</li>
					<li>tomato</li>
					<li>onions</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "There are some things best left separate.",
			content: `
				<p>There is a somewhat ironic issue plaguing this burger: the addition of macaroni and cheese. Take those away and you got yourself a fine burger.</p>
				<p>Now, don't get me wrong, it's not that the mac & cheese are bad - quite the opposite. It's just that they don't belong in a burger. Sure, I'm open to the opportunity that there may be something to this idea. But if there is, this isn't it.</p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;"><strong>Warning:</strong> if you are considering Stari Pisker's offerings at <a href="https://www.pivoinburgerfest.si/" target="_blank" class="callback">Pivo & Burger Fest</a>, do yourself a favor and don't. While their burgers were some of the best in class, this unfortunately no longer applies, at least not to Burgerfests. They have decided on a brilliant strategic move of prioritizing profits at the expense of their reputation and now produce only well done and super dry meat patties. Therefore, I can no longer recommend them at any venue outside their restaurant. Disregard the existing review/score in any other context.</div>

			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | 2018 (Uncertain)"
		},
		{
			type: "image",
			url: require("./Mac 'n' Cheese.jpg")
		}
	]
}
