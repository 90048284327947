export default {
	title: "Burger s slovensko govedino",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>mustard-truffle sauce</li>
					<li>BBQ sauce</li>
					<li>Cheddar cheese</li>
					<li>caramelized onions</li>
					<li>coleslaw</li>
					<li>rucola</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Another case of ordering a burger based on its picture. Another case of expectations being shattered in the face of reality.",
			content: `
				<p>To be fair, I ordered it delivered. But it looked really sad even even in that context.</p>
				<p>In a twist of fate, however, it actually turned out to be not horrible. The sauce was good. The meat was a little tough but not too bad. The caramelized onions were present but in smaller quantities than anticipated. Cheese was much the same.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | July 2019"
		},
		{
			type: "image",
			url: require("./Burger s slovensko govedino.jpg")
		}
	]
}
