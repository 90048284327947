export default {
	title: "Klasik Burger",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>butter bun</li>
					<li>beef batty</li>
					<li>house sauce</li>
					<li>Cheddar cheese</li>
					<li>iceberg lettuce</li>
					<li>Lušt lomatoes</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Every love story has its dark days.",
			content: `
				<p>This was the first burger from Bazen I wasn't immediately sold on.</p>
				<p>The patty was dry - not good but I can write that off due to the nature of P&BF. What really disappointed me, however, was the sauce. Very acidic. Ruined the whole burger for me.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | May 2023"
		},
		{
			type: "image",
			url: require("./Klasik.png")
		}
	]
}
