export default {
	title: "Wild Onion Burger",
	rating: "4.5",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>fresh sesame bread bun</li>
					<li>2x beef patty</li>
					<li>garlic mayo</li>
					<li>grilled onions</li>
					<li>2x Cheddar cheese</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Two words: onion overload. And I mean that in the best possible sense.",
			content: `
				<p>Let me start this review off by dispensing with some semantics: it says "grilled onions" on the menu. They're actually caramelized. Which is actually even better. But technically incorrect.</p>
				<p>Moving on - and straight to the point - this is the best burger at Wild Brothers. There are no serious flaws with this masterpiece. The only <span class="emphasized">very</span> minor flaw I could come up with is that this burger is <span class="emphasized">maybe slightly</span> unbalanced due to the lack of veggies. Then again, judging by the spec sheet, that seems more of a feature than a bug. The hardcore artery-clogging vibe this burger exudes is the point. And it's great.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | January 2022"
		},
		{
			type: "image",
			url: require("./Wild Onion.png")
		}
	]
}
