export default {
	title: "Crispy Cheeseburger",
	rating: "2",
	tags: ["discontinued", "special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>house sauce</li>
					<li>Cheddar cheese</li>
					<li>crispy bacon</li>
					<li>caramelized onions</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Tediously average at best.",
			content: `
				<p>But probably slightly lower.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | April 2019"
		}
	]
}
