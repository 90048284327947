export default {
	title: "Utrganc BBQ",
	rating: "3.5",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["pig"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>mustard-honey sauce</li>
					<li>Cheddar cheese</li>
					<li>caramelized onions </li>
					<li>crispy shallot</li>
					<li>iceberg lettuce</li>
					<li>tomatoes</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Fairly neutral pulled pork in the company of good ingredients.",
			content: `
				<p>A lot of good things to be found here. The BBQ sauce was used in appropriate quantities. The onion rings were a most welcome addition.</p>
				<p>On the flip side, tomato I can take or leave. And the pork, while good, is not exactly boldly seasoned.</p>
				<p>But as good as this is, I believe the design to be slightly misguided. Instead of relying almost entirely on regular BBQ sauce to flavor the pork, I prefer the pork strongly seasoned on its own and the sauce to play a supporting role. If you want to understand what I mean, try Trappa's <a href="/ranking/trappa/pulled-pork-burger" class="reference">Pulled Pork Burger</a> or - god help me - Kavarna Tiskarna's <a href="/ranking/kavarna-tiskarna/utrgana-svinja" class="reference">Utrgana Svinja</a>.</p>
				<p>On a final note, you may have noticed that the image of this sandwich doesn't look quite right. The truly devoted readers may even find it familiar. Yeah... that's because it's the same image as Mini Cafe Golf's <a href="/ranking/mini-cafe-golf/tivoli-pohancek" class="reference">Tivoli Pohanček</a>. They included this image on their Wolt page so I'm following their lead. To eternally shame them for their inexcusable blunder.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | May 2022"
		},
		{
			type: "image",
			url: require("./Utrganc BBQ.png")
		}
	]
}
