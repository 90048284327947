import dAnger from "./d'Anger/d'Anger.js"
import dKlasik from "./d'Klasik/d'Klasik.js"
import dOjaja from "./d'Ojaja/d'Ojaja.js"
import dPohanc from "./d'Pohanc/d'Pohanc.js"
import dSef from "./d'Sef/d'Sef.js"
import dSlaninc from "./d'Slaninc/d'Slaninc.js"

export default {
	title: "d'Burger",
	tags: ["closed"],
	burgers: [
		dOjaja,
		dSlaninc,
		dAnger,
		dSef,
		dKlasik,
		dPohanc
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "http://dburger.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/dburger.place/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d15214438-Reviews-D_Burger_Place-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: `
		<p>As good as I think d'Burger's burgers are, I find their menu a bit predictable, broadly speaking. It almost looks like they put all the ingredients they planned to use and built a flagship (<span class="emphasized">d'Ojaja</span>) then proceeded to cross them out from the list to arrive at all the other variations:</p>
		<ul>
			<li><span class="emphasized">d'Big</span> is a double d'Ojaja without the egg</li>
			<li><span class="emphasized">d'Slaninc</span> is d'Ojaja without BBQ sauce, the egg, and coleslaw</li>
			<li><span class="emphasized">d'Šef</span> is d'Ojaja without BBQ sauce, the egg, caramelized onions, and tomato</li>
			<li><span class="emphasized">d'Klasik</span> is d'Ojaja without BBQ sauce, the egg, caramelized onions, and coleslaw</li>
		</ul>
		<p>I'm not gonna lie, as someone who works in IT (broadly speaking), this approach appeals to me. It's a very elegant, very scalable solution. There are no other menus quite like it.</p>
		<p>There is, however, a potential drawback to this strategy. If you don't like one of their burgers it's very likely you won't like the others either. And just like that, you've eliminated 5/6 burgers on their menu (as of this writing).</p>
		<p>Luckily, I don't have that problem. F for those who do.</p>
		<p>Still, I wish they'd go for a bit more diversity. They seem competent so I'd love to see how else they might be able to branch out.</p>
	`
}
