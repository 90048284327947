export default {
	title: "Čiz Klasik",
	rating: "2.5",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>dry aged beef patty</li>
					<li>house sauce</li>
					<li>Cheddar cheese</li>
					<li>sautéed onions</li>
					<li>pickles</li>
					<li>tomatoes</li>
					<li>lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Could trick a vegetarian.",
			content: `
				<p>The first thing I noticed was the excellently grilled (mid-rare) excellent meet blend. Unfortunately, things unraveled from there.</p>
				<p>Above all else, this burger suffers from balance issues. Firstly, the sauce on this burger was barely noticeable to the point that I had to check the specs to see if it even had a sauce. That could be a red flag all its own. Secondly - and more importantly - this burger had <span class="emphasized">way</span> too much lettuce and tomatoes. It was like I was eating a salad with a side of beef and bun. It was a first for me, to be honest. But not in a good way.</p>
				<p>I guess if you're an unhinged lunatic who prioritizes veggies in their burgers above all else, this is for you. If, however, you are looking for an actual burger but want something lighter (as much as that's possible with such a dish), try the burger from <a href="/ranking/meat-business/burger" class="reference">Meat Business</a> if you can get it.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | September 2021"
		},
		{
			type: "image",
			url: require("./Ciz Klasik.png")
		}
	]
}
