import BlackAngusRoastbeefSteak from "./Black Angus Roastbeef Steak/Black Angus Roastbeef Steak.js"
import Blackadder from "./Blackadder/Blackadder.js"
import ChickenSteak from "./Chicken Steak/Chicken Steak.js"
import CrispyChicken from "./Crispy Chicken/Crispy Chicken.js"
import Lars from "./Lars/Lars.js"
import Magnus from "./Magnus/Magnus.js"
import PhillyCheese from "./Philly Cheese/Philly Cheese.js"
import PhillyChilli from "./Philly Chilli/Philly Chilli.js"
import PopeyeSpinach from "./Popeye Spinach/Popeye Spinach.js"
import PulledPork from "./Pulled Pork/Pulled Pork.js"
import RoastbeefSteak from "./Roastbeef Steak/Roastbeef Steak.js"
import Rookie from "./Rookie/Rookie.js"
import Smokey from "./Smokey/Smokey.js"
import Sven from "./Sven/Sven.js"

export default {
	title: "Lars & Sven",
	tags: [],
	burgers: [
		BlackAngusRoastbeefSteak,
		PhillyCheese,
		RoastbeefSteak,
		Blackadder,
		PopeyeSpinach,
		Magnus,
		PulledPork,
		Rookie,
		CrispyChicken,
		Sven,
		PhillyChilli,
		Lars,
		Smokey,
		ChickenSteak
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "http://www.larsandsven.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/larsandsven/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d13528216-Reviews-Lars_Sven_burgers_Ljubljana_city-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
