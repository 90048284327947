import _100Beef from "./100 Beef/100 Beef.js"

export default {
	title: "GourmeThomas",
	tags: ["closed"],
	burgers: [
		_100Beef
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: ""
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
