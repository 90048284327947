import Angus from "./Angus/Angus.js"

export default {
	title: "Azur Tratorija",
	tags: [],
	burgers: [
		Angus
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://azur-tratorija.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/azur.tratorija/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d1098521-Reviews-Azur_tratorija_s_picami-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
