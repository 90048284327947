export default {
	title: "Roastbeef Sandwich",
	rating: "1.5",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>wholegrain ciabatta</li>
					<li>beef steak</li>
					<li>truffle sauce</li>
					<li>Brie cheese</li>
					<li>rucola</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Do you like tasting only one ingredient when eating burgers? If so, this is for you.",
			content: `
				<p>The second-rate mayonnaise-like "truffle" sauce (in quotes because I'm not buying it) drowned out all the other flavours. 'nuff said.</p>
				<p>For a similar (but orders of magnitude better) take on this theme - moldy cheese, mushroomy sauce, rucola - consider Hood Burger's <a href="/ranking/hood-burger/le-brie" class="reference">Le Brie</a>.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | 2016 (Uncertain)"
		}
	]
}
