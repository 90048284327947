export default {
	title: "Horseburger",
	rating: "3",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["horse"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>horse patty</li>
					<li>Tabasco sauce <em style="opacity: 0.4; font-size: 80%">(optional)</em></li>
					<li>mustard <em style="opacity: 0.4; font-size: 80%">(optional)</em></li>
					<li>ajvar <em style="opacity: 0.4; font-size: 80%">(optional)</em></li>
					<li>Nacho cheese <em style="opacity: 0.4; font-size: 80%">(optional)</em></li>
					<li>ketchup <em style="opacity: 0.4; font-size: 80%">(optional)</em></li>
					<li>mayo <em style="opacity: 0.4; font-size: 80%">(optional)</em></li>
					<li>chili peppers <em style="opacity: 0.4; font-size: 80%">(optional)</em></li>
					<li>onions <em style="opacity: 0.4; font-size: 80%">(optional)</em></li>
					<li>lettuce <em style="opacity: 0.4; font-size: 80%">(optional)</em></li>
					<li>tomatoes <em style="opacity: 0.4; font-size: 80%">(optional)</em></li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "This one is for my old schoolers out there.",
			content: `
				<p>Arguably considered the pinnacle of burgers during my childhood.</p>
				<p>I won't lie to you: it's not for those of you looking for an amazing experience. Unless you have your glasses on with nostalgia lenses installed, you're not gonna be blown away by the taste.</p>
				<p>But that's not what the purpose of this sandwich is. You can tell just by looking at it: thin patty, giant buns, flooded with sauces and garnished with a sizeable amount of veggies of your choice. It's designed to make you extremely not hungry. A job at which it excels.</p>
				<p>Somewhat recommended if you know what you're getting into.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | February 2023"
		},
		{
			type: "image",
			url: require("./Horseburger.png")
		}
	]
}
