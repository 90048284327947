import Smokey from "./Smokey/Smokey.js"

export default {
	title: "Bežigrajski Dvor",
	tags: [],
	burgers: [
		Smokey
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.bezigrajskidvor.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/bezigrajskidvor/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
