export default {
	title: "Dry Aged Burger",
	rating: "4",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>BBQ mayo</li>
					<li>Brie cheese</li>
					<li>sweet onions</li>
					<li>rucola</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Well considered.",
			content: `
				<p>As I stood at the OPA stand at <a href="https://www.odprtakuhna.si/" class="reference">Odprta Kuhna</a> I watched with increasing worry and kept checking my watch as the gentleman grilling my burger patty refused to take it off the grill. By the time he finally relented, I was all but drowning in melancholy. I expected the burger to be a disappointment.</p>
				<p>Yet to my everlasting delight, the meat actually turned out fine. It was definitely well done, to be sure, but it wasn't super lean so it didn't end up dry. The seasoning on it was excellent, as was the sauce. The onions were maybe just a touch too sweet but honestly, I didn't mind.</p>
				<p>This is genuinely one of the closest competitors to Hood's <a href="/ranking/hood-burger/le-brie" class="reference">Le Brie</a> I have ever tried (for the laypeople: that is very high praise).
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Odprta Kuhna | May 2022"
		},
		{
			type: "image",
			url: require("./Dry Aged Burger.jpg")
		}
	]
}
