import MaisterBurger from "./Maister Burger/Maister Burger.js"

export default {
	title: "Maister Pub",
	tags: [],
	burgers: [
		MaisterBurger
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.maisterpub.net/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/MaisterPub"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: "Every burger comes with a serving of fried potato wedges. Unfortunately, they miss the mark in just about every way: they're underseasoned, the portion is gigantic, and there's no sauce included. Comrades, I believe we deserve better."
}
