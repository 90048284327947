export default {
	title: "Fat Billy",
	rating: "1",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>	
					<li>beef patty</li>
					<li>house sauce</li>
					<li>grated Mozzarella cheese</li>
					<li>fried egg</li>
					<li>crispy bacon</li>
					<li>red cabbage</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The ICC has prosecuted people for lesser atrocities.",
			content: `
				<p>I ordered medium rare. What I received was quite possibly the most well done burger I have ever seen.</p>
				<p>It was almost charred. That's a pretty big offense in my book, however one I was willing to forgive (or at least chalk it up to a bad cook) if the rest of the burger was OK.</p>
				<p>It wasn't.</p>
				<p>Can I elaborate? No. I was so disappointed I forgot to take notes.</p>
				<p>One last parting thought, though. The way their menu is organized is similar to <span class="reference">d'Burger</span> (minus the actual quality): they have quite a few burgers on the menu, however each successive one is just the previous one plus some extra ingredients. The one I ordered was at the end of the line - a flagship, basically. And based on this one I have no faith whatsoever that the rest of them are any good. Hard pass, my dudes.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018"
		}
	]
}
