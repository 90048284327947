export default {
	title: "BigBoy",
	rating: "1.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>BBQ sauce</li>
					<li>Cheddar cheese</li>
					<li>seared bacon</li>
					<li>red onion</li>
					<li>lettuce</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The burger's poorly spelled name portends its actual quality.",
			content: `
				<p>Don't really have much to say here. If you can't pull off a burger with these incredibly standard specs, you shouldn't be in the business of making burgers.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | 2020"
		},
		{
			type: "image",
			url: require("./BigBoy.jpg")
		}
	]
}
