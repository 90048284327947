import Texas from "./Texas/Texas.js"

export default {
	title: "Lor&Di",
	tags: [],
	burgers: [
		Texas
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/LorDi-101755328000803/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
