export default {
	title: "Bastardo",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>beef patty</li>
					<li>chilly mayo jalapeno salsa</li>
					<li>Cheddar cheese</li>
					<li>sour onions</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Excellent, though not for me.",
			content: `
				<p>I understand what this is trying to be. And judging by the praises being sung to this burger from those amenable to more aggressive tastes, I imagine it succeeds at its task. But again, not for me. I just can't do spicey.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018"
		},
		{
			type: "image",
			url: require("./Bastardo.jpg")
		}
	]
}
