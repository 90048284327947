import BigBoss from "./Big Boss/Big Boss.js"

export default {
	title: "Jurman Food Corner",
	tags: [],
	burgers: [
		BigBoss
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.jurman.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/jurmancafeloungebar/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d1113951-Reviews-or30-Gostilna_in_pizzerija_Jurman-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: `
		<p>Fries with every burger but no sauce for the fries. Why?</p>
		<p>Still, the fries are good.</p>
	`
}
