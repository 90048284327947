export default {
	title: "4UM Burger [Mk. I]",
	rating: "3.5",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>miso mayonnaise with black sesame seeds</li>
					<li>aged Gouda cheese</li>
					<li>onion jam</li>
					<li>rucola</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Sweet.",
			content: `
				<p>This burger is very flavor-rich but in a different way you'd expect from your average burger. The onion marmalade gives it an almost sweet taste. It's different and I mostly like it.</p>
				<p>I say 'mostly' because unfortunately, it's also very unbalanced. The ingredients tend to clash and the onions overpower almost everything when you get to the middle. Still worth it now and again though.</p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;"><strong>Warning:</strong> this burger is NOT the same as <a href="/ranking/forum/4um-burger" class="reference">4UM Burger</a> currently sold at their restaurant. Hence, I dubbed this one <span class="emphasized">Mk. I</span> to make it clear that they are different burgers. I unfortunately do not know when exactly the switcheroo took place as it was a silent revision.</div>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018"
		}
	]
}
