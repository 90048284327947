export default {
	title: "Chicken Crispy Burger",
	rating: "3",
	tags: ["discontinued"],
	dish_type: "sandwich",
	meat_types: ["chicken"],
	sections: [
		{
			type: "html",
			title: "Review",
			lede: "Decent enough chicken sandwich, troubled by labels.",
			content: `
				<p>Let me just get this out of the way so I can sleep better. It's a recurring theme. You may already know where this is going.</p>
				<p>This is - as the hawk-eyed readers of my other reviews may have already spotted - Also. Not. A. Burger. A <span class="emphasized">burger</span> requires <span class="emphasized">beef</span>. A foundation of <span class="emphasized">chicken</span>, on the other hand, will result in a <span class="emphasized">sandwich</span>.</p>
				<p>That's in the Bible. Look it up.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2015"
		},
		{
			type: "image",
			url: require("./Chicken Crispy.jpg")
		}
	]
}
