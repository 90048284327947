export default {
	title: "100% Beef Burger",
	rating: "2",
	tags: ["discontinued", "special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Review",
			lede: "Forgettable.",
			content: `
				<p>This had some interesting specs - marinated sauerkraut, to name the most eye-catching - but ultimately failed to impress. The well done patty certainly didn't help.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | June 2017 (Uncertain)"
		}
	]
}
