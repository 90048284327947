export default {
	title: "The Gotham Burger",
	rating: "2",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>double beef patty</li>
					<li>double cheese</li>
					<li>grilled onions</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "In need of a hero it deserves.",
			content: `
				</p>
				<p>First of all, pickles were not actually listed on the menu. Not cool. Second, this burger can only be made medium well to well done because of thin patties. Very hard to pull off with their particular blend of fairly lean meat. Even less cool.</p>
				<p>Can't recommend. Especially considering the rest of Pop's lineup.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2017 (Uncertain)"
		}
	]
}
