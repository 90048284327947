export default {
	title: "Chicken Burger",
	rating: "1.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>mustard sauce</li>
					<li>Parmesan cheese</li>
					<li>onion jam</li>
					<li>rucola</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A fuck-up with a twist.",
			content: `
				<p>Not a lot of good things to be said about this incorrectly labelled <span class="emphasized">sandwich</span>.</p>
				<p>Interestingly, however, it suffers from an opposite issue usually plaguing its rivals with similar or adjacent scores: the meat here was actually pretty good. It seemed well seasoned and it was properly browned yet remained juicy. They had the most important part down pat yet boldly proceeded to fuck it all up.</p>
				<p>It was everything else that made it kind of suck. The sauce especially was in my opinion incompatible with poultry and the veggies could certainly have been more fresh.</p>
				<p>On a final note, if you're having this delivered, find yourself a chopstick or something to stab it with. The chicken in mine absolutely refused to be contained within the bun's perimeter (slathering it with sauce on boh sides certainly didn't help). And no burger stick shipped with mine, contrary to what the image implied. Annoying.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | May 2022"
		},
		{
			type: "image",
			url: require("./Chicken Burger.png")
		}
	]
}
