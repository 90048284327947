export default {
	title: "Carolina Chopped Pork",
	rating: "4",
	tags: ["discontinued"],
	dish_type: "sandwich",
	meat_types: ["pig"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>pulled pork</li>
					<li>mustard sauce</li>
					<li>coleslaw</li>
					<li>pickled onions</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Exquisitely prepared pulled pork beauty.",
			content: `
				<p>As my friend and I eagerly listened to the chef gushing over the carefully chosen ingredients that went into this beauty, it became clear this <span style="text-decoration: line-through;">burger</span> sandwich was made with love. Leaving clichés aside, the only thing that bothered me was the level of spiciness, but that's a personal preference. Most normies would find it relatively benign, I suspect.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | 2019"
		}
	]
}
