import CarolinaChoppedPork from "./Carolina Chopped Pork/Carolina Chopped Pork.js"
import CrispyCheeseburger from "./Crispy Cheeseburger/Crispy Cheeseburger.js"

export default {
	title: "Majmun",
	tags: ["closed"],
	burgers: [
		CarolinaChoppedPork,
		CrispyCheeseburger
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/majmun.bar"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
