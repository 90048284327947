import VicKing from "./Vic King/Vic King.js"
import BeefBrisket from "./Beef Brisket/Beef Brisket.js"

export default {
	title: "Texas BBQ Ljubljana",
	tags: ["discontinued"],
	burgers: [
		VicKing,
		BeefBrisket
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/Texas-BBQ-105714611642607/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
