export default {
	title: "Gurman",
	rating: "3",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>tenderloin roast</li>
					<li>mustard sauce with seeds</li>
					<li>Parmesan cheese</li>
					<li>melted butter</li>
					<li>grilled zucchini</li>
					<li>rucola</li>
					<li>tomato</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Beef flavour successfully subdued.",
			content: `
				<p>This is a tough one. Glancing at the specs, this burger should be amazing. And it is indeed very good. Yet it doesn't really rise above, so to speak. My guess would be that the zucchini combined with a fairly thick tomato slice overly dampen the flavour of the meat. I don't really understand why you would put zucchini in a burger in the first place but there you go.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | October 2018"
		},
		{
			type: "image",
			url: require("./Gurman.jpg")
		}
	]
}
