export default {
	title: "Magnus Burger",
	rating: "3.5",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>Magnus bun</li>
					<li>2x beef patty</li>
					<li>2x Cheddar cheese</li>
					<li>BBQ sauce</li>
					<li>lettuce</li>
					<li>onions</li>
					<li>sweet pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Actually great conventional burger with BBQ sauce. Highly irregular.",
			content: `
				<p>Long-time reader(s) will know I'm not the biggest fan of L&S. Be that as it may, they are still decidedly above average, especially amongst the newcomers. I've long lamented the annoyingly prevalent lack of vision the contemporary upstarts in the burger world manage to exhibit. So when I noticed a new entry on an established vendor's menu with BBQ sauce on the spec sheet, expectations were high. And luckily, they were pretty much met.</p>
				<p>What L&S brings to the table, first and foremost, is good meat. My problem with them has almost always mainly been with the sauces. No such issue here - BBQ sauce is difficult to fuck up I guess. Also mad props on the pickles; this is one of those rare cases where they actually don't suck. That alone is a noteworthy accomplishment.</p>
				<p>Still, it's not the best in the BBQ sauce "genre" I've ever tried. That honor would probably go to Projekt Burger's <a href="/ranking/projekt-burger/lord" class="reference">Lord</a>, depending on how much you want to stretch the definition.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | December 2021"
		},
		{
			type: "image",
			url: require("./Magnus.png")
		}
	]
}
