export default {
	title: "Cheeseburger",
	rating: "1",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>ketchup</li>
					<li>mayonnaise</li>
					<li>cheese</li>
					<li>onion</li>
					<li>lettuce</li>
					<li>tomato</li>
					<li>cabbage</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "I would recommend this burger to anyone literally minutes away from starving to death and devoid of strength to make it to the next closest restaurant.",
			content: `
				<p>When I ordered this burger the time was around 23:30. There were three places marked as open on Wolt. I picked one at random and ordered a burger. I'm giving you all this auxiliary information because I want you to understand just how low my expectations for this burger were. And how impressed I was when the restaurant still managed to undercut them.</p>
				<p>Before we even get to the review: they translated "bombeta" (Slovenian for bun) into "bombetta"(!). For those not in the know, a bombetta is a type of hat Charlie Chaplin wore. Now, I could go for the easy dunk and just say "... which is exactly how this burger tastes AMIRITE FELLAS?". But I will resist the urge because I want you to truly understand how bad this burger is.</p>
				<p>The best I can say about this burger is that the bun was not stale and the veggies seemed relatively fresh. Basically everything else about it was bad. It's a kind of burger I'd expect from McDonald's or something. But it's actually worse than that in a way because at least at Mickey D's you'd get a sauce that was specifically engineered to be as uncontroversial possible and serves as a masking agent so that you don't realize you're not eating real food. 'Halo Podnevi & Ponoči
				' offers no such luxury.
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | August 2021"
		},
		{
			type: "image",
			url: require("./Cheese.jpg")
		}
	]
}
