export default {
	title: "Silverton Burger",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>beef patty</li>
					<li>house BBQ sauce</li>
					<li>Cheddar cheese</li>
					<li>smoked bacon</li>
					<li>crispy onions</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Swing and a miss.",
			content: `
				<p>I was unpleasantly surprised munching on this. The onion ring inside the burger over-promised and under-delivered. It's not even a terrible burger - the BBQ sauce and bacon make sure of that - just below the Pop's (relatively high) average.</p>
				<p>Hot take: they should just discontinue it. It's bringing down their average rating on my site. Which is a big deal. Huge.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | March 2021"
		},
		{
			type: "image",
			url: require("./Silverton.jpg")
		}
	]
}
