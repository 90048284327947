import ClassicZanci from "./Classic Zanci/Classic Zanci.js"
import Sick from "./Sick/Sick.js"
import WildOnion from "./Wild Onion/Wild Onion.js"

export default {
	title: "Wild Brothers",
	tags: ["closed"],
	burgers: [
		WildOnion,
		ClassicZanci,
		Sick
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/Wild-Brothers-314554822591007/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
