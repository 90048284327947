export default {
	title: "Novozelandec",
	rating: "4.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>steak slices</li>
					<li>BBQ sauce</li>
					<li>mustard spread</li>
					<li>onions</li>
					<li>lettuce</li>
					<li>tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "If you thought it was impossible to add a beef patty AND a steak to the same burger, think again.",
			content: `
				<p>I have eaten this burger three times at <a href="https://www.pivoinburgerfest.si/" target="_blank" class="callback">Pivo & Burger fest</a> so far and it's been amazing every single time <span class="emphasized" style="font-size: 80%;">(Hi, yours truly from the future here; this is no longer the case - read warning below)</span>. An intriguing spec sheet never fails to disappoint. The overabundance of beef is well balanced by the sauces and veggies and never feels excessive. You owe it to yourself to try this showpiece at least once. It needs to go in your mouth and its juices all over your face.</p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;"><strong>Warning:</strong> if you are considering Stari Pisker's offerings at <a href="https://www.pivoinburgerfest.si/" target="_blank" class="callback">Pivo & Burger Fest</a>, do yourself a favor and don't. While their burgers were some of the best in class, this unfortunately no longer applies, at least not to Burgerfests. They have decided on a brilliant strategic move of prioritizing profits at the expense of their reputation and now produce only well done and super dry meat patties. Therefore, I can no longer recommend them at any venue outside their restaurant. Disregard the existing review/score in any other context.</div>
		`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | 2017"
		},
		{
			type: "image",
			url: require("./Novozelandec.jpg")
		}
	]
}
