export default {
	title: "Gastro Burger",
	rating: "1.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>charcoal bread bun</li>
					<li>Black Angus beef patty</li>
					<li>house BBQ sauce</li>
					<li>crispy bacon</li>
					<li>poached quail egg</li>
					<li>lettuce</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Like a seagull in an oil spill.",
			content: `
				<p>I was really looking forward to this burger. I've been to Gastro House before. Granted, I've only ever eaten their non-burgers (this is how I refer to all other food products; it's a professional deformation). But everything I previously ordered was delicious.</p>
				<p>Besides - poached quail egg? My expectations were high. Reality, however, is a cruel mistress.</p>
				<p>For all I know, this burger was delicious. Sadly, I just can't say. Because all I could taste was BBQ sauce. But this wasn't just your typical overdose. I've experienced those before. Oh no. This was something else entirely. This burger was so drenched in the stuff that when I opened the box it was delivered in, <span class="emphasized">the bottom bun was entirely fucking soggy</span>. And the sauce even made it on <span class="emphasized">top</span> of the patty!</p>
				<p>As I said, this might have been a one-off blunder. But I can't evaluate burgers based on some imagined optimal serving. I can only evaluate what I actually receive. And what I received was not good.
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | September 2021"
		},
		{
			type: "image",
			url: require("./Gastro.jpg")
		}
	]
}
