export default {
	title: "Der Würstmeister",
	rating: "2",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>grass-fed beef patty</li>
					<li>weisswurst</li>
					<li>german mustard with apples</li>
					<li>creamy Emmentaler cheese</li>
					<li>sauer kraut with butter and mustard seeds</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Objectionable.",
			content: `
				<p>I heard from a little birdie that Hood's method of preparing the <a href="https://en.wikipedia.org/wiki/Weisswurst" class="reference">weisswurst</a> in this burger - first cooked, then grilled - is apparently "blasphemous" as it isn't supposed to be grilled but only cooked. I'm not versed in weisswurst preparation, but I do know a thing or two about blasphemy and take it very seriously. Therefore, I felt obligated to report this offence.</p>
				<p>Serious matters aside, I think this burger only worked because of the quality of its ingredients. It's not a very cohesive dish - some elements don't mix well. Needs to be rethought.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | September 2019"
		},
		{
			type: "image",
			url: require("./Der Wurstmeister.jpg")
		}
	]
}
