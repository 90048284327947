import Painkiller from "./Painkiller/Painkiller.js"
import MindBlower from "./Mind Blower/Mind Blower.js"
import _7Burger from "./7Burger/7Burger.js"

export default {
	title: "7 Burger",
	tags: [],
	burgers: [
		Painkiller,
		MindBlower,
		_7Burger
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://7burger.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/sedemburger/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g815567-d8528760-Reviews-7_Burger-Kamnik_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
