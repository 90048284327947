export default {
	title: "Utrgana Svinja",
	rating: "4",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["pig"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>white bread bun</li>
					<li>slow roasted pulled pork</li>
					<li>BBQ Bulleit Bourbon sauce</li>
					<li>coleslaw</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Finally found Tiskarna's true calling: non-burgers.",
			content: `
				<p>Among the few who don't care for their burgers, even I am forced to concede that Tiskarna is capable of making good food. I realize now - far too late - that I shouldn't have been eating their burgers but all the other sandwiches instead. This is one such product.</p>
				<p>The pork in this sandwich had one of the strongest seasonings of any pork sandwiches I have ever eaten. Luckily for me, it was absolutely delicious. The sauce was exquisite as well. Way to go, bourbon!</p>
				<p>The coleslaw played its role excellently as well. I just wish the assortment of sliced veggies were broader - from what I could tell, they only included red cabbage and the vinaigrette. It was a bit one-dimensional.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | May 2022"
		},
		{
			type: "image",
			url: require("./Utrgana Svinja.png")
		}
	]
}
