export default {
	title: "Jezeršek 360° Burger",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>sauce with mayo and mustard seeds</li>
					<li>BBQ sauce</li>
					<li>Brie cheese</li>
					<li>sour red onion</li>
					<li>crispy pancetta</li>
					<li>lettuce</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Refreshing subversion of expectations.",
			content: `
				<p>I was pitched this burger by a friend who found it kind of average. Strong pitch, I know. Nevertheless, rating burgers seems to be my purpose in life so here we are.</p>
				<p>In spite of what I just said, this burger was a nice surprise. It has somewhat unorthodox ingredients, to be sure, but it works. The meat is very soft and the pickled onions add a pleasant aftertaste. It's <span class="emphasized">so close</span> to being one of the big boys. Alas, it does fall just a bit short</p>
				<p>... which might have been what my friend was trying to communicate now that I think about it. Look, I'm not gonna lie, it was a while ago. And I'm not asking. He'd be furious if I called him now. It's three o'clock in the morning.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | June 2020"
		},
		{
			type: "image",
			url: require("./Jezersek 360.jpg")
		}
	]
}
