export default {
	title: "Beef Burger",
	rating: "3",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>dressing</li>
					<li>cheese</li>
					<li>lettuce</li>
					<li>tomato</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A relative disappointment.",
			content: `
				<p>Honestly, from a restaurant with such a good reputation, I was expecting more. By no means was this burger bad, don't misunderstand. And quite big as well. But while the meat was solid (yet not of the highest delectability), everything else was wholly unremarkable, especially the painfully average dressing I usually encounter in burgers from bars, not at all befitting a restaurant of this caliber.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018"
		}
	]
}
