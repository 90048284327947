export default {
	title: "Cube Burger",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>lepinja bread</li>
					<li>beef patty</li>
					<li>house sauce with bacon bits</li>
					<li>cheese</li>
					<li>lettuce</li>
					<li>tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Skip.",
			content: `
				<p>It's a lesser version of <a href="/ranking/cube-burger/kraljevi-burger-xxl" class="reference">Kraljevi Burger XXL</a>.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		},
		{
			type: "image",
			url: require("./Cube.jpg")
		}
	]
}
