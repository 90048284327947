export default {
	title: "Dirty Mac",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>aged double beef patty</li>
					<li>pickle-based sauce</li>
					<li>Cheddar cheese</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Incomplete.",
			content: `
				<p>This burger's missing an ingredient. I'm not sure what exactly but I do know it's not there and I miss it.</p>
				<p>But, you know, it's Pop's, so it's still great.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2017 (Uncertain)"
		},
		{
			type: "image",
			url: require("./Dirty Mac.jpg")
		}
	]
}
