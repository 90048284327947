export default {
	title: "Dule",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>Black Angus beef patty</li>
					<li>house sauce</li>
					<li>Cheddar cheese</li>
					<li>crispy bacon</li>
					<li>caramelized onions</li>
					<li>tomatoes</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Good... in a vacuum.",
			content: `
				<p>Let me state my bias up front: I am not a big fen of Pinki in general.</p>
				<p>My friend and I used to visit often back high school. They have two parts to their business: a street-facing fast food stand where you could order to go in the front and a "proper" restaurant in the back. The food they offered at the stand was OK - though unremarkable - but (very important to a high school kid) cheap. The restaurant, however, was trash. I went there two times (just to make sure; a youthful mistake) and I would not recommend anyone else visit.</p>
				<p>When I spotted their nema on Wolt something stirred deep inside. I felt a call of duty. It was my responsibility to shell out the enormous stack of money they were charging in order to spare the general public from having to do the same. I never imagined this burger might be any good.</p>
				<p>And yet, it was actually surprisingly decent. The mayo was a bit bland but other than that - pretty good. I still can't quite recommend it for the price because there are several much better establishments within reasonable walking distance. But if you spend the money, you <span class="emphasized">might</span> not necessarily feel ripped off. Which is the highest compliment I can give to Pinki.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | December 2021"
		},
		{
			type: "image",
			url: require("./Dule.png")
		}
	]
}
