export default {
	title: "Crispy chicken burger",
	rating: "1.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>fried chicken thight <em>[sic]</em></li>
					<li>curry sauce</li>
					<li>smoked cheese</li>
					<li>onion</li>
					<li>tomato</li>
					<li>green lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: '"Thight".',
			content: `
				<p>That is what <em>thigh</em> is spelled as on Stazione's Wolt page. Things do not improve from there.</p>
				<p>The chicken was dry, which is honestly quite an achievement considering they're not using breast here. Other highlights include: unripe tomato, weirdly misshaped buns, and mediocre sauce.</p>
				<p>Terrible.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | March 2023"
		},
		{
			type: "image",
			url: require("./Crispy chicken burger.png")
		}
	]
}
