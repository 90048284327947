export default {
	title: "Classic Žanči Burger",
	rating: "4",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>fresh sesame bread bun</li>
					<li>2x beef patty</li>
					<li>house sauce</li>
					<li>2x Cheddar cheese</li>
					<li>caramelized onions</li>
					<li>lettuce</li>
					<li>tomatoes</li>
					<li>cucumbers</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Standard ingredients, elevated.",
			content: `
				<p>This was my first burger of 2022. Which might have been a big mistake. There may be nowhere but downhill to go from here. This is one of the very few new burgers in the last three yearsc actually worth a fuck. And it's from one of only two joints that will actually be added to the rotation.</p>
				<p>COVID really fucked us.</p>
				<p>Anyway. The meat blend is great. The sauce is great. It has double the cheese. It's fairly sizeable. And to top it all off - very outside of the norm these days - it's not even insanely expensive! By all accounts, this should not be possible. Yet here we are.</p>
				<p>Gushing praise aside, I would be remiss if I did not talk about some flaws. Because believe or not, this burger is not perfect.</p>
				<p>First and foremost, a malicious element has found its way into this burger: a pickle. Assume +½ to the score in case of removal. Second, the caramelized onions. They were great, of course - I've <span class="emphasized">almost</span> never seen them fucked up anywhere - but they seemed a bit... subdued. It seems like not that many were added to the burger in the first place. But I can't tell if that was an anomaly or not.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | January 2022"
		},
		{
			type: "image",
			url: require("./Classic Zanci.png")
		}
	]
}
