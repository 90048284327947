import FatBilly from "./Fat Billy/Fat Billy.js"

export default {
	title: "Kino Bežigrad",
	tags: [],
	burgers: [
		FatBilly
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.kino-bezigrad.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/KinoBezigrad/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Attraction_Review-g274873-d8727594-Reviews-Kino_Bezigrad-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
