export default {
	title: "Tartuf Burger",
	rating: "3",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["pig"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>pork fillet</li>
					<li>dressing</li>
					<li>truffles</li>
					<li>lettuce</li>
					<li>onions</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Resisting the urge to deduct points for not even bothering to translate the name.",
			content: `
				<p>If you are looking for a decent lunch, this fits the bill. You can't really go wrong with it, yet it also won't leave a lasting impression. It's a middle-of-the-road sandwich. An ordinary everyday sandwich, unremarkable in every way.</p>
				<p>That's about all I can say here. I've ran out of generic "average" descriptors.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | August 2016"
		},
		{
			type: "image",
			url: require("./Tartuf.jpg")
		}
	]
}
