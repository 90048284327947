import TopChef from "./TopChef/TopChef.js"
import BigBoy from "./BigBoy/BigBoy.js"

export default {
	title: "Top Chef",
	tags: [],
	burgers: [
		BigBoy,
		TopChef
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "http://www.topchef.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/TopChef-1582253031988585/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
