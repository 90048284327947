export default {
	title: "Burger",
	rating: "5",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>house sauce</li>
					<li>Cheddar cheese</li>
					<li>caramelized black onions</li>
					<li>quail egg</li>
					<li>radicchio</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "I would crawl over broken glass to lick the juices off of the plate this burger was served on.",
			content: `
				<p>This masterpiece is an exercise in what is possible when paying attention to the little things. There are no radical ingredients to be found here, no gimmicks, no quirky shit (we could arguably quibble about quail eggs but they don't taste much different than normal eggs). It all just comes down to quality of each individual piece and the cohesive product their sum results in. In some ways it's a flex - a rebuke of the lazy average burgers some joints are throwing together with seemingly no thought about the final result.</p>
				<p>I can't even complain about the price anymore. A few years ago this would have been a ridiculous sum to pay, sure. But by now I've eaten far worse burgers that were just as expensive. So I'm basically left with no real criticisms.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2020"
		},
		{
			type: "image",
			url: require("./Burger.jpg")
		}
	]
}
