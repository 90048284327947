export default {
	title: "McGreggor Burger",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>2x 100% beef</li>
					<li>house sauce</li>
					<li>Cheddar cheese</li>
					<li>bacon</li>
					<li>rucola</li>
					<li>tomatoes</li>
					<li>cucumbers</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries, ketchup</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The fundamentals are not there.",
			content: `
				<p>The meat was chewy as shit. And it wasn't even overcooked or anything - it was mid/mid-rare! Not good, guys. It's also expensive but arguably actually justifies its ridiculous price because of its size (it's a double by default, after all).</p>
				<p>Still, I wouldn't recommend it. Especially if you actually find yourself at their location. There are several better options within walking distance. <a href="/ranking/pops-place" class="reference">Pop's Place</a>, <a href="/ranking/hood-burger" class="reference">Hood Burger</a>, <a href="/ranking/lars-sven" class="reference">Lars & Sven</a>, even <a href="/ranking/burger-time" class="reference">Burger Time</a>, to name a few</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | December 2021"
		},
		{
			type: "image",
			url: require("./McGreggor.png")
		}
	]
}
