<template>
	<div id="app">
		<Title/>
		<Navigation/>
		<router-view class="router-page"/>
		<Footer v-if="!$route.fullPath.startsWith('/editorial') && $route.name !== 'Philosophy'"/>
	</div>
</template>

<script>
import Title from "./components/Title.vue"
import Navigation from "./components/Navigation.vue"
import Footer from "./components/Footer.vue"

export default {
	name: "App",
	components: {
		Title,
		Navigation,
		Footer
	},
	created () {
		document.title = "Burg.si"
	}
}
</script>

<style>
	@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap&subset=latin-ext');

	/* Nunito Sans */
	@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;1,800;1,900;1,300;1,400;1,600&display=swap');
	/* Open Sans */
	@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
	@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,400;1,600&display=swap');

	#app {
		font-family: Lato, Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}

	html {
		background: #2a2a2a;
	}

	body {
		background: #2a2a2a;
		margin: 0;
	}

	.router-page {
		/* 100vh - navbarHeight - footerHeight */
		min-height: calc(100vh - 86px - 181px);
		background: white;
		width: 100%;
		box-sizing: border-box;
	}

	a {
		text-decoration: none;
	}
</style>
