export default {
	title: "Boss",
	rating: "5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>dry-aged beef patty</li>
					<li>truffle aioli</li>
					<li>Stilton cheese</li>
					<li>onion marmalade</li>
					<li>apple slice</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries & ketchup</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Literally The Boss from Pop's Place with an apple slice. So, you know - fantastic.",
			content: `
				<p>I heard through the grapevine that As has the same owner as Pop's Place. I haven't been able to confirm it yet but it would not surprise me in the slightest. They both have literally the exact same beef blend. I've eaten burgers in both establishments and I would fail a blind A/B taste test every time. Everything else seems pretty much the same as well.</p>
				Given that, the basic gist from Pop's <a href="/ranking/pops-place/the-boss" class="reference">The Boss</a> review applies here. The apple slice is an interesting addition but I'm more or less indifferent as to its inclusion. It neither especially improves the burger nor does it ruin it.</p>
				<p>I do have two superficial problems with this burger, however.</p>
				<p>First, it used to be called <a href="https://www.youtube.com/watch?v=6Pkq_eBHXJ4" target="_blank" class="callback">'Royale with Cheese'</a>. Now, as you can see from the spec sheet it obviously bares no resemblance to the Quarter Pounder. I still don't know why it was changed though. I doubt Tarantino threatened any lawsuits. Or was it the burdensome association with trashy fast food that became too much to bear? Whatever it was, why change it to the most overused name in this business? Very stupid, if you ask me.</p>
				<p>Second, it's expensive. They non-optionally bundle it with fries which increases its price. So if you want to save some money - Pop's is just two minutes away...</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018"
		},
		{
			type: "image",
			url: require("./Boss.jpg")
		}
	]
}
