export default {
	title: "Smokey Burger",
	rating: "2",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["pig"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>lightly smoked & minced pork tenderloin</li>
					<li>house BBQ sauce</li>
					<li>Gauda cheese</li>
					<li>crispy bacon</li>
					<li>lettuce</li>
					<li>tomato</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries (during select hours)</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Where's the beef?",
			content: `
				<p>The main criticism of this... <span class="emphasized">sandwich</span> (he said annoyed) can, ironically, be boiled down to this: no beef. Now, there is bacon, but I will have to break with the precedent here and veto (myself, I guess?) the obligatory ½ point. Shocking, I know. The reason is simple: the taste of bacon is mostly lost in ground pork this burger's patty is made of, losing the impact it would otherwise have had.</p>
				<p>I don't know, this whole burger just tastes so... bland. Which shouldn't be the case with these ingredients - especially BBQ sauce. But it somehow is.</p>

			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018"
		},
		{
			type: "image",
			url: require("./Smokey.jpg")
		}
	]
}
