import BeefBacon from "./Beef Bacon/Beef Bacon.js"

export default {
	title: "Irish Pub",
	tags: ["discontinued"],
	burgers: [
		BeefBacon
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.irishpub-ljubljana.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/irishpubljubljana.si/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d948475-Reviews-Patrick_s_Irish_Pub-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
