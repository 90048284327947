export default {
	title: "Jake'n'Jay",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>grass-fed thin double beef patty</li>
					<li>california style sauce</li>
					<li>Cheddar cheese</li>
					<li>iceberg lettuce</li>
					<li>grilled onions</li>
					<li>tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The most flavour-rich burger on Hood's regular menu.",
			content: `
				</p>The only gripes I have with it are rather superficial: it seems that Hood's bun containment technology is not yet sufficiently advanced to properly restrain this burger's ample ingredients. Second, they don't make it medium rare, because the patties are too thin. Which... well, in the immortal words of <a href="https://www.youtube.com/watch?v=l1dnqKGuezo" target="_blank" class="callback">Clay Davis: "Sheeeeeit!"</a></p>
				<p><strong>UPDATE:</strong> The goddess of fortune smiles upon us, comrades! Instead of the two thin ones you can request a regular patty which <span class="emphasized">can</span> be prepared medium rare. So if you're not a fan of smash burgers you can go down that road.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | January 2023"
		},
		{
			type: "image",
			url: require("./Jake'n'Jay.jpg")
		}
	]
}
