export default {
	title: "Tiskarna [Mk. I]",
	rating: "2.5",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>white bread bun</li>
					<li>beef patty</li>
					<li>Curry smoke sauce</li>
					<li>cheese</li>
					<li>caramelized onions</li>
					<li>lettuce</li>
					<li>tomatoes</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "How do you fuck up with those specs?",
			content: `
				<p>I never thought I would utter these blasphemous words: it is possible to have too much cheese in a burger.</p>
				<p>WHAT THE FUCK ARE YOU MAKING ME SAY, TISKARNA?!</p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;">At some point during their existence (not sure when), Tiskarna tweaked their recipes. I now denote their old crop of burgers/sandwiches with "[Mk. I]" in their name. More thoughts and information about the change in the <a href="/ranking/kavarna-tiskarna" class="reference">Comments section</a> on the Joint page.</div>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | June 2023"
		},
		{
			type: "image",
			url: require("./Tiskarna [Mk. I].jpg")
		}
	]
}
