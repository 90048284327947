export default {
	title: "Dirty Sanchez [Mk. I]",
	rating: "3",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>white bread bun</li>
					<li>beef patty</li>
					<li>BBQ Bulleit Bourbon sauce</li>
					<li>Nacho cheese</li>
					<li>caramelized onions</li>
					<li>bacon</li>
					<li>fried egg</li>
					<li>lettuce</li>
					<li>tomatoes</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Best burger at Tiskarna. Which isn't saying much.",
			content: `
				<p>At least out of the five burgers I've eaten there. And I probably won't try the rest to be perfectly honest. As I've discussed elsewhere, I'm not a fan. The only reason I've even tried that many of their burgers is because I was dragged there by my friends and coworkers enough times that I eventually found one that doesn't suck.</p>
				<p>The nacho is good. The egg is always welcome. The portions are big in general. But ultimately, their meat is just not where it needs to be and the burger is nowhere near top tier.</p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;">At some point during their existence (not sure when), Tiskarna tweaked their recipes. I now denote their old crop of burgers/sandwiches with "[Mk. I]" in their name. More thoughts and information about the change in the <a href="/ranking/kavarna-tiskarna" class="reference">Comments section</a> on the Joint page.</div>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | May 2020"
		},
		{
			type: "image",
			url: require("./Dirty Sanchez [Mk. I].jpg")
		}
	]
}
