import PorkaMadonaPulledBurger from "./Pork-a-Madona Pulled Burger/Pork-a-Madona Pulled Burger.js"
import HolyCow from "./Holy Cow/Holy Cow.js"
import DryAgedHolyCow from "./Dry Aged Holy Cow/Dry Aged Holy Cow.js"

export default {
	title: "Kralj Žara",
	tags: ["closed"],
	burgers: [
		DryAgedHolyCow,
		PorkaMadonaPulledBurger,
		HolyCow
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/KraljZara/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
