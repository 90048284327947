export default {
	title: "The Kliffhanger",
	rating: "2.5",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>dill, mustard, and mayo sauce</li>
					<li>Camembert cheese</li>
					<li>hash brown</li>
					<li>smoked ham</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A commendable attempt, flawed as it was.",
			content: `
				<p>Despite its interesting specs this one did not work for me. The hash browned potato patty was a cool addition. The cheese was great. The smoked ham was merely OK - the level of crispiness was sorely lacking. But what ultimately brought it all down for me was the choice of sauce and the fact that the burger was far too salty.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | February 2021"
		},
		{
			type: "image",
			url: require("./The Kliffhanger.jpg")
		}
	]
}
