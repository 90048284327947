export default {
	title: "Sick Burger",
	rating: "3.5",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>fresh sesame bread bun</li>
					<li>2x beef patty</li>
					<li>smokey sauce</li>
					<li>2x heddar cheese</li>
					<li>2x bacon</li>
					<li>fried spicy cheese</li>
					<li>rucola</li>
					<li>tomatoes</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A peculiar name for a food product but OK.",
			content: `
				<p>This burger should be subject to some sort of regulation. It's hilarious how many deliciously unhealthy parts it consists of. There are some veggies shyly lurking in this monster but they are barely noticeable. It's like they combined half of your favorite fast foods into one burger.</p>
				<p>In other words: pretty damn delicious.</p>
				<p>Anyway, mediocre standup material aside, it's a good burger. But ultimately it goes a bit overboard for my sensibilities. I know that's the point. But still.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | January 2022"
		},
		{
			type: "image",
			url: require("./Sick.png")
		}
	]
}
