export default {
	title: "Philly Chilly",
	rating: "3",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>hoagie bread roll</li>
					<li>beef slices</li>
					<li>Cheddar cheese</li>
					<li>jalapeños</li>
					<li>caramelized onions</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries (during select hours)</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The tables turn.",
			content: `
				<p>Those aware of my dislike for jalapeños can dismiss the score here. However, I will still note that there's something about the seasoning on their meat coupled with their take on caramelized onions that doesn't quite work for me. If you like your sammiches spicy and are otherwise OK with the rest of the ingredients, I would steer you towards Hood's <a href="/ranking/hood-burger/chilli-cheese-steak-sandwich" class="reference">Chilli Cheese Steak Sandwich</a> instead.</p>
				<p>Some of you might raise your eyebrows at this since I generally refer people to Lars & Sven when they ask me where the best steak sandwiches are. And that still holds for <a href="/ranking/lars-sven/black-angus-roastbeef-steak" class="reference">Black Angus Roastbeef Steak</a> and <a href="/ranking/lars-sven/philly-cheese" class="reference">Philly Cheese</a>. But when it comes to this particular type of config, Hood actually has the superior option.</p>
				<p>If you want an easy cheet sheet, it boils down to the choice of cheese: if you like Provolone on your steak sandwich, go with <a href="/ranking/lars-sven" class="reference">Lars & Sven</a>. If you like Cheddar (and some spiciness), go with <a href="/ranking/hood-burger" class="reference">Hood</a>.
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | January 2022"
		},
		{
			type: "image",
			url: require("./Philly Chilli.jpg")
		}
	]
}
