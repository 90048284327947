import Classic from "./Classic/Classic.js"
import FriedChicken from "./Fried Chicken/Fried Chicken.js"
import GrilledChicken from "./Grilled Chicken/Grilled Chicken.js"
import OpenFaceAngus from "./Open-Face Angus/Open-Face Angus.js"

export default {
	title: "Dežela Okusov",
	tags: [],
	burgers: [
		Classic,
		FriedChicken,
		OpenFaceAngus,
		GrilledChicken
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "http://www.dezela-okusov.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/DezelaOkusov/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d8545387-Reviews-Dezela_Okusov-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
