export default {
	title: "The Duke II",
	rating: "4.5",
	tags: ["special-edition"],
	dish_type: "sandwich",
	meat_types: ["duck"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>slow roasted pulled duck</li>
					<li>mustard with seeds</li>
					<li>cranberry marmalade</li>
					<li>black truffle with honey</li>
					<li>Gruyère cheese</li>
					<li>rucola</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Basically the same as the The Duke 2.0 but with different cheese.",
			content: `
				<p>Human memory of how something tasted is, as we know, unfortunately extremely fickle and unreliable. It would be impossible for me to tell which Duke was better - the 3rd (<a href="/ranking/hood-burger/the-duke-20" class="reference">The Duke 2.0</a>) or the 4th (this one). The only ingredient that changed was the cheese. Now, yes, I could go to the grocery store and buy both types of cheeses to test. But unfortunately, that's not how this works.</p>
				<p>So fuck it. I'm making a purely emotional call here and will be ranking the 3rd version higher. This is because there was no ongoing global pandemic during 2019 and I was able to, you know, actually eat it at the Hood's table.</p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;">This is the 4th version of The Duke. Annoyingly, Hood has opted for a stupidly confusing naming scheme for this dish. See my editorial <a href="/editorial/a-royal-mess" class="reference">'A Royal Mess'</a> and <a href="/editorial/a-royal-mess-2" class="reference">'A Royal Mess 2: The Reckoning'</a> for more information on this topic.</div>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | November 2020"
		},
		{
			type: "image",
			url: require("./The Duke II 2020.jpg")
		}
	]
}
