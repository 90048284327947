import _4umMkI from "./4UM Burger [Mk. I]/4UM Burger [Mk. I].js"
import SmokeyMkI from "./Smokey [Mk. I]/Smokey [Mk. I].js"
import _4um from "./4UM Burger/4UM Burger.js"
import MrBig from "./MrBig/MrBig.js"
import ChickenBurger from "./Chicken Burger/Chicken Burger.js"

export default {
	title: "Forum",
	tags: [],
	burgers: [
		_4umMkI,
		SmokeyMkI,
		_4um,
		MrBig,
		ChickenBurger
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.forumbarljubljana.com"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/ForumBarLjubljana/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d12846827-Reviews-Forum-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
