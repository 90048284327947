import Rog from "./Rog/Rog.js"

export default {
	title: "Kavarna Rog",
	tags: [],
	burgers: [
		Rog
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "http://kavarna-rog.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/kavarnarog.si/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d5484869-Reviews-Kavarna_Rog-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
