export default {
	title: "The Duke 2",
	rating: "4",
	tags: ["special-edition"],
	dish_type: "sandwich",
	meat_types: ["duck"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>slow roasted pulled duck</li>
					<li>Gruyère cheese</li>
					<li>mustard with seeds</li>
					<li>cranberry marmalade</li>
					<li>black truffle with honey</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "An exponential improvement.",
			content: `
				<p>... over <a href="/ranking/hood-burger/the-duke" class="reference">The Duke</a> in every way. Also inexpensive to boot, considering dat spec sheet.</p>
				<p>One of my main objections to the first Duke was the unnecessary duck and beef combo. This year, Hood made some pretty radical changes.</p>
				<p>To start off, they scrapped the last year's inexplicable combo of beef and duck and removed the beef patty. Strictly by the book, this is now a product on a fundamentally lower tier than a burger - a mere <span class="emphasized">sandwich</span>. However, I would urge you to look past this breach of decorum. While the decision might seem scandalous on the surface, it was the correct decision.</p>
				<p>This unprecedented move allowed for a much different flavour pallette. I mean, just look at the config. I feel more blue blooded just reading it! I mean, <span class="emphasized">black truffle with honey</span>? It makes you want to find a peasant and look at them arrogantly and scornfully while eating it.</p>
				<p>I dare say this is the best sandwich Hood has made so far. Yes, even better then the beefy ones. Which I never thought I would say but here we are.</p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;">This is the 2nd version of The Duke. Annoyingly, Hood has opted for a stupidly confusing naming scheme for this dish. See my editorial <a href="/editorial/a-royal-mess" class="reference">'A Royal Mess'</a> and <a href="/editorial/a-royal-mess-2" class="reference">'A Royal Mess 2: The Reckoning'</a> for more information on this topic.</div>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | November 2018"
		},
		{
			type: "image",
			url: require("./The Duke 2 2018.jpg")
		}
	]
}
