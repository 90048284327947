export default {
	title: "Tiskarna",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>white bread bun</li>
					<li>beef patty</li>
					<li>Curry smoke sauce</li>
					<li>Mozzarella cheese</li>
					<li>caramelized onions</li>
					<li>lettuce</li>
					<li>tomatoes</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Decent but not my cup of tea.",
			content: `
				<p>It's a decent enough burger, as all new Tiskarna's burgers tend to be. I just don't care for the sauce.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | April 2023"
		},
		{
			type: "image",
			url: require("./Tiskarna.png")
		}
	]
}
