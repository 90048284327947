export default {
	title: "Texas Burger",
	rating: "1.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>burger butter bun</li>
					<li>beef patty</li>
					<li>BBQ sauce</li>
					<li>cheese</li>
					<li>bacon</li>
					<li>lettuce</li>
					<li>tomatoes</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Baffling.",
			content: `
				<p>This is an OK burger. It doesn't have any pretentious ingredients no bells and whistles. It tastes exactly how you think it will taste when you look at its picture. It's not pretending to be something is not.</p>
				<p>... is what I would have said if it wasn't so ridiculously fucking expensive. Seriously guys, what the fuck.</p>
				<p>To be frank, I don't understand how this establishment manages to survive. If you wanted to eat a slightly worse burger (probably) but for as much as 50% cheaper, there's a McDonald's on literally the same street. And if you actually have good taste there are (sorted by distance) <a href="/ranking/hood-burger" class="reference">Hood Burger</a>, <a href="/ranking/lars-sven" class="reference">Lars & Sven</a> and <a href="/ranking/pops-place" class="reference">Pop's Place</a> all less than five minutes away with burgers that range from cheaper to no more than 15% more expensive. And of course any burger or sandwich from those establishments can easily surpass this one.</p>
				<p>The only reason you should visit Lor&Di is if someone sends you a death threat demanding you do it. And even then I would probably consult some experts first to determine whether or not the threat is credible.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | October 2021"
		},
		{
			type: "image",
			url: require("./Texas.png")
		}
	]
}
