export default {
	title: "Shrimp Burger",
	rating: "2",
	tags: [],
	dish_type: "burger",
	meat_types: [""],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>baked shrimp</li>
					<li>house truffle mayonnaise</li>
					<li>rucola</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Some things just don't belong in a bun.",
			content: `
				<p>This is the farthest thing from a burger on my website. To celebrate this criminally mislabeled dish I've deducted more points from its rating than from any other burger pretender. Which is to say -½ point.</p>
				<p>Moving on, I'm sorry to say but it simply doesn't work. I actually like shrimp a lot and I genuinely think Trappa did as well as they could have. Sadly, my expectations were proven correct - shrimp simply do not belong in a sandwich. Skip this one and go for one of their pork-based "burgers" instead <span class="emphasized" style="font-size: 80%">(Hi, yours truly from the future here; make that <span style="font-style: normal;">"burger"</span>, singular; one of them's been discontinued.)</span>.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | August 2021"
		},
		{
			type: "image",
			url: require("./Shrimp.jpg")
		}
	]
}
