export default {
	title: "Chicken Steak",
	rating: "2",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["chicken"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>hoagie bread roll</li>
					<li>free range chicken breasts</li>
					<li>house greek yogurt sauce</li>
					<li>Mozzarella cheese</li>
					<li>lettuce</li>
					<li>tomato</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries (during select hours)</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "When you look up the definition of 'bland' in a dictionary, it's just a picture of this sandwich.",
			content: `
				<p>I should have seen it coming, honestly. The greek yogurt sauce <span class="emphasized">and</span> Mozzarella should have been ample warning signs. But I expected the chicken and its seasoning to pick up the slack.</p>
				<p>It didn't.</p>
				<p>More or less all I could taste the entire time as I was eating it was the yogurt sauce. It wasn't bad. But it was also not what I came to eat. If I just wanted greek yogurt, I'd go fucking get one.</p> 
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | September 2020"
		},
		{
			type: "image",
			url: require("./Chicken Steak.jpg")
		}
	]
}
