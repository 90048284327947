export default {
	title: "Pulled Beef Burger",
	rating: "3",
	tags: ["special-edition"],
	dish_type: "sandwich",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>pulled beef</li>
					<li>BBQ sauce</li>
					<li>coleslaw</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Decent but hardly memorable.",
			content: `
				<p><span style="font-weight: bold">First visit</span><br />One of the simplest not-really-burgers in terms of ingredients. Still, they pull it off... kind of. My portion unfortunately had way too much barbecue sauce. I'm not really sure if that's the norm or a fluke. Either way, I'm grading based on the food I received not some theoretical optimal serving.</p>
				<p><span style="font-weight: bold">Second visit</span><br />Between the last time and this time I had forgotten I already visited this joint. I only remembered when I sat down to write the review and realized I already had it. Welp. Less work for me!</p>
				Anyway, their food has always been decent enough so I don't regret spending my money on this again. Having said that, I must confess something: I wasn't planning on ordering this particular item. That is because at their stand they had a banner that said "Angus Burger". As any reasonable person would, I interpreted that to mean <span class="reference">a grilled ground beef patty in a bun</span>. Not so! It was a pulled beef <span class="emphasized">sandwich</span>. I feel hoodwinked. Hoodwinked, I tell you!</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | September 2021"
		},
		{
			type: "image",
			url: require("./Pulled Beef.jpg")
		}
	]
}
