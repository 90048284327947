export default {
	title: "Classique Cheese",
	rating: "3",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>white bread bun</li>
					<li>beef patty</li>
					<li>picadilly sauce</li>
					<li>Cheddar cheese</li>
					<li>lettuce</li>
					<li>tomatoes</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Spec bump.",
			content: `
				<p>This is a substantial improvement over the <a href="/ranking/kavarna-tiskarna/classique-cheese-mk-i" class="reference">previous revision</a>. The meat and cheese are in a different league. The sauce is now better as well, no longer bothers me like before.</p>
				<p>The main factor holding it back now - or at least it was on the sample I received - is the mild seasoning. More salt and a bit more aggressive spices would help a lot.</p>
				<p>I can now happily say this is fine. However, Tiskarna has some <a href="/ranking/kavarna-tiskarna" class="reference">much better offerings</a>. I would go for one of those.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | February 2023"
		},
		{
			type: "image",
			url: require("./Classique Cheese.png")
		}
	]
}
