export default {
	title: "Classic Burger",
	rating: "4.5",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>house sauces</li>
					<li>cheese</li>
					<li>bacon</li>
					<li>rucola</li>
					<li>tomato</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "90% of my orders at Dežela Okusov.",
			content: `
				<p>Excellent, this one. I more or less can't name a single complaint against it. It should also be noted that the burger comes standard with some of the best fries I've ever eaten. None of this frozen bullshit. Freshly cut. And they pair with the burger super well, which is sadly not as common as one might think.</p>
				<p>All in all, not much to complain here.</p>
				<p>Now, some of you may criticize me by saying I'm being unfairly generous because Dežela Okusov is located a stone's throw away from my home. This line of criticism, however, is ultimately flawed because it presumes from me a certain level of integrity. I can therefore easily defeat this argument thusly: yes. That was absolutely the contributing factor to the score. So, what are you gonna do about it, bitch?</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		},
		{
			type: "image",
			url: require("./Classic.jpg")
		}
	]
}
