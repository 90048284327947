import Vue from "vue"
import Vuex from "vuex"
import Data from "@/assets/Burgers/Joints.js"
import { slugify } from "@/mixins/Slugify"

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		joints: JSON.parse(JSON.stringify(Data.joints))
	},
	mutations: {
	},
	actions: {
	},
	getters: {
		JointsAndBurgersBySlug (state) {
			const output = {}
			state.joints.forEach(joint => {
				const jointSlug = slugify(joint.title)
				output[jointSlug] = joint
				output[jointSlug].slug = jointSlug
				const jointBurgers = {}
				joint.burgers.forEach(burger => {
					const burgerSlug = slugify(burger.title)
					jointBurgers[burgerSlug] = burger
					jointBurgers[burgerSlug].slug = burgerSlug
				})
				output[jointSlug].burgers = jointBurgers
			})
			return output
		}
	},
	modules: {
	}
})
