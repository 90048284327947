export default {
	title: "Stara Krava",
	rating: "5",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>beef patty</li>
					<li>mayonnaise sauce</li>
					<li>hot sauce</li>
					<li>Cheddar cheese</li>
					<li>carrots</li>
					<li>radish</li>
					<li>baby spinach</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Christians: 1, Atheists: 0",
			content: `
				<p>It is simply not possible for sinners such as ourselves to create something so profound, so sacred. I am therefore forced to accept the only possible explanation: God himself has descended from the heavens to guide the hands of the chefs that conceived of and actualized this sacred dish. A bona fide deus ex machina!</p>
				<p>I saw this burger by chance during a food-centric event on Ljubljana's marketplace (Odprta kuhna). As I am sworn to do I ordered it reflexively. Seeing the ingredients - they didn't have them listed so I snuck a peek behind the counter - my expectations were relatively low. This initial skepticism is now but a distant memory of youthful naïveté.	After a bite or two, a single tear rolled down my cheek. This was it. After all these years, I now finally understand the sheer magnitude of the greatness that is our Lord and Saviour. Praise be.</p>
				<p>This burger is basically engineered as a flex from the ground up. It has hands down the best meat blend I have ever tasted. It blows <a href="/ranking/pops-place" class="reference">Pop's Place</a> - whose meat blends I love - out of the water without breaking a sweat. The initially seemingly random and quirky choice of veggies reveals itself to be anything but - the relative neutrality of carrots and spinach and the conservative use of radish respectfully step aside and let the meat shine, serving only as a slight counterweight so the burger doesn't feel too heavy. The hot sauce I was initially also skeptical about turned out to be fairly mild and gave the burger a pleasant kick.</p>
				<p>This is quite literally the first time I am left with no suggestions, no caveats, no asterisks. I simply cannot think of a way to improve this burger.</p>
				<p>Bravo.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | April 2023"
		},
		{
			type: "image",
			url: require("./Klasik.jpg")
		}
	]
}
