export default {
	title: "The Dark Vader Spicy Burger",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>dry aged double beef patty</li>
					<li>honey-mustard sauce</li>
					<li>Cheddar cheese</li>
					<li>creme fraiche</li>
					<li>fried egg</li>
					<li>crispy bacon</li>
					<li>tomato</li>
					<li>lettuce</li>
					<li>chilli</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Thicc.",
			content: `
				<p>As soon as I saw 'creme fraiche' in the config, I knew I had to try it. I was a bit skeptical as to whether it would work with other ingredients and was pleasantly surprised by the result.</p>
				<p>Obligatory warning to the uninitiated: this burger is <span class="emphasized">stacked</span>. And you know that's quite a statement, coming from me. Do not just casually waltz into the restaurant and order this. At the very least go on an empty stomach.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		}
	]
}
