export default {
	title: "Open-Face Angus Burger",
	rating: "3",
	tags: ["discontinued"],
	dish_type: "sandwich",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>toast</li>
					<li>Angus beef steak</li>
					<li>house sauces</li>
					<li>cheese</li>
					<li>rucola</li>
					<li>lettuce</li>
					<li>tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The hogwash that does not belong on this list.",
			content: `
				<p>This isn't a burger, first of all. It's not even a sandwich. It's slices of steak placed on a bed of slices of toast and rucola. So why is it on this list?</p>
				<p>Because I fell victim to lies and deceit. And the best way I know how to expose my hoodwinkers is by including their malarkey of a "burger" on the list and trashing them in the review. You know, like normal people do.</p>
				<p>First of all, this dish is listed under burgers. Poppycock! This is the farthest thing from it on this list. Second, the ingredients listed on the menu include a wholegrain bun. Horsefeathers! Slices of toast and buns are obviously not the same thing.</p>
				<p>Anyway, let's move on. I've run out of colorful ways to say <span class="emphasized">"nonsense"</span>.</p>
				<p>It was a decent dish, but as far as steak sandwiches go, there are better options available. Consider Hood Burger's <span class="reference">Chilli Cheese Steak Sandwich</span> or Lars & Sven's <span class="reference">Philly Cheese</span> instead.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2017"
		}
	]
}
