import Gastro from "./Gastro/Gastro.js"

export default {
	title: "Gastro House 151",
	tags: [],
	burgers: [
		Gastro
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.gastrohouse.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/gastrohouse151"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d11661933-Reviews-Gastro_House_151-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
