import _7Burger from "./Joints/7Burger/7Burger.js"
import AjdaPremiumBurger from "./Joints/Ajda Premium Burger/Ajda Premium Burger.js"
import AllegroBistroLoungeBar from "./Joints/Allegro Bistro & Lounge Bar/Allegro Bistro & Lounge Bar.js"
import Americano from "./Joints/Americano/Americano.js"
import AsAperitivo from "./Joints/As Aperitivo/As Aperitivo.js"
import Azur from "./Joints/Azur/Azur.js"
import BazenBarBBQ from "./Joints/Bazen Bar & BBQ/Bazen Bar & BBQ.js"
import BezigrajskiDvor from "./Joints/Bezigrajski Dvor/Bezigrajski Dvor.js"
import BiljardnaHisaLjubljana from "./Joints/Biljardna Hisa Ljubljana/Biljardna Hisa Ljubljana.js"
import BiljardnicaDirekt from "./Joints/Biljardnica Direkt/Biljardnica Direkt.js"
import BirdieFoodielab from "./Joints/Birdie Foodielab/Birdie Foodielab.js"
import Boschitz from "./Joints/Boschitz/Boschitz.js"
import Brunch from "./Joints/Brunch/Brunch.js"
import BuldogBar from "./Joints/Buldog Bar/Buldog Bar.js"
import BurgerTime from "./Joints/Burger Time/Burger Time.js"
import BurekOlimpijaSiska from "./Joints/Burek Olimpija Siska/Burek Olimpija Siska.js"
import CubeBurger from "./Joints/Cube Burger/Cube Burger.js"
import dBurger from "./Joints/d'Burger/d'Burger.js"
import DezelaOkusov from "./Joints/Dezela Okusov/Dezela Okusov.js"
import DnevnibarprMrtinet from "./Joints/Dnevni bar pr' Mrtinet/Dnevni bar pr' Mrtinet.js"
import DvorniBar from "./Joints/Dvorni Bar/Dvorni Bar.js"
import FanyMary from "./Joints/Fany & Mary/Fany & Mary.js"
import Fast5House from "./Joints/Fast 5 House/Fast 5 House.js"
import FilipsBackyard from "./Joints/Filip's Backyard/Filip's Backyard.js"
import FNX from "./Joints/FNX/FNX.js"
import Forum from "./Joints/Forum/Forum.js"
import Frks from "./Joints/Frks/Frks.js"
import GastroHouse151 from "./Joints/Gastro House 151/Gastro House 151.js"
import GostilnicainPivnicaSiska from "./Joints/Gostilnica in Pivnica Siska/Gostilnica in Pivnica Siska.js"
import GostilnicainPivnicaVic from "./Joints/Gostilnica in Pivnica Vic/Gostilnica in Pivnica Vic.js"
import GourmeThomas from "./Joints/GourmeThomas/GourmeThomas.js"
import HaloPinki from "./Joints/Halo Pinki/Halo Pinki.js"
import HaloPodneviPonoci from "./Joints/Halo Podnevi & Ponoci/Halo Podnevi & Ponoci.js"
import HaratsPub from "./Joints/Harat's Pub/Harat's Pub.js"
import HillBillys from "./Joints/HillBilly's/HillBilly's.js"
import HisaPodGradom from "./Joints/Hisa Pod Gradom/Hisa Pod Gradom.js"
import HoodBurger from "./Joints/Hood Burger/Hood Burger.js"
import HotHorse from "./Joints/Hot Horse/Hot Horse.js"
import HotspotBarinBistro from "./Joints/Hotspot Bar in Bistro/Hotspot Bar in Bistro.js"
import IrishPub from "./Joints/Irish Pub/Irish Pub.js"
import JackJoe from "./Joints/Jack & Joe/Jack & Joe.js"
import JurmanFoodCorner from "./Joints/Jurman Food Corner/Jurman Food Corner.js"
import Kastrola from "./Joints/Kastrola/Kastrola.js"
import KavarnaRog from "./Joints/Kavarna Rog/Kavarna Rog.js"
import KavarnaTiskarna from "./Joints/Kavarna Tiskarna/Kavarna Tiskarna.js"
import KinoBezigrad from "./Joints/Kino Bezigrad/Kino Bezigrad.js"
import KraljZara from "./Joints/Kralj Zara/Kralj Zara.js"
import Kubus from "./Joints/Kubus/Kubus.js"
import KuhinjaKrusic from "./Joints/Kuhinja Krusic/Kuhinja Krusic.js"
import LarsSven from "./Joints/Lars & Sven/Lars & Sven.js"
import LePetitCafe from "./Joints/Le Petit Cafe/Le Petit Cafe.js"
import LorDi from "./Joints/Lor&Di/Lor&Di.js"
import MaisterPub from "./Joints/Maister Pub/Maister Pub.js"
import Majmun from "./Joints/Majmun/Majmun.js"
import MangoopBurgers from "./Joints/Mangoop Burgers/Mangoop Burgers.js"
import MeatBusiness from "./Joints/Meat Business/Meat Business.js"
import MiniCafeGolf from "./Joints/Mini Cafe Golf/Mini Cafe Golf.js"
import OazaPEF from "./Joints/Oaza PEF/Oaza PEF.js"
import OMAMIBurgers from "./Joints/OMAMI Burgers/OMAMI Burgers.js"
import OPAResort from "./Joints/OPA Resort/OPA Resort.js"
import OsterijaPrNoni from "./Joints/Osterija Pr'Noni/Osterija Pr'Noni.js"
import ParkZibertDriveIn from "./Joints/Park Zibert Drive-In/Park Zibert Drive-In.js"
import PisekBar from "./Joints/Pisek Bar/Pisek Bar.js"
import PivnicaUnion from "./Joints/Pivnica Union/Pivnica Union.js"
import PopsPlace from "./Joints/Pop's Place/Pop's Place.js"
import PostaSlovenije from "./Joints/Posta Slovenije/Posta Slovenije.js"
import PriBabiciMarici from "./Joints/Pri babici Marici/Pri babici Marici.js"
import ProjektBurger from "./Joints/Projekt Burger/Projekt Burger.js"
import PrPandi from "./Joints/Pr' Pandi/Pr' Pandi.js"
import Punkt from "./Joints/Punkt/Punkt.js"
import RacaGastroBar from "./Joints/Raca GastroBar/Raca GastroBar.js"
import RexBurgerPivoSport from "./Joints/Rex Burger Pivo Sport/Rex Burger Pivo Sport.js"
import Romansa1971 from "./Joints/Romansa 1971/Romansa 1971.js"
import Soba102 from "./Joints/Soba 102/Soba 102.js"
import SorbaraSteakHouse from "./Joints/Sorbara Steak House/Sorbara Steak House.js"
import StaraFabrka from "./Joints/Stara Fabrka/Stara Fabrka.js"
import StariPisker from "./Joints/Stari Pisker/Stari Pisker.js"
import StazioneParenzanaLjubljana from "./Joints/Stazione Parenzana Ljubljana/Stazione Parenzana Ljubljana.js"
import SuperHrust from "./Joints/Super Hrust/Super Hrust.js"
import SuperPsanc from "./Joints/Super Psanc/Super Psanc.js"
import Svetilnik from "./Joints/Svetilnik/Svetilnik.js"
import TabayBrothers from "./Joints/Tabay Brothers/Tabay Brothers.js"
import TexasBBQLjubljana from "./Joints/Texas BBQ Ljubljana/Texas BBQ Ljubljana.js"
import TINKSuperfoodCafe from "./Joints/TINK Superfood Cafe/TINK Superfood Cafe.js"
import TokyoPiknik from "./Joints/Tokyo Piknik/Tokyo Piknik.js"
import TopChef from "./Joints/Top Chef/Top Chef.js"
import Trappa from "./Joints/Trappa/Trappa.js"
import UrbanPiqniq from "./Joints/Urban Piqniq/Urban Piqniq.js"
import VijaVaja from "./Joints/Vija Vaja/Vija Vaja.js"
import WildBrothers from "./Joints/Wild Brothers/Wild Brothers.js"
import Zanoodle from "./Joints/Zanoodle/Zanoodle.js"
import Zbornica from "./Joints/Zbornica/Zbornica.js"

export default {
	joints: [
		_7Burger,
		AjdaPremiumBurger,
		AllegroBistroLoungeBar,
		Americano,
		AsAperitivo,
		Azur,
		BazenBarBBQ,
		BezigrajskiDvor,
		BiljardnaHisaLjubljana,
		BiljardnicaDirekt,
		BirdieFoodielab,
		Boschitz,
		Brunch,
		BuldogBar,
		BurgerTime,
		BurekOlimpijaSiska,
		CubeBurger,
		dBurger,
		DezelaOkusov,
		DnevnibarprMrtinet,
		DvorniBar,
		FanyMary,
		Fast5House,
		FilipsBackyard,
		FNX,
		Forum,
		Frks,
		GastroHouse151,
		GostilnicainPivnicaSiska,
		GostilnicainPivnicaVic,
		GourmeThomas,
		HaloPinki,
		HaloPodneviPonoci,
		HaratsPub,
		HillBillys,
		HisaPodGradom,
		HoodBurger,
		HotHorse,
		HotspotBarinBistro,
		IrishPub,
		JackJoe,
		JurmanFoodCorner,
		Kastrola,
		KavarnaRog,
		KavarnaTiskarna,
		KinoBezigrad,
		KraljZara,
		Kubus,
		KuhinjaKrusic,
		LarsSven,
		LePetitCafe,
		LorDi,
		MaisterPub,
		Majmun,
		MangoopBurgers,
		MeatBusiness,
		MiniCafeGolf,
		OazaPEF,
		OMAMIBurgers,
		OPAResort,
		OsterijaPrNoni,
		ParkZibertDriveIn,
		PisekBar,
		PivnicaUnion,
		PopsPlace,
		PostaSlovenije,
		PriBabiciMarici,
		ProjektBurger,
		PrPandi,
		Punkt,
		RacaGastroBar,
		RexBurgerPivoSport,
		Romansa1971,
		Soba102,
		SorbaraSteakHouse,
		StaraFabrka,
		StariPisker,
		StazioneParenzanaLjubljana,
		SuperHrust,
		SuperPsanc,
		Svetilnik,
		TabayBrothers,
		TexasBBQLjubljana,
		TINKSuperfoodCafe,
		TokyoPiknik,
		TopChef,
		Trappa,
		UrbanPiqniq,
		VijaVaja,
		WildBrothers,
		Zanoodle,
		Zbornica
	]
}
