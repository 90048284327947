export default {
	title: "Gourmet Burger",
	rating: "4",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>Black Angus beef patty</li>
					<li>special sauce</li>
					<li>Cheddar cheese</li>
					<li>red onion jam</li>
					<li>marinated beetroot</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Beetroot, anyone?",
			content: `
				<p>Great burger. But it's basically <a href="/ranking/tokyo-piknik/black-angus-burger" class="reference">Angus Burger</a> plus beetroot. And so far I must confess I am not sold on beetroot as a burger condiment. Other than that, obviously fantastic.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | September 2019"
		},
		{
			type: "image",
			url: require("./Gourmet.jpg")
		}
	]
}
