import Boss from "./Boss/Boss.js"
import Cube from "./Cube/Cube.js"
import Kraljevi from "./Kraljevi/Kraljevi.js"
import RostBeef from "./Rost Beef/Rost Beef.js"

export default {
	title: "Cube Burger",
	tags: [],
	burgers: [
		Kraljevi,
		Boss,
		Cube,
		RostBeef
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://cubeburger.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/cube.burger.lj/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d7009258-Reviews-Cube_burger-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
