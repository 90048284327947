export default {
	title: "Lady Marmalade",
	rating: "3",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>vinaigrette sauce</li>
					<li>Gorgonzola Dolce DOP cheese</li>
					<li>Merlot jam</li>
					<li>crispy pancetta</li>
					<li>sautéed onions</li>
					<li>radicchio</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "True to its name, I'll give it that.",
			content: `
				<p>This burger has a very particular taste. It is not going to be for everyone, maybe not even for most people. That said, personally I find it pretty good. I have some minor critiques - I found the jam a touch too sweet, for example, which throws the balance off - but the burger holds up. I recommend trying it if you get tired of the typical burger taste and wanna spice things up.</p>
				<p>Metaphorically speaking I mean, just to be clear. The burger is not actually spicy.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | May 2019"
		},
		{
			type: "image",
			url: require("./Lady Marmalade.jpg")
		}
	]
}
