import Jezersek360 from "./Jezersek 360/Jezersek 360.js"

export default {
	title: "Pivnica Union",
	tags: [],
	burgers: [
		Jezersek360
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://union-experience.si/sl/dozivi-union-pivnico"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: ""
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d7199778-Reviews-Pivnica_Union-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
