export default {
	title: "American Classic",
	rating: "3",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>2x beef patty</li>
					<li>house sauce</li>
					<li>Cheddar cheese</li>
					<li>fried onions</li>
					<li>iceberg lettuce</li>
					<li>tomatoes</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Not great, not mediocre.",
			content: `
				<p>This is a mostly middle of the road burger - OK meat, sauce a bit bland - with two things that elevate it slightly: caramelized onions (not fried, as the menu says) and a generous amount of cheese.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | December 2021"
		},
		{
			type: "image",
			url: require("./American Classic.png")
		}
	]
}
