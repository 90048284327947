import Beefburgerwithpancettaandcheddarcheese from "./Beef burger with pancetta and cheddar cheese/Beef burger with pancetta and cheddar cheese.js"
import Burgerwithbeefbriecheeseandaspargus from "./Burger with beef brie cheese and aspargus/Burger with beef brie cheese and aspargus.js"
import Crispychickenburger from "./Crispy chicken burger/Crispy chicken burger.js"

export default {
	title: "Stazione Parenzana Ljubljana",
	tags: [],
	burgers: [
		Beefburgerwithpancettaandcheddarcheese,
		Crispychickenburger,
		Burgerwithbeefbriecheeseandaspargus
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.stazione-ljubljana.com/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/profile.php?id=100089959380049"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d25307030-Reviews-Stazione_Parenzana_Ljubljana-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: `
		<p>The names of these burgers are almost as bad as (most) of the burgers themselves. Why list the ingredients in the name? Not only does it make for dumb names, the long names throw the formatting on my page completely outta whack. Very inconsiderate.</p>
		<p>And don't even get me started on the missing Oxford comma.</p>
	`
}
