export default {
	title: "d'Ojaja",
	rating: "4.5",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>sesame bread bun</li>	
					<li>beef patty</li>
					<li>house sauce</li>
					<li>BBQ sauce</li>
					<li>Cheddar cheese</li>
					<li>crispy bacon</li>
					<li>caramelized onions</li>
					<li>egg</li>
					<li>coleslaw</li>
					<li>tomato</li>
					<li>lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Seems like sensory overload on paper. But they somehow pull it off.",
			content: `
				<p>Just look at that spec sheet. In most other establishments, a burger like d'Burger's own <a href="/ranking/dburger/dslaninc" class="reference">d'Slaninc</a> would be considered a flagship. Not here. This one has all of the same ingredients as d'Slaninc <span class="emphasized">plus</span> barbecue sauce, a fried egg, and coleslaw. And just like d'Slaninc, it pulls everything off brilliantly.</p>
				<p>Yet a lengthy list of ingredients is not without its drawbacks. This burger is messy. It rivals those of <a href="/ranking/hood-burger" class="reference">Hood Burger</a> in its refusal to keep all the components neatly nestled within its bun. So I guess be prepared for that.
				<p>I also have some minor misgivings about coleslaw. It doesn't bother me but also doesn't really add much to the overall package. There are already other veggies present, after all.</p>
				<p>These drawbacks are ultimately very minor, however. This burger is one of my all-time favorites and I recommend it with no hesitation.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2020"
		},
		{
			type: "image",
			url: require("./d'Ojaja.png")
		}
	]
}
