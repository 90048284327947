export default {
	title: "Grilled Chicken Burger",
	rating: "2.5",
	tags: ["discontinued"],
	dish_type: "sandwich",
	meat_types: ["chicken"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>grilled chicken fillet</li>
					<li>house sauce</li>
					<li>tomato</li>
					<li>rucola</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Bean counter's wet dream.",
			content: `
				<p>They simply took their <a href="/ranking/dezela-okusov/classic-burger" class="reference">Classic Burger</a> and swapped the beef patty, cheese, and bacon, for chicken. I'm sure the big wigs on the top floor were hella impressed. Must've looked real' nice on the P&L sheet.</p>
				<p>It's not bad, per se. But I'm also not impressed. That's not how you make a good burger. Well, sandwich, in this case.</p>
				<p>Chicken sandwiches require a different selection of condiments than a regular burger to really stand out. Even simply tweaking the sauce a little to optimize it for poultry instead of beef would be a massive improvement. But didn't feel like doing that. So I don't feel like being forgiving in my rating.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018"
		}
	]
}
