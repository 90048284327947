export default {
	title: "Maxim Burger",
	rating: "0.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>mayonaise</li>
					<li>cream cheese</li>
					<li>Cheddar cheese</li>
					<li>lettuce</li>
					<li>tomatoes</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "There are abusive households out there less emotionally damiging than this burger.",
			content: `
				<p>We have a 2022 Worst Burger Award winner. In the second week of January.</p>
				<p>To say this burger came slathered in sauce (well, cream cheese, technically) would be an understatement. The only plausible explanation is that it was submerged in it. And not just for a few seconds, either. Both bun slices were fucking <span class="emphasized">drenched</span>. And to add insult to injury, the sauce wasn't even good.</p>
				<p>By no means does the bad stop there. The meat was average at best. The cheese on top of it was not even melted. And the image on Wolt portrays it with fries but if you actually want fries, it's a surcharge. And relatively high one, too.</p>
				<p>At this point Romansa is in my bottom five burger vendors for sure. Maybe even bottom three. Which is really weird. Other food there is decent. So it makes me sad that they're compelling me to shit on them. But them's the rules.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | January 2022"
		},
		{
			type: "image",
			url: require("./Maxim.png")
		}
	]
}
