import Classic from "./Classic/Classic.js"
import FlyingChicken from "./Flying Chicken/Flying Chicken.js"
import KrpanBacon from "./Krpan Bacon/Krpan Bacon.js"
import Tartuf from "./Tartuf/Tartuf.js"
import Country from "./Country/Country.js"

export default {
	title: "Fany & Mary",
	tags: [],
	burgers: [
		KrpanBacon,
		Classic,
		Tartuf,
		FlyingChicken,
		Country
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/fanymary/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d6882021-Reviews-Fany_Mary-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
