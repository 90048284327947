import BlackAngus from "./Black Angus/Black Angus.js"
import Gourmet from "./Gourmet/Gourmet.js"

export default {
	title: "Tokyo Piknik",
	tags: [],
	burgers: [
		BlackAngus,
		Gourmet
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://tokyopiknik.com/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/TokyoPiknik/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d4507705-Reviews-Tokyo_Piknik-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: "Tokyo Piknik still lists burgers on their menu online, years after they stop selling them. It's like they are mocking me personally."
}
