import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		redirect: "/ranking"
	},
	{
		path: "/ranking/:joint/:burger",
		name: "BurgerDetails",
		component: () => import(/* webpackChunkName: "BurgerDetails" */ "@/views/Burgers/Details.vue"),
		meta: {
			scrollToTop: true
		}
	},
	{
		path: "/ranking/:joint",
		name: "JointDetails",
		component: () => import(/* webpackChunkName: "JointDetails" */ "@/views/Joints/Details.vue"),
		meta: {
			scrollToTop: true
		}
	},
	{
		path: "/ranking",
		name: "Burgers",
		component: () => import(/* webpackChunkName: "Burgers" */ "@/views/Burgers/index.vue")
	},
	{
		path: "/philosophy",
		name: "Philosophy",
		component: () => import(/* webpackChunkName: "Philosophy" */ "@/views/Philosophy/index.vue")
	},
	{
		path: "/stats/",
		name: "Stats",
		component: () => import(/* webpackChunkName: "Stats" */ "@/views/Stats/index.vue")
	},
	{
		path: "/editorial",
		name: "Editorial",
		component: () => import(/* webpackChunkName: "Editorial" */ "@/views/Editorial/index.vue"),
		redirect: "/editorial/semantics",
		children: [
			{
				path: "raison-detre",
				component: () => import(/* webpackChunkName: "Why" */ "@/views/Editorial/Components/Content/Why.vue")
			},
			{
				path: "semantics",
				component: () => import(/* webpackChunkName: "SomeVitalSemantics" */ "@/views/Editorial/Components/Content/Semantics.vue")
			},
			{
				path: "a-terrible-secret",
				component: () => import(/* webpackChunkName: "HoodBurger" */ "@/views/Editorial/Components/Content/ATerribleSecret.vue")
			},
			{
				path: "lars-and-sven",
				component: () => import(/* webpackChunkName: "LarsAndSven" */ "@/views/Editorial/Components/Content/LarsAndSven.vue")
			},
			{
				path: "hood-vs-lars",
				component: () => import(/* webpackChunkName: "HoodVsLars" */ "@/views/Editorial/Components/Content/HoodVsLars.vue")
			},
			{
				path: "kavarna-tiskarna",
				component: () => import(/* webpackChunkName: "KavarnaTiskarna" */ "@/views/Editorial/Components/Content/KavarnaTiskarna.vue")
			},
			{
				path: "good-but-not-great",
				component: () => import(/* webpackChunkName: "GoodButNotGreat" */ "@/views/Editorial/Components/Content/GoodButNotGreat.vue")
			},
			{
				path: "a-royal-mess",
				component: () => import(/* webpackChunkName: "ARoyalMess" */ "@/views/Editorial/Components/Content/ARoyalMess.vue")
			},
			{
				path: "a-royal-mess-2",
				component: () => import(/* webpackChunkName: "ARoyalMess2TheReckoning" */ "@/views/Editorial/Components/Content/ARoyalMess2TheReckoning.vue")
			}
		]
	}
]

const router = new VueRouter({
	routes,
	scrollBehavior (to, from, savedPosition) {
		if (to.meta.scrollToTop) {
			window.scrollTo(0, 0)
		} else if (savedPosition) {
			return savedPosition
		} else {
			window.scrollTo(0, 0)
		}
	},
	mode: "history"
})

export default router
