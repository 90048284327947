export default {
	title: "Hood Chick Sandwich",
	rating: "2.5",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["chicken"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>fried chicken thighs</li>
					<li>house ranch sauce</li>
					<li>chopped celery</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Fucking pickles, everywhere you look.",
			content: `
				<p>Hood says the chicken thighs are marinated in buttermilk with secret spice mix, coated with rice flour and marinated in peanut oil. Just thought I'd mention it.</p>
				<p>This sandwich actually has the potential to be very good. But the long time readers will know that I am no fan of pickles. And in this particular case, Hood has decided to go all out. They fill the whole bottom layer with them. I have never received more pickles in any sandwich. It's to the point where even some of my friends far more tolerant of pickles than I said it was just too much. Based on that not at all vague and highly representative sample size, I feel confident in declaring that this ain't it, chief.</p>
				<p>If you like pickles, have at it. If you don't but would still like fried chicken, just order this without the pickles. A config like that I would easily give a whole extra point.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | June 2018"
		},
		{
			type: "image",
			url: require("./Hood Chick.jpg")
		}
	]
}
