export default {
	title: "Golf Burger",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>mustard-honey sauce</li>
					<li>Cheddar cheese</li>
					<li>caramelized onions </li>
					<li>crispy shallot</li>
					<li>iceberg lettuce</li>
					<li>tomatoes</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "I ordered this burger for delivery on a fairly chilly autumn day. It arrived almost cold. And it was still one of the best burgers I tried in 2021.",
			content: `
				<p>The even sadder thing about this whole ordeal - not the burger itself but rather what it says about the state of burgerdom lately - is that the burger's taste immediately seemed oddly familiar. For a while I couldn't quite place it. Only later that week when the name caught my eye in a messenger app did it hit me: this burger was basically an improved version of the burger I ate at my friend <a href="/ranking/filips-backyard" class="reference">Filip's</a> house.</p>
				<p>Yes, what I'm unironically saying is that Food Corner Tivoli is one of the rare joints capable of putting together a better burger than what you could make at home with just off-the-shelf ingredients from a grocery store, buns included. Which is really sad.</p>
				<p>The burger has no obvious flaws. The meat is seasoned conservatively (in a good way) and was grilled to a perfect medium rare. The cheese was exactly what it should have been. The veggies were fresh. The caramelized onions were maybe just a touch too sweet but honestly, we are now firmly in the nitpicking territory.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | May 2022"
		},
		{
			type: "image",
			url: require("./Golf.png")
		}
	]
}
