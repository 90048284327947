export default {
	title: "Beef & Bacon Burger",
	rating: "4",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>sauce</li>
					<li>cheese</li>
					<li>bacon</li>
					<li>lettuce</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "An upsettingly good burger.",
			content: `
				<p>... considering it's sold at a regular pub, not even a burger joint.</p>
				<p>In order to explain the lead, allow me to describe the circumstances under which I came to try this burger.</p>
				<p>A friend of mine invited me to the Irish Pub on Friday. Being somewhat friendly with the owner my friend exchanged a few pleasantries as he came to the table to take our order. We noticed that he seemed, shall we say, inordinately jovial. He explained that one of his waitresses opened her own pub that day and they had a bit too much to drink, celebrating the occasion. Regardless, he powered through, took our order and left.</p>
				<p>A few minutes later we had received the beers but there were no signs of burgers. However, the owner was the only one working and it was a busy night so we didn't think much of it.</p>
				<p>About ten minutes later he came to our table and apologetically confessed to forgetting our food order. We laughed it off and repeated our order. He disappeared once again and this time, a pleasant sizzling was soon heard from the kitchen.</p>
				<p>A few seconds later he was out of the kitchen and back to serving beers. As this went on I grew increasingly worried as it seemed to me that those burgers had been left on the grill for too long. At some point the same thought must have struck the owner as we saw him rushing comically to the kitchen. Soon after that, we finally received our burgers.</p>
				<p>The first thing we noticed was that the owner forgot to put bacon in. The burger was also a bit too done for my taste. My friend and I quipped that given the chef's inebriation, we should be lucky the burgers weren't burnt.</p>
				<p>I laid out this story because I want you to understand the gradual lowering of expectation occurring in my head as all of this was going on. And I want you to understand that <span class="emphasized">even after all this</span>, the burger still came out better than much of the competition on this list <span class="emphasized">from the actual burger joints and restaurants</span>. That's crazy. And sad, most of all.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | May 2019"
		}
	]
}
