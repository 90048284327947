export default {
	title: "Slider Duo",
	rating: "4.5",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>beef patty</li>
					<li>truffle aioli</li>
					<li>Cheddar cheese</li>
					<li>caramelized onion marmalade</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Basically The Boss but with Cheddar instead of Stilton and in two small burgers instead of a single bigger one.",
			content: `
				<p>So, you know: perfect. Which means it just comes down to which cheese you prefer. You can guess my preference from the scores of both burgers.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018 (Uncertain)"
		},
		{
			type: "image",
			url: require("./Slider Duo.jpg")
		}
	]
}
