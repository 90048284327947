import ThePrincipal from "./The Principal/The Principal.js"
import Smokey from "./Smokey/Smokey.js"
import ProfessorLuigi from "./Professor Luigi/Professor Luigi.js"

export default {
	title: "Zbornica",
	tags: [],
	burgers: [
		ThePrincipal,
		Smokey,
		ProfessorLuigi
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.zbornicabar.com/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/ZbornicaBarZar/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d12930051-Reviews-Zbornica-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
