<template>
	<div class="navbar">
		<div class="navbar__master-wrapper">
			<a href="/">	<!-- PROBABLY NOT CORRECTLY IMPLEMENTED :D -->
				<div class="navbar__home">
					<img class ="logo" src="../assets/Images/Icons/fullBurger.png" />
					<span class ="site-name">Burg<span class ="site-name__period">.</span>si</span>
				</div>
			</a>
			<div class="navbar__tablinks-wrapper">
				<router-link
					:to="{ name: 'Burgers' }"
					class="navbar__tablinks navbar__tablinks--ranking"
				>
					<Icon
						:icon="['fas', 'list-ol']"
						class="navbar__tablinks--ranking__icon"
					/>
					<span>Ranking</span>
				</router-link>
				<router-link
					:to="{ name: 'Philosophy' }"
					class="navbar__tablinks navbar__tablinks--philosophy"
				>
					<Icon
						:icon="['fas', 'graduation-cap']"
						class="navbar__tablinks--philosophy__icon"
					/>
					<span>Philosophy</span>
				</router-link>
				<router-link
					:to="{ name: 'Editorial' }"
					class="navbar__tablinks navbar__tablinks--editorial"
				>
					<Icon
						:icon="['fas', 'newspaper']"
						class="navbar__tablinks--editorial__icon"
					/>
					<span>Editorial</span>
				</router-link>
			</div>
			<button
				class="navbar__hamburger-button"
				@click="navigationOpen = !navigationOpen"
			>
				<Icon
					v-if="!navigationOpen"
					:icon="['fas', 'bars']"
				/>
				<Icon
					v-else
					:icon="['fas', 'xmark']"
				/>
			</button>
		</div>
		<transition tag="template">
			<div v-if="navigationOpen" class="navbar__mobile-links-container">
				<router-link
					:to="{ name: 'Burgers' }"
					class="navbar__mobile-link navbar__mobile-link--ranking"
					@click.native.stop="navigationOpen = false"
				>
					<Icon :icon="['fas', 'list-ol']"/>
					<span>Ranking</span>
				</router-link>
				<router-link
					:to="{ name: 'Philosophy' }"
					class="navbar__mobile-link navbar__mobile-link--philosophy"
					@click.native.stop="navigationOpen = false"
				>
					<Icon :icon="['fas', 'graduation-cap']"/>
					<span>Philosophy</span>
				</router-link>
				<router-link
					:to="{ name: 'Editorial' }"
					class="navbar__mobile-link navbar__mobile-link--editorial"
					@click.native.stop="navigationOpen = false"
				>
					<Icon :icon="['fas', 'newspaper']"/>
					<span>Editorial</span>
				</router-link>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	data () {
		return {
			navigationOpen: false
		}
	}
}
</script>

<style lang="scss">
	.navbar {
		padding: 0;
		text-align: center;
		background: #2a2a2a;
		position: sticky;
		top: 0;
		margin: 0;
		width: 100%;
		z-index: 100;
		box-shadow: 0 0 13px #0000001f;

		.navbar__master-wrapper {
			box-sizing: border-box;
			color: #141414;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 0;
			padding: 0 100px;
			height: 86px;
			box-shadow: 0 0 13px #0000001f;

			@media only screen and (max-width: 1200px) {
				padding: 0 40px;
			}

			@media only screen and (max-width: 820px) {
				padding: 0 30px;
			}

			.navbar__home,
			.navbar__tablinks {
				color: #a7a7a7;
				cursor: pointer;
				display: inline-block;
				font-size: 22px;
				letter-spacing: 2px;
				text-decoration: none;
				line-height: 25px;
				padding: 20px 0;

				svg {
					margin-right: 10px;
				}
			}

			.navbar__home {
				justify-content: flex-start;
				display: flex;
				align-items: center;
				padding: 20px 0;
				font-size: 28px;

				.logo {
					display: inline-block;
					max-height: 46px;
					margin-top: -5px;
				}

				.site-name {
					display: flex;
					font-family: "Open Sans", sans-serif;
					margin-left: 20px;
					text-transform: uppercase;
					color: #fff;
					font-weight: 700;

					.site-name__period {
						font-size: 9px;
						line-height: initial;
						display: flex;
						align-items: flex-end;
					}
				}
			}

			.navbar__hamburger-button {
				background: transparent;
				border: none;
				border-radius: 5px;
				height: 38px;
				width: 38px;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: background 0.2s;
				background: rgba(#EF9443, 0);
				cursor: pointer;

				@media only screen and (min-width: 821px) {
					display: none;
				}

				&:hover {
					background: #EF9443;
				}

				svg {
					font-size: 22px;
					color: rgba(white, 0.9);
				}
			}

			.navbar__tablinks-wrapper {
				justify-content: flex-end;
				text-transform: uppercase;

				@media only screen and (max-width: 820px) {
					display: none;
				}

				.navbar__tablinks {
					margin-left: 24px;
					font-weight: 400;
					letter-spacing: 1.5px;

					@media only screen and (max-width: 1200px) and (min-width: 820px) {
						font-size: 18px;
						margin-left: 20px;
					}

					@media only screen and (max-width: 820px) {
						font-size: 125%;
						line-height: 13px;
						margin: 5px;
					}

					&.router-link-active {
						color: #fff;
						font-weight: 600;
						letter-spacing: 1.5px;
					}

					&:hover {
						color: #fff;
						/*border-radius: 3px;*/
					}

					.navbar__tablinks--ranking__icon,
					.navbar__tablinks--philosophy__icon,
					.navbar__tablinks--editorial__icon {
						margin-top: 3px;
					}
				}
			}
		}

	.navbar__mobile-links-container {
		position: fixed;
		top: 86px;
		right: 0;
		display: flex;
		flex-direction: column;
		background: #2a2a2a;
		text-align: left;

			.navbar__mobile-link {
				margin-left: 24px;
				font-family: "Open Sans", sans-serif;
				font-weight: 400;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				color: #a7a7a7;
				background: rgba(#EF9443, 0);
				transition: background 0.2s;
				margin: 0;
				padding: 20px 80px 20px 30px;
				letter-spacing: 1.2px;
				font-size: 18px;

				&.router-link-active {
					color: #fff;
					font-weight: 600;
				}

				&:hover {
					color: #fff;
					background: rgba(#EF9443, 1);
				}

				svg {
					margin-right: 15px;
				}
			}
		}
	}
</style>
