export default {
	title: "Pulled Pork Burger",
	rating: "4",
	tags: ["special-edition"],
	dish_type: "sandwich",
	meat_types: ["pig"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>pulled pork</li>
					<li>Cheddar cheese</li>
					<li>coleslaw</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries (during select hours)</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Redemption arc.",
			content: `
				<p>Lars & Sven read my disparaging review of their <a href="/ranking/lars-sven/smokey-burger" class="reference">Smokey Burger</a> and decided to do something about it. This is the result.</p>
				<p>Please don't fact-check this narrative.</p>
				<p>This creation is also yet another proof L&S knows their stuff when it comes to sandwiches. The ingredients are well thought out. The standout performer - other than of course the pork - is coleslaw. Very good sauce and bits of raw onion in there. Refreshing.</p>
				<p>My only niggle here would be that the pork was just a touch too dry. Or maybe too lean? But it's a minor criticism.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | May 2022"
		},
		{
			type: "image",
			url: require("./Pulled Pork.png")
		}
	]
}
