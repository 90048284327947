import Golf from "./Golf/Golf.js"
import TivoliPohancek from "./Tivoli Pohancek/Tivoli Pohancek.js"
import UtrgancBBQ from "./Utrganc BBQ/Utrganc BBQ.js"
import FitBurger from "./Fit Burger/Fit Burger.js"

export default {
	title: "Mini Cafe Golf",
	tags: [],
	burgers: [
		Golf,
		TivoliPohancek,
		UtrgancBBQ,
		FitBurger

	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://minigolftivoli.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/minigolftivoli/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d7260333-Reviews-Minicafegolf-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: `
		<p>I found this place as 'Food Corner Tivoli' on Wolt. Only months later did I learn this is the same place as 'Mini Cafe Golf' in Tivoli. In fact, 'Food Corner Tivoli' cannot be found anywhere outside Wolt. Why?</p>
		<p>So stupid.</p>
	`
}
