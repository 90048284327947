import PulledPork from "./Pulled Pork/Pulled Pork.js"
import Porchetta from "./Porchetta/Porchetta.js"
import Shrimp from "./Shrimp/Shrimp.js"

export default {
	title: "Trappa",
	tags: [],
	burgers: [
		PulledPork,
		Porchetta,
		Shrimp
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.trappa.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/trappa.pizza/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d16697841-Reviews-Trappa_Pizzeria_Bar-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
