export default {
	title: "Classique Cheese [Mk. I]",
	rating: "2",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>white bread bun</li>
					<li>beef patty</li>
					<li>picadilly sauce</li>
					<li>cheese</li>
					<li>onions</li>
					<li>lettuce</li>
					<li>tomatoes</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Easiest recommendation in my life.",
			content: `
				<p>Don't buy.</p>
				<p>In line with the recommendation, this is also not a difficult review. Don't like the sauce. Not sure what kind of cheese is on this but I know it's the wrong kind. And as icing on the cake, there's a pickle.</p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;">At some point during their existence (not sure when), Tiskarna tweaked their recipes. I now denote their old crop of burgers/sandwiches with "[Mk. I]" in their name. More thoughts and information about the change in the <a href="/ranking/kavarna-tiskarna" class="reference">Comments section</a> on the Joint page.</div>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | May 2020"
		},
		{
			type: "image",
			url: require("./Classique Cheese [Mk. I].jpg")
		}
	]
}
