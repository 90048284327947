import Beef from "./Beef/Beef.js"
import PulledPork from "./Pulled Pork/Pulled Pork.js"

export default {
	title: "Ošterija Pr'Noni",
	tags: [],
	burgers: [
		PulledPork,
		Beef
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "http://www.prnoni.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/osterijaprnoni/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d941718-Reviews-Restavracija_Osterija_Pr_Noni-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
