import Angus from "./Angus/Angus.js"

export default {
	title: "FNX Café & Bistro",
	tags: [],
	burgers: [
		Angus
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "http://www.fnx.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/FNX.si/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d5075228-Reviews-Fnx-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
