export default {
	title: "Burger Bacon",
	rating: "0",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>BBQ sauce</li>
					<li>Chedar cheese</li>
					<li>crispy bacon</li>
					<li>tomato</li>
					<li>lettuce</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "I'm angry.",
			content: `
				<p>Truthfully, I shouldn't be. The joint I ordered this from had one of the lowest ratings on Wolt I've ever seen. But here's the thing: it's so close to my home. Which is why I wanted it to be good. So I grabbed my tank, inhaled a hearty dose of copium, and reasoned that the score on Wolt must be inaccurate - a result of misguided haters, perhaps.</p>
				<p>Incorrect.</p>
				<p>Well, sort of. The score was indeed inaccurate. It was absurdly high. At least based on what I received.</p>
				<p>Theirs is now my least favorite burger I have ever eaten. It's a whole different animal. It genuinely seemed (and smelled) like it was prepared a few days ago, left on the counter, then reheated it minutes before it was brought to me. Given the score, you can safely conclude just about every part of this burger was terrible but I'd like to give a special shout-out to the buns; let's just say I haven't eaten sponge before but I now find it easier to imagine what that would be like.</p>
				<p>I made it <em>four bites</em> in before I had to put it down. Which is the main reason for the lede to this review - the burger was so bad I couldn't eat it and now I'm hungry. And, oh yeah, did I mention I paid out the ass for it?</p>
				<p>Of course, the depths of nutritious villainy we are operating with are not so easily explained. Yet given that this is what I'm supposed to be doing here, allow me to give you two further points of reference.</p>
				<p>As a first point of reference, this handily dethrones my previous Lord of Infamy™, Pošta's <a href="/ranking/posta-slovenije/burger" class="reference">Burger</a>. As I noted in the appropriately derisive lede to that review: "The best part of that burger was the leaf of lettuce." Understand something: this was no idle jest: that lettuce leaf was <em>fresh</em>. Now, sure, the rest of the burger was bad, but at least I had <em>something</em> to work with. And bad as it was, it was still edible (well, I ate it, at least). There was no such silver lining here. The lettuce seemed like it had withered and was later reanimated by the burger's sauce. Delightful.</p>
				<p>Secondly, if you eat as many burgers as I have, at some point you're bound to eat... shall we say, a "compromised" burger. Two or three times so far I have spent a couple of days throwing up because of what was (clearly) poorly prepared patties. Each time it was a horrible experience. I say that because I want you to understand that given choice, I would rather eat hazardous burgers for a year than finish this one. In doing so, my body would not suffer (taste buds not withstanding), but on my soul would be left a permanent scar.</p>
				<p>Oh, also, the images on their Wolt page are attached incorrectly. You know, just for good measure.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | February 2023"
		},
		{
			type: "image",
			url: require("./Burger Bacon.png")
		}
	]
}
