export default {
	title: "Cheeseburger",
	rating: "1",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>Cheddar cheese</li>
					<li>red onion</li>
					<li>lettuce</li>
					<li>tomatoes</li>
					<li>pickles</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "What the fuck did I just eat?",
			content: `
				<p>This is actually going to be a very simple review.</p>
				<p>This burger costs €8. It tastes and looks as if it costs €0,5.</p>
				<p>End of review.
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | May 2021"
		},
		{
			type: "image",
			url: require("./Cheeseburger.jpg")
		}
	]
}
