import Horseburger from "./Horseburger/Horseburger.js"

export default {
	title: "Hot Horse",
	tags: [],
	burgers: [
		Horseburger
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://hot-horse.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/HotHorse/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d1117784-Reviews-Hot_Horse-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
