export default {
	title: "d'Slaninc",
	rating: "4",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>sesame bread bun</li>
					<li>beef patty</li>
					<li>house sauce</li>
					<li>Cheddar cheese</li>
					<li>crispy bacon</li>
					<li>caramelized onions</li>
					<li>tomato</li>
					<li>lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "This isn't even d'Burger's final form.",
			content: `
				<p>Just looking at the specs, at almost any other burger joint this would be a flagship. Not at d'Burger. In d'Burger's hallowed halls, <a href="/ranking/dburger/dojaja" class="reference">d'Ojaja</a>'s shadow looms large.</p>
				<p>In any case, this is actually just d'Ojaja minus BBQ sauce, the egg, and coleslaw. So undoubtedly a downgrade. I would ordinarily say this is a worthy pick - and it is - but realistically, I would only ever recommend it if you're trying to save every cent possible.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		},
		{
			type: "image",
			url: require("./d'Slaninc.png")
		}
	]
}
