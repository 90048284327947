export default {
	title: "Philly Cheese",
	rating: "4.5",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>hoagie bread roll</li>
					<li>beef slices</li>
					<li>Provolone cheese</li>
					<li>caramelized onions</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries (during select hours)</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The best steak sandwich I'm aware of. Also the best item on Lars & Sven's menu. Wait... Déjà vu?",
			content: `
				<p>This seems to be L&S' replacement for their now discontinued <a href="/ranking/lars-sven/roastbeef-steak" class="reference">Roastbeef Steak</a> sandwich. The rebranding was the correct move. There is now a much clearer differentiation between this and their (still available) <a href="/ranking/lars-sven/black-angus-roastbeef-steak" class="reference">Black Angus Roastbeef Steak</a>.</p>
				<p>Let's start this review off by acknowledging the elephant in the room. Yes, L&S' arch nemesis, <a href="/ranking/hood-burger" class="reference">Hood Burger</a>, also makes a steak sandwich. In fact, it is a steak sandwich almost indistinguishable from this one if we were to go by the specs alone: <a href="/ranking/hood-burger/hood-steak" class="reference">Hood Steak</a>. So why is this one better?</p>
				<p>The bread and the meat are almost equally good on both. The seasonings are different but that's about it (other than one notable difference I shall address at the end). It comes down to two components and their preparation: the caramelized onions and the Provolone.</p>
				<p>In my opinion, L&S' onions are just plain better. Not overall - I love Hood's onions as well - just for the purposes of steak sandwiches. They are seasoned wit beer (I believe) which gives them this slightly yeasty tone.</p>
				<p>Mainly, however, it comes down to Provolone. Or rather, its implementation. I cannot remember the last time I ordered Hood Steak and it was actually fucking <span class="emphasized">melted</span>. You know, like it's supposed to be?</p>
				<p>At first I thought they must be using a fundamentally different product. However, I have since learned of an intriguing rumor...</p>
				<p>Legend supposedly tells of a faraway hiding place long forgotten where a mysterious Hood joint opens its doors Monday through Saturday. This enigmatic eatery is said to possess an occult-like knowledge of the long lost art of cheese melting. There, it is said, I may be able to find what I seek.</p>
				<p>Or at least, that was what my friend told me. He was talking about Hood's first joint near his place. I may have paraphrased his words a bit. Anyway, I went there just to see if there was anything to it. There wasn't. The cheese was prepared no differently than at their other locations.</p>
				<p>This being the case, I am left with no choice but to give the sandwich-shaped crown to L&S. Their cheese is melted. Every time.</p>
				<p>One last note. This thing would be five stars if not for L&S's refusal to grill the meat rare (or even mid-rare). And I say 'refusal' because in Hood you <span class="emphasized">can</span> get it rare. So I know it's possible, dammit!</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | January 2022"
		},
		{
			type: "image",
			url: require("./Philly Cheese.jpg")
		}
	]
}
