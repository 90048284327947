export default {
	title: "Metropolitan",
	rating: "2.5",
	tags: ["discontinued", "special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Review",
			lede: "Fails to impress.",
			content: `
				<p>The white BBQ sauce is interesting but it's not enough to save this burger.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | June 2017 (Uncertain)"
		}
	]
}
