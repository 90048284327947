export default {
	title: "Painkiller",
	rating: "3",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>aged beef patty</li>
					<li>bacon</li>
					<li>mustard sauce</li>
					<li>Cheddar cheese</li>
					<li>lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Didn't relieve any pain. Didn't hurt me either. So positive overall, I guess.",
			content: `
				<p>The burger tastes good, but it's ultimately unworthy of a higher score. <span class="emphasized">However</span>, it does come fully equipped with bacon. Therefore, as per the ancient laws, I am required to award it +½ point.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | June 2017"
		},
		{
			type: "image",
			url: require("./Painkiller.jpg")
		}
	]
}
