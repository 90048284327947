export default {
	title: "Burger with beef, brie cheese and aspargus",
	rating: "1",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef</li>
					<li>sauce</li>
					<li>Brie cheese</li>
					<li>truffles</li>
					<li>egg</li>
					<li>pancetta</li>
					<li>aspargus <em>[sic]</em></li>
					<li>baby spinach</li>
					<li>onion</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: '"Aspargus".',
			content: `
				<p>Continuing their rich tradition of seemingly not giving a fuck, this time, Stazione misspelled <em>asparagu</em>. And they didn't misspell it just once - it's spelled wrong in the name as well as in the ingredients list.</p>
				<p>OK, enough stalling.</p>
				<p>This was terrible. Way too many mismatched ingredients. I could barely taste the beef amid so many clashing tastes. The only redeeming quality was the addition of the egg. Otherwise I'd rank it even lower.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | February 2023"
		},
		{
			type: "image",
			url: require("./Burger with beef brie cheese and aspargus.png")
		}
	]
}
