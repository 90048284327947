import LePetitBurger from "./Le Petit Burger/Le Petit Burger.js"

export default {
	title: "Le Petit Cafe",
	tags: [],
	url: "https://www.lepetit.si/",
	burgers: [
		LePetitBurger
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.lepetit.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/lepetitcafeljubljana/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d1088537-Reviews-Le_Petit_cafe-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
