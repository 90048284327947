export default {
	title: "Logist Burger",
	rating: "2",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>Brioche bun</li>
					<li>aged beef patty</li>
					<li>Cheddar cheese</li>
					<li>maple candied bacon bacon</li>
					<li>caramelized onions with Logist beer</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Dryyy.",
			content: `
				<p>From placing the order to receiving the burger, about 45 seconds elapsed. I didn't even need to taste it to know I would hate it.</p>
				<p>I imagine the patty must have sat on the side of the grill for a long time. It was warm enough but any juice it might have contained in its prime had long since vanished.</p>
				<p>Worse yet, the buns were huge. I would guess at least 30% larger than the patty. Why would you do this?</p>
				<p>Lastly - and I never could have imagined myself typing these words - there were too many strips of bacon in that burger.</p>
				<p>Madness.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | May 2023"
		},
		{
			type: "image",
			url: require("./Logist Burger.png")
		}
	]
}
