export default {
	title: "Professor Luigi Burger",
	rating: "2",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>Mozzarella cheese</li>
					<li>Grana Padano cheese</li>
					<li>pancetta</li>
					<li>eggplant</li>
					<li>zucchini</li>
					<li>tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Hey, that's-a pretty disappointing!",
			content: `
				<p>This is one of those burgers where you see the list of ingredients and you instantly know it's either going to somehow pull off a miracle or it's gonna be a disaster. Before we continue: guess what I think?</p>
				<p>The eggplant <span class="emphasized">and</span> zucchini...</p>
				</>Actually, let's pause right there for a second. What is this? Why put <span class="emphasized">both</span> in? Pick one or the other (or, you know, preferably neither) and stop messing around. This isn't fucking <a href="https://en.wikipedia.org/wiki/Ratatouille" class="reference">ratatouille</a>. No, I don't mean the movie, you lazy link skippers!</p>
				<p>Anyway, I may be fine with <span class="emphasized">one</span> of these ("may" is doing a lot of the heavy lifting) if they grilled it and properly infused it with flavour (read: salt and pepper). The sad reality is that they end up tasting so bland that it dampens the taste of the rest of the burger. Not entirely dissimilar to Gostilnica in Pivnica Šiška's <a href="/ranking/gostilnica-in-pivnica-siska/gurman" class="reference">Gurman</a>. Except that one's better. Mostly because - and I can't (seem to) stress this enough - it <span class="emphasized">only</span> has zucchini and <span class="emphasized">not also</span> an eggplant.
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		},
		{
			type: "image",
			url: require("./Professor Luigi.jpg")
		}
	]
}
