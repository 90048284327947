export default {
	title: "Le Brie",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>grass-fed beef patty</li>
					<li>mayo with button mushrooms</li>
					<li>French Brie cheese</li>
					<li>tomato</li>
					<li>rucola</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The first burger I ever ate.",
			content: `
				<p>This will probably be shocking to many of you, I know. I started late in life.</p>
				<p>Then again, I consider that a positive more than anything. Before Hood Burger came on the scene, you'd be hard pressed to find a burger in Ljubljana outside a few sub-par fast food joints and McDonald's. Had I started there I'm almost positive this site would never have come into existence. If my idea of an average burger came to be defined by McDonald's I imagine I would always believe burgers to be mediocre food that probably can't be improved much. In other words, I would have been wrong. Which is not acceptable.</p>
				<p>Le Brie is not only a great burger on its own but it's also a clever strategic inclusion by Hood. There are three burgers on their regular menu: <a href="/ranking/hood-burger/classic-burger" class="reference">Classic Burger</a>, <a href="/ranking/hood-burger/jakenjay" class="reference">Jake'n'Jay</a>, and Le Brie. The first two are fantastic as well but they start blending together after a while. The only meaningful differentiator between them is the sauce; they are very similar otherwise. Not so with Le Brie. It has a completely different sauce and radically different cheese. It adds a lot of diversity of flavour to the menu that would otherwise not be there.</p>
				<p>For a long time this used to be my favorite burger. Now that I'm a seasoned veteran who has seen the world, I realize there are burgers out there that far surpass this one. Even so, I find myself inevitably coming back for more.</p>
				<p>Alright, enough reminiscing.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | January 2023"
		},
		{
			type: "image",
			url: require("./Le Brie.jpg")
		}
	]
}
