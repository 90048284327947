export default {
	title: "Beef Brisket",
	rating: "2.5",
	tags: ["discontinued"],
	dish_type: "sandwich",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>burger butter bun</li>
					<li>12h smoked beef</li>
					<li>house BBQ sauce</li>
					<li>cream cheese</li>
					<li>caramelized onions</li>
					<li>iceberg lettuce</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "BBQ sauce: the great equalizer. Towards mediocrity.",
			content: `
				<p>While not exactly a burger, this is pretty good. Especially the meat.</p>
				<p>Having said that, this sandwich unfortunately suffers from huge balance issues. While the individual ingredients are good, they are all slathered in a far too generous serving of BBQ sauce. I unfortunately cannot recommend it. But, you know, if you aren't bothered by this, go for it.
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | July 2021"
		},
		{
			type: "image",
			url: require("./Beef Brisket.jpg")
		}
	]
}
