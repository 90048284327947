import Speedy from "./Speedy/Speedy.js"

export default {
	title: "Burger Time",
	tags: [],
	burgers: [
		Speedy
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.burgertime.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/burgertimeslovenija/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d16853681-Reviews-Burger_Time_Ljubljana-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
