export default {
	title: "Cheeseburger",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>house sauce</li>
					<li>cheese</li>
					<li>lamb's lettuce</li>
					<li>tomatoes</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "All style, no substance.",
			content: `
				<p>For a fast food joint not primarily about burgers, they sure managed to produce some of the best looking ones.</p>
				<p>That's as far as my praise goes.</p>
				<p>I could feel my enthusiasm rapidly decay with each bite. The beef is mediocre, the sauce is hella boring, and the cheese somehow adds next to nothing to the experience.</p>
				<p>Buy it to admire its aesthetics. Then donate it to a passerby.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | March 2023"
		},
		{
			type: "image",
			url: require("./Cheeseburger.png")
		}
	]
}
