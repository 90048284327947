import Dule from "./Dule/Dule.js"

export default {
	title: "Halo Pinki",
	tags: [],
	burgers: [
		Dule
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "http://www.halopinki.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/dostava.halopinki/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d6459339-Reviews-HALO_PINKI_d_o_o-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
