export default {
	title: "Chicken Burger",
	rating: "2.5",
	tags: ["discontinued"],
	dish_type: "sandwich",
	meat_types: ["chicken"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>fried chicken slices</li>
					<li>house BBQ sauce</li>
					<li>lettuce</li>
					<li>tomato</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Uninspired.",
			content: `
				<p>The peculiar taste of the bun on this bur-... I mean <span class="emphasized">sandwich</span> (almost got me there) coupled with BBQ sauce results in a taste that doesn't sit well with me. Besides, they're clearly just recycling the most of the ingredients from their <a href="/ranking/soba-102/black-angus-premium-burger" class="reference">Black Angus Premium Burger</a>. It's a lazy approach, culminating in exactly what one would expect: mediocrity.</p>
				<p>The chicken is not dry, at least. That alleviates my grievance a bit.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018"
		}
	]
}
