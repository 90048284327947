import Holesterol from "./Holesterol/Holesterol.js"
import MacnCheese from "./Mac 'n' Cheese/Mac 'n' Cheese.js"
import Novozelandec from "./Novozelandec/Novozelandec.js"
import Pierre from "./Pierre/Pierre.js"
import Umami from "./Umami/Umami.js"

export default {
	title: "Stari Pisker",
	tags: [],
	burgers: [
		Holesterol,
		Novozelandec,
		Pierre,
		MacnCheese,
		Umami
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.stari-pisker.com/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/gostilna.stari.pisker/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274868-d3693822-Reviews-Gostilna_in_Pivnica_Stari_Pisker-Celje_Styria_Region.html"
		}
	],
	review: ""
}
