export default {
	title: "Angus",
	rating: "2.5",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>sauce</li>
					<li>Cheddar cheese</li>
					<li>crunchy bacon</li>
					<li>lettuce</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Nothing to write home about.",
			content: `
				<p>Also, the ingredients state this burger contains - and I quote - "<span class="emphasized">crunchy</span> bacon". Lies!</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | April 2016"
		}
	]
}
