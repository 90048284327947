export default {
	title: "Bacon Burger",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>Fast 5 sauce</li>
					<li>Cheddar cheese</li>
					<li>pancetta</li>
					<li>iceberg lettuce</li>
					<li>tomatoes</li>
					<li>onions</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Not bad for a random find.",
			content: `
				<p>Found the joint on Wolt by chance.</p>
				<p>Pretty great burger. Well seasoned, well considered ingredients, good meat. Not a lot to hate.</p>
				<p>Speaking of, if I had to levy some criticisms: the bacon lacks the crisp factor and the buns are a bit generic. Relatively minor complaints, all things considered.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | March 2023"
		},
		{
			type: "image",
			url: require("./Bacon Burger.png")
		}
	]
}
