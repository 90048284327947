export default {
	title: "Balbo [2022]",
	rating: "4",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>grass-fed beef patty</li>
					<li>aioli with capers, garlic, lemon juice</li>
					<li>Gruyère cheese</li>
					<li>grilled marinated button mushrooms</li>
					<li>caramelized onions</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "An inexplicably silent yet fantastic revision.",
			content: `
				<p>Continuing their rich tradition of terrible, inconsistent naming schemes and silent revisions, Hood introduced the new Balbo. I've had just about enough of their onomatological bullshit so I will break my long-standing tradition of using the restaurants' actual names of dishes in the titles and name this one "<span class="emphasized">Balbo (2020)</span>". Because if there's one thing I hate more than inconsistency it's the confusion it creates and I will not be a party to it.</p>
				<p>Anyway, to the review.</p>
				<p>Actually no, fuck that, I've got more to say. I don't know why you wouldn't name this burger something else so that it's immediately apparent it's not the same as the original Balbo (namely, Raclette cheese has been retired in favour of Gruyere and a new sauce was added). There is precedence - <a href="/ranking/hood-burger/the-duke" class="reference">The Duke</a> was renamed several times when the ingredients changed (don't even get me started on the details though). So why, Hood. WHY?!</p>
				<p>And yes, I know Gruyere is mentioned in their newsletter. But I would bet good money you can't actually demonstrate anyone other than me reads those through and through. Hence me referring to it as a <span class="emphasized">silent	</span> revision.</p>
				<p>Anyway, to the review. For real this time.</p>
				<p>This burger is now significantly better than the old one.</p>
				<p>Yes, that's it. I'm too tired now to go into the weeds.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | January 2022"
		},
		{
			type: "image",
			url: require("./Balbo (2022).jpg")
		}
	]
}
