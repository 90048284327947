export default {
	title: "Beef Burger",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>house sauce</li>
					<li>cheese</li>
					<li>lamb's lettuce</li>
					<li>tomatoes</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Unnecessary.",
			content: `
				<p>It's just their Cheeseburger minus the cheese. But tastes almost exactly the same.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | March 2023"
		},
		{
			type: "image",
			url: require("./Beef Burger.png")
		}
	]
}
