import JacktheTartuf from "./Jack the Tartuf/Jack the Tartuf.js"
import Angus from "./Angus/Angus.js"

export default {
	title: "Jack & Joe Steak and Burger Club",
	tags: [],
	burgers: [
		Angus,
		JacktheTartuf
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "http://jackandjoe.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/jackandjoemb/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274874-d9976608-Reviews-Jack_Joe_Steak_and_Burger_Club-Maribor_Styria_Region.html"
		}
	],
	review: ""
}
