import Cheeseburger from "./Cheeseburger/Cheeseburger.js"

export default {
	title: "Vija Vaja",
	tags: [],
	burgers: [
		Cheeseburger
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://restaurantguru.com/VIJA-VAJA-pizza-Burek-Kebab-Ljubljana"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: ""
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
