export default {
	title: "d'Šef",
	rating: "3.5",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>sesame bread bun</li>
					<li>beef patty</li>
					<li>house sauce</li>
					<li>Cheddar cheese</li>
					<li>crispy bacon</li>
					<li>coleslaw</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Cutbacks, continued.",
			content: `
				<p>This is just <a href="/ranking/dburger/dojaja" class="reference">d'Ojaja</a> with missing ingredients. It's good, absolutely, but it's not sufficiently distinct from the other burgers on the menu - a problem I spoke about before in the comments <a href="/ranking/dburger" class="reference">here</a>.</p>
				<p>Then again, there are probably people out there who would scoff at the idea of BBQ sauce, caramelized onions and eggs being added to their burger. In other words, my arch enemies. And if they want to waste money on an inferior version of a burger, I say we let them.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		},
		{
			type: "image",
			url: require("./d'Sef.png")
		}
	]
}
