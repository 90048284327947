export default {
	title: "Hood Kahuna Burger",
	rating: "1.5",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>grass-fed beef patty</li>
					<li>mayo with lime juice and hot sauce</li>
					<li>Mozzarella cheese</li>
					<li>pancetta</li>
					<li>caramelized jalapeños</li>
					<li>grilled pineapple</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "I suppose it was only a matter of time.",
			content: `
				<p>All sane individuals realize this fundamental universal axiom: <span class="emphasized">pineapple does not belong on pizza</span>. Yet there live among us those who cannot come to terms with this reality. And at least one such individual is clearly situated in the upper echelons of Hood's upper management.</p>
				<p>As soon as I read the words "pineapple" and "jalapeno", two things were immediately clear to me. First, the purchase decision was out of my hands at that point. I simply had to know. Second, I knew I was going to regret it. Indeed, both came to pass.</p>
				<p>I'm sure there's people out there who would love this bastard. I, however, am not among them.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | July 2018"
		},
		{
			type: "image",
			url: require("./Kahuna.jpg")
		}
	]
}
