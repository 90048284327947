export default {
	title: "Pierre",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>	
					<li>beef patty</li>
					<li>house sauce</li>
					<li>Brie cheese</li>
					<li>black pepper Cheddar cheese</li>
					<li>truffle paste</li>
					<li>rucola</li>
					<li>tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "I like the cheese mix. But I guess I was expecting more.",
			content: `
				<p>I don't really have anything else to say...</p>
				<p>Oh! If you want a better version of this, try Hood Burger's <a href="/ranking/hood-burger/le-brie" class="reference">Le Brie</a> instead.</p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;"><strong>Warning:</strong> if you are considering Stari Pisker's offerings at <a href="https://www.pivoinburgerfest.si/" target="_blank" class="callback">Pivo & Burger Fest</a>, do yourself a favor and don't. While their burgers were some of the best in class, this unfortunately no longer applies, at least not to Burgerfests. They have decided on a brilliant strategic move of prioritizing profits at the expense of their reputation and now produce only well done and super dry meat patties. Therefore, I can no longer recommend them at any venue outside their restaurant. Disregard the existing review/score in any other context.</div>

			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | September 2019 (Uncertain)"
		},
		{
			type: "image",
			url: require("./Pierre.jpg")
		}
	]
}
