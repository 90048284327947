import CizKlasik from "./Ciz Klasik/Ciz Klasik.js"
import DoubleCheeseburger from "./Double Cheeseburger/Double Cheeseburger.js"
import Tartuf from "./Tartuf/Tartuf.js"

export default {
	title: "TINK Superfood Café",
	tags: [],
	burgers: [
		CizKlasik,
		DoubleCheeseburger,
		Tartuf
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://wolt.com/en/slovenia/ljubljana/restaurant/tink-4-food"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/tinksuperfood/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d15610617-Reviews-TINK_superfood_cafe-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: `
		<p>This establishment has the same owner as <a href="/ranking/omami-burgers/" class="reference">OMAMI Burgers</a>.</p>
	`
}
