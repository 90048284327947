export default {
	title: "The Duke 2.0",
	rating: "4.5",
	tags: ["special-edition"],
	dish_type: "sandwich",
	meat_types: ["duck"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>slow roasted pulled duck</li>
					<li>mustard with seeds</li>
					<li>cranberry marmalade</li>
					<li>black truffle with honey</li>
					<li>Asiago DOP cheese</li>
					<li>rucola</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Hands down my favorite beefless product Hood has ever made.",
			content: `
				<p>A further improvement upon <a href="/ranking/hood-burger/the-duke-2" class="reference">The Duke 2</a>. They changed the cheese and added rucola. Both extremely welcome changes, especially the latter. The Duke 2 was excellent but needed something... lighter. Rucola fixes this, the only remaining issue.</p>
				<p>This is now also officially in the running for the best product Hood has ever made, period. I would still hesitate to go that far but the fact that I'm even contemplating such blasphemous thoughts should tell you just how good this is.</p>
				<p>I'm subtracting some points for the stupidly confusing naming scheme, however. I shall hold them hostage until this clusterfuck is resolved.</p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;">This is the 3rd version of The Duke. Annoyingly, Hood has opted for a stupidly confusing naming scheme for this dish. See my editorial <a href="/editorial/a-royal-mess" class="reference">'A Royal Mess'</a> and <a href="/editorial/a-royal-mess-2" class="reference">'A Royal Mess 2: The Reckoning'</a> for more information on this topic.</div>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | November 2019"
		},
		{
			type: "image",
			url: require("./The Duke 2.0 2019.jpg")
		}
	]
}
