export default {
	title: "d'Pohanc",
	rating: "2.5",
	tags: ["discontinued"],
	dish_type: "sandwich",
	meat_types: ["chicken"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<<li>sesame bread bun</li>
					<li>fried chicken</li>
					<li>house white sauce</li>
					<li>Cheddar cheese</li>
					<li>tomatoes</li>
					<li>lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Nothing else matters if you fuck up the main ingredient.",
			content: `
				<p>Man, I really wanted this to be good. I've been missing a good chicken sandwich ever since Americano closed. And this has all the groundwork - the sauce is on point, ingredients that make sense, a somewhat reasonable price. But the execution falls short. The chicken was fried for far too long and was super dry.</p>
				<p>Will be going back at some point to see if anything changed. For now, the score remains based on the one sandwich I ate. <span class="emphasized" style="font-size: 80%;">(Hi, yours truly from the future here; this sandwich was discontinued so fast I never got the chance at the encore Too bad. It really seemed like it had potential if they just got the chicken right.)</span></p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		}
	]
}
