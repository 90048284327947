export default {
	title: "Dry Aged Holy Cow Burger",
	rating: "5",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>dry aged Irish Angus beef patty</li>
					<li>house mayo with wasabi</li>
					<li>red caramelized onions</li>
					<li>lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "If you told me I would have to take a human life for every bite out of this burger, I would conduct human sacrifices on a scale that would put the ancient Aztecs to shame.",
			content: `
				<p>The tender, juicy patty, some of the best caramelized onions on the market, the mustard-based sauce... Fuuuuuuck.</p>
				<p>This burger is so magnificent I'm not even going to complain about how you may need to sell some of your family members into slavery in order to afford it.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2017"
		},
		{
			type: "image",
			url: require("./Dry Aged Holy Cow.jpg")
		}
	]
}
