export default {
	title: "Francoski",
	rating: "4",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>beef patty</li>
					<li>creme fraiche</li>
					<li>Gruyère cheese</li>
					<li>caramelized onions with Cognac</li>
					<li>iceberg lettuce</li>
					<li>tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A sensible recipe and good ingredients. Also the bun looks cool.",
			content: `
				<p>Well, I suppose creme fraiche does look a bit out of place there. But hey, it works.</p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;"><strong>Warning:</strong> if you are considering Punkt's offerings at <a href="https://www.pivoinburgerfest.si/" target="_blank" class="callback">Pivo & Burger Fest</a>, do yourself a favor and don't. While their burgers were some of the best in class, this unfortunately no longer applies, at least not to Burgerfests. They have decided on a brilliant strategic move of prioritizing profits at the expense of their reputation and now produce only well done and super dry meat patties. Therefore, I can no longer recommend them at any venue outside their restaurant. Disregard the existing review/score in any other context.</div>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | April 2019 (Uncertain)"
		},
		{
			type: "image",
			url: require("./Francoski.jpg")
		}
	]
}
