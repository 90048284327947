export default {
	title: "Crispy Chicken Burger",
	rating: "3.5",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["chicken"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>ground breaded chicken fillet</li>
					<li>herbal Ranch sauce</li>
					<li>lettuce</li>
					<li>tomato</li>
					<li>pickles</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries (during select hours)</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "It doesn't get any simpler than this. Nor does it have to.",
			content: `
				<p>This may be the simplest <span class="emphasized">non-</span>burger (you fuckers, didn't think I'd notice, did you?) on this list. Even the most absurdly incompetent people could make this at home.</p>
				<p>Then again, is that a bad thing? Not everything needs to be super fancy or sophisticated to be good. Plus, they're charging an acceptable amount for it. So it's a win in my book.</p>
				<p>Well, other than the fucking pickle.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | January 2022"
		},
		{
			type: "image",
			url: require("./Crispy Chicken.jpg")
		}
	]
}
