export default {
	title: "Stejkov Sendvič",
	rating: "4",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread roll</li>
					<li>ribeye steak slices</li>
					<li>Cheddar cheese sauce</li>
					<li>meat sauce</li>
					<li>sauteed onions</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Cheesy decadence.",
			content: `
				<p>Damn.</p>
				<p>This was one of the better beef sandwiches I've had the pleasure of tasting. It’s like everything inside the hoagie was drowned in butter and cheez whiz - and I mean that in the best possible sense. The meat was grilled perfectly and al the fat was properly rendered. The cheddar sauce was fantastic.</p>
				<p>Sadly, it fell short of perfection. First, the bread rolls were too aggressively sweetened which distracted from the experience. Second, there was almost no heterogeneity. Other than the bread roll, all the flavours were just kind of... smooshed together. Every bite tasted the same. Were it not for the texture, I would barely be able to tell where the meat ended and the cheese began.</p>
				<p>But all of these are more or less nitpicks. Overall, the positives outweigh the negatives. If you find yourself able to order this, it's well worth it.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Odprta Kuhna | July 2022"
		},
		{
			type: "image",
			url: require("./Stejkov Sendvic.png")
		}
	]
}
