export default {
	title: "Eksotik Burger",
	rating: "4.5",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>beef patty</li>
					<li>sauce with horseradish, cream, and orange</li>
					<li>orange slices</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "How is this even possible?",
			content: `
				<p>This is undoubtedly the biggest flex I have ever encountered in this space.</p>
				<p>Think about it. There are joints out there that put a beef patty, Cheddar, sauce, and maybe lettuce and tomato in their burgers and against all odds manage to make them completely suck. Then causally, along comes Meat Business and unironically puts <span class="emphasized">a fucking orange</span> in their burger and proceeds to embarrass absolutely everyone, even most of the top players.</p>
				<p>There are no secrets to success here. If you crack the code on the blend, everything else falls in place. To be clear, other ingredients are far from average. Yet it's the meat which elevates the dish.</p>
				<p>Still, if you find sweet fruits in your burgers a bit too adventurous, no worries - just go for their <a href="/ranking/meat-business/klasik-burger" class="reference">Klasik Burger.</a>.</p>
				<p>Bravo.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Odprta Kuhna | April 2022"
		}
	]
}
