import BaconCheese from "./Bacon Cheese/Bacon Cheese.js"
import ChickenSandwich from "./Chicken Sandwich/Chicken Sandwich.js"
import DirtyMac from "./Dirty Mac/Dirty Mac.js"
import OriginalCaliforniaClassic from "./Original California Classic/Original California Classic.js"
import PopsOriginal from "./Pop's Original/Pop's Original.js"
import Silverton from "./Silverton/Silverton.js"
import SliderDuo from "./Slider Duo/Slider Duo.js"
import TheBoss from "./The Boss/The Boss.js"
import TheGotham from "./The Gotham/The Gotham.js"

export default {
	title: "Pop's Place",
	tags: [],
	burgers: [
		TheBoss,
		SliderDuo,
		ChickenSandwich,
		BaconCheese,
		PopsOriginal,
		OriginalCaliforniaClassic,
		DirtyMac,
		Silverton,
		TheGotham
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://burger.thepopsplace.com/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/PopsPlaceBurgerBar/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d8403821-Reviews-Pop_s_Place-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
