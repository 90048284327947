export default {
	title: "Pulled Lamb",
	rating: "2",
	tags: ["special-edition"],
	dish_type: "sandwich",
	meat_types: ["sheep"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>slow roast lamb</li>
					<li>mint yogurt</li>
					<li>caramelized carrots</li>
					<li>roasted sesame</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Stick to the roast piglet, fellas.",
			content: `
				<p>Aight. I'm gonna make this short.</p>
				<p>I liked the meat. I disliked what surrounded it.</p>
				<p>Back to the drawing board, please.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | 2019"
		},
		{
			type: "image",
			url: require("./Pulled Lamb.jpg")
		}
	]
}
