export default {
	title: "Beef burger with pancetta and cheddar cheese",
	rating: "3",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef</li>
					<li>sauce</li>
					<li>Cheddar cheese</li>
					<li>pancetta</li>
					<li>onion</li>
					<li>tomato</li>
					<li>green lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Basics done (mostly) right.",
			content: `
				<p>To be honest, this burger is not exciting at all. Bog-standard components, no interesting seasoning, no innovative take. Well, except I guess the somewhat unique bun which I didn't care for.</p>
				<p>But it doesn't matter. It's a decent burger. No deal-breaking flaws. Wouldn't necessarily advise against it.</p>
				<p>High praise, I know.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | February 2023"
		},
		{
			type: "image",
			url: require("./Beef burger with pancetta and cheddar cheese.png")
		}
	]
}
