export default {
	title: "Black Angus Bacon Burger",
	rating: "4",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>Black Angus beef patty</li>
					<li>house sauce</li>
					<li>Cheddar cheese</li>
					<li>crispy bacon</li>
					<li>onions marinated in beer</li>
					<li>lettuce</li>
					<li>tomato</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A worthy flagship.",
			content: `
				<p>Featuring bacon <span class="emphasized">and</span> caramelized onions. That, my friends, is not common. Even among flagships. Major props.</p>
				<p>The bun has a somewhat specific taste that may or may not be to one's liking. I imagine they're using some non-standard flour (corn?). I didn't care for them much but the spec sheet mostly overshadows this minor flaw.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018"
		},
		{
			type: "image",
			url: require("./Black Angus Bacon.jpg")
		}
	]
}
