export default {
	title: "Tartuf Burger",
	rating: "1",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>sesame burger bun</li>
					<li>dry aged beef patty</li>
					<li>mayonnaise</li>
					<li>Brie cheese</li>
					<li>rucola</li>
					<li>truffles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Don't waste your money.",
			content: `
				<p>The meat was extraordinarily bland and chewy. Having been grilled well done makes the mediocrity even more apparent. Because the mayo and cheese are so bland basically all that remains is the extremely strong (and extremely fake) truffle aftertaste.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | January 2022"
		},
		{
			type: "image",
			url: require("./Tartuf.png")
		}
	]
}
