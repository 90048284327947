import PulledLamb from "./Pulled Lamb/Pulled Lamb.js"
import Hrustljavckov from "./Hrustljavckov/Hrustljavckov.js"

export default {
	title: "Super Hrust",
	tags: [],
	burgers: [
		Hrustljavckov,
		PulledLamb
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "http://superhrust.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/superhrust/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
