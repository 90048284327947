import Premium from "./Premium/Premium.js"

export default {
	title: "Ajda Premium Burger",
	tags: [],
	burgers: [
		Premium
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://ajda-skupina.si/ponudba/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/ajda.burgers.and.more/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d11045533-Reviews-Ajda-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
