export default {
	title: "Rookie Burger",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>house sauce</li>
					<li>Cheddar cheese</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries (during select hours)</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Simplicity is the hallmark of... something or other.",
			content: `
				<p>This is the purest distillation of that special Lars & Sven essence (forgive the pretentious language). It's one of the simplest burgers - well, sliders - out there, but it's surprisingly good and pretty much foreshadows the rest of the lineup.</p>
				<p>But therein lies the rub. It's still fundamentally a Lars & Sven burger. Which means it comes with their sauce. And much to the dismay of many of my readers, I'm still not a big fan.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | 2020"
		},
		{
			type: "image",
			url: require("./Rookie.jpg")
		}
	]
}
