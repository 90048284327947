import Cheese from "./Cheese/Cheese.js"

export default {
	title: "Halo Podnevi & Ponoči",
	tags: [],
	burgers: [
		Cheese
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://halopodneviinponoci.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/HALO-PodneviPono%C4%8Di-107816804452187/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
