import Krusic from "./Krusic/Krusic.js"

export default {
	title: "Kuhinja Krušič",
	tags: [],
	burgers: [
		Krusic
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "http://www.mesarstvo-krusic.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/kuhinja.krusic/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d1016543-Reviews-KUHINJA_KRUSIC-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
