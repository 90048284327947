export default {
	title: "Balbo",
	rating: "3",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>grass-fed beef patty</li>
					<li>Raclette cheese</li>
					<li>grilled marinated button mushrooms</li>
					<li>grilled onions</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The most delicious copyright infringement available.",
			content: `
				<p>Fun fact: this burger was formerly known as <span class="emphasized">Rocky Balboa</span>. <a href="https://www.hoodburger.si/dogaja-1/2020/balbo" class="reference">According to Hood</a>, MGM apparently sent them a cease and desist (or something to that effect) so they had to rename it. Wild.</p>
				<p>I was quite skeptical about these specs. Button mushrooms? How the hell are button mushrooms supposed to pair with a beef patty?</p>
				<p>Surprisingly well, it turns out.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | February 2019"
		},
		{
			type: "image",
			url: require("./Balbo.jpg")
		}
	]
}
