export default {
	title: "Smokey Burger",
	rating: "2",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef from matured ribs with smoked bacon</li>
					<li>horseradish sauce, mayonnaise and ketchup</li>
					<li>Cheddar cheese</li>
					<li>fried bacon</li>
					<li>caramelized onions</li>
					<li>lettuce</li>
					<li>tomatoes</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Behind the times.",
			content: `
				<p>The taste at the edge of acceptable I guess. Comes with fries. Nacho was perhaps not the best choice of cheese in this context. But more than anything, the beef blend is simply not good enough. It's the kind of patty I would have expected prior to <a href="/ranking/hood-burger" class="reference">Hood Burger</a> coming on the scene and waking us all up to what burgers could be. Today, however, there is nothing about this burger that stands out from the competition.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | May 2021"
		},
		{
			type: "image",
			url: require("./Smokey.jpg")
		}
	]
}
