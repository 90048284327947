export default {
	title: "Señor Burger",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>butter bun</li>
					<li>Black Angus beef batty</li>
					<li>house sauce</li>
					<li>double Cheddar cheese with pepper</li>
					<li>rucola</li>
					<li>caramelized red onion with bacon</li>
					<li>spicy Pata Negra lard</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "For the starved.",
			content: `
				<p>This burger is enormous. It looks slightly above average in size from afar but once you get it in your hands you realize what an undertaking it is going to be. And by the way, to those who know me: if I'm saying this, you know this bad boy is a chonker. A <a href="https://www.youtube.com/watch?v=cB4dYfFgaME" target="_blank" class="callback">Big</a> <a href="https://www.youtube.com/watch?v=6YDoowoYzOI" target="_blank" class="callback">Chungus</a>, for all my contemporary memers out there.</p>
				<p>I mention this for those people like me who will (or already did) encounter Bazen at <a href="https://www.pivoinburgerfest.si/" class="reference">Pivo & Burger Fest</a> so they can decide whether or not to go for it. If you just want one burger to quench your hunger as efficiently as possible, this is for you. If, however, you go to Burgerfest to sample a variety of sandwiches, maybe skip this one or find a person to share it with. Otherwise you might be done after one burger.</p>
				<p>Size aside, this burger is good. The sauce is decent (and plentiful), the balance is OK, the meat blend is great. It is an artery clogger, though - double Cheddar, lard, thicc and creamy sauce, caramelized onions... Sounds great, yes, but you know - understand what you're in for.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | September 2021"
		},
		{
			type: "image",
			url: require("./Senor.png")
		}
	]
}
