export default {
	title: "Fit Burger",
	rating: "3",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["chicken"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>vegan bun</li>
					<li>grilled chicken fillet</li>
					<li>tzaziki sauce</li>
					<li>lettuce</li>
					<li>tomatoes</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Perfection marred by the surplus of The Scourge.",
			content: `
				<p>I am talking, of course, about the presence of my old adversary, the agent of chaos, the ravager of sandwiches: <span class="emphasized">the pickle</span>.</p>
				<p>The reason for the slightly elevated dramatic flare this time is that this sandwich contains not just your ordinary amount of pickles. Oh no. You see, this sandwich also utilizes tzatziki sauce. Which means there are pickles in the sauce <span class="emphasized">in addition</span> to the pickles already in the sandwich.</p>
				<p>I actually don't mind the sauce itself too much. It's pretty good, actually. But the traces of pickles are decidedly enough. I don't need extra!</p>
				Other than that, pretty good.</p>
				<p>To finish up, I have a question: why would you use a vegan bun for a chicken sandwich?</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | May 2022"
		},
		{
			type: "image",
			url: require("./Fit Burger.png")
		}
	]
}
