export default {
	title: "Classic Burger",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>house sauce</li>
					<li>cheese</li>
					<li>red onions</li>
					<li>rucola</li>
					<li>tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Less is more... somehow?",
			content: `
				<p>This may be the first time I place a joint's "classic" burger on the same level as their flagship counterpart, <a href="/ranking/buldog-bar/bulldog-burger" class="reference">Bulldog Burger</a>. As you can see, this one lacks bacon and an egg. However, the bacon isn't the crispy kind and I'm agnostic about this particular egg (by which I mean it neither significantly diminishes nor enhances the overall taste). So I guess you could save a bit of money and just order this one instead of its more full-featured incarnation. I dunno. To each their own.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		},
		{
			type: "image",
			url: require("./Classic.jpg")
		}
	]
}
