export default {
	title: "Speedy Burger",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>sesame bread bun</li>
					<li>aged beef patty</li>
					<li>BBQ sauce</li>
					<li>bacon</li>
					<li>fried egg</li>
					<li>caramelized onions</li>
					<li>lettuce</li>
					<li>paprika</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A normal burger with a normal price tag.",
			content: `
				<p>But there's a very important caveat. Not five minutes away on foot you can find at least three better joints. Namely, <a href="/ranking/pops-place" class="reference">Pop's Place</a>, <a href="/ranking/hood-burger" class="reference">Hood Burger</a>, and <a href="/ranking/lars-sven" class="reference">Lars & Sven</a>. Furthermore, Burger Time doesn't seem to grasp the concept of "medium rare".</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		},
		{
			type: "image",
			url: require("./Speedy.jpg")
		}
	]
}
