export default {
	title: "Le Petit Burger",
	rating: "2",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>seasoned beef patty</li>
					<li>cheese</li>
					<li>crispy bacon</li>
					<li>tomato</li>
					<li>lettuce</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Were they going for the Veblen effect or something?",
			content: `
				<p>My friends... I am very annoyed right now.</p>
				<p>For the 99% of you who didn't read the <a href="/philosophy" class="reference">Philosophy</a> section on this site: I make it a point to consider the price in my score as little as possible. I think food is a very personal thing so I tend to let you, the reader, decide if something was worth the money or not. I'm primarily concerned about taste.</p>
				<p>However...</p>
				<p>In this case I am going to have to make an exception. The reason being this is the second (or third, I don't remember) most expensive burger I have ever paid for. And the reason I am so annoyed is that this is one of the most middle-of-the-road - or even worse - burgers I have ever eaten. It is absolutely not worth the money. Buy a house instead. You're basically halfway there if you skip this burger.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		},
		{
			type: "image",
			url: require("./Le Petit Burger.jpg")
		}
	]
}
