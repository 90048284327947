export default {
	title: "Vič King Burger",
	rating: "3.5",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>butter bun</li>
					<li>Black Angus beef patty</li>
					<li>house BBQ sauce</li>
					<li>cream cheese</li>
					<li>fried onion rings</li>
					<li>seared bacon</li>
					<li>lettuce</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "I can't believe I'm giving a burger whose name is a double fucking pun a good review.",
			content: `
				<p>Having principles hurts. Don't do it.</p>
				<p>This was one of the rare occasions where after I finished this burger I went back to the list and retroactively lowered the score of another. That's because when ordering burgers for delivery I had simply assumed that the crispiness of the onion rings was one of the unfortunate areas of compromise inherent to food degradation during transport. Vič King shattered these assumptions. To which I can only say: bravo.</p>
				<p>There is a lot to like about this burger. The portion is very generous. The beef patty is thicc. The barbecue sauce is delicious (albeit a bit overpowering). The only major potential issue here is auxiliary: the fries. The ones I received were not only fried in what I can only assume was frying oil past its prime, they also didn't play well with the burger. Personally, it is my belief that it is not a necessity for the fries to be included with every burger. But if you include them, at least don't fuck them up. It ruins the whole experience.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | June 2021"
		},
		{
			type: "image",
			url: require("./Vic King.jpg")
		}
	]
}
