import Beerger from "./Beerger/Beerger.js"
import CosaNostra from "./Cosa Nostra/Cosa Nostra.js"
import Mangoop from "./Mangoop/Mangoop.js"

export default {
	title: "Mangoop Burgers",
	tags: [],
	burgers: [
		Mangoop,
		Beerger,
		CosaNostra
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: ""
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g815567-d21198947-Reviews-Mangoop_Burgers-Kamnik_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
