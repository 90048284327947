export default {
	title: "Kobe Beef Sliders",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>dry-aged beef patty</li>
					<li>truffle aioli</li>
					<li>quail eggs</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries & ketchup</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Quail eggs. Truffle Aioli. Kobe beef (allegedly). What's not to like?",
			content: `
				<br />Well, I'll tell you (kind of why I'm here): the portions are so small you'll want another burger. But you won't be able to get one, as you won't have any money left. Heck, I'm still repaying the debt I incurred ordering these.
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018"
		}
	]
}
