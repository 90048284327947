export default {
	title: "Brgr",
	rating: "4",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>beef patty</li>
					<li>ketchup</li>
					<li>mustard</li>
					<li>onion</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The prettiest burger.",
			content: `
				<p>By the fall of 2021 I was beginning to get worried. Out of dozens of new burgers I've tried that year there were only two that I actually thoroughly enjoyed (and one of those was from my friend <a href="/ranking/filips-backyard/burger" class="reference">Filip</a> so it didn't exactly count). To my everlasting delight, Brgr finally broke that streak in November.</p>
				</p>Guys. We <span class="emphasized">finally</span> have some fucking innovation! Pr' Pandi describe their work as "Classic burgers with an Asian touch". And oh boy do they deliver.</p>
				<p>To start off - and as mentioned above - this is the prettiest burger that I have ever eaten. Bar none. The bun was absolutely perfect in both, appearance and texture/taste. The meat blend was fantastic and - shockingly - medium rare! In case you don't know that's almost unheard of when you get burgers delivered. The sauces were also great. Just... <span class="emphasized">*chef's kiss*</span>.</p>
				<p>The only minor complaint I have - as many of you will undoubtedly have already guessed - are the included pickles. To be fair, they <span class="emphasized">barely</span> manage to make them work. But still - could do without.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | January 2022"
		},
		{
			type: "image",
			url: require("./Brgr.png")
		}
	]
}
