export default {
	title: "Umami",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>avocado spread</li>
					<li>Parmesan Frico</li>
					<li>roasted onions</li>
					<li>mushrooms</li>
					<li>tomato</li>
					<li>rucola</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A mishmash of incompatible stuff.",
			content: `
				<p>The config is interesting to read, but quite frankly, at the end of the day it's not to my taste. I'm a simple man. I prefer burgers with few ingredients that complement each other. This one's too... diverse.</p>
				<p>I know, I know. That doesn't seem like a particularly insightful criticism. But I'm too lazy to elaborate. Go away.</p> 
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;"><strong>Warning:</strong> if you are considering Stari Pisker's offerings at <a href="https://www.pivoinburgerfest.si/" target="_blank" class="callback">Pivo & Burger Fest</a>, do yourself a favor and don't. While their burgers were some of the best in class, this unfortunately no longer applies, at least not to Burgerfests. They have decided on a brilliant strategic move of prioritizing profits at the expense of their reputation and now produce only well done and super dry meat patties. Therefore, I can no longer recommend them at any venue outside their restaurant. Disregard the existing review/score in any other context.</div>

			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | 2019 (Uncertain)"
		},
		{
			type: "image",
			url: require("./Umami.jpg")
		}
	]
}
