import ChickenGrill from "./Chicken Grill/Chicken Grill.js"
import ChickenCrispy from "./Chicken Crispy/Chicken Crispy.js"
import Bacon from "./Bacon/Bacon.js"

export default {
	title: "Americano",
	tags: ["closed"],
	burgers: [
		ChickenGrill,
		ChickenCrispy,
		Bacon
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/Americano-633877160041763/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
