import McGreggor from "./McGreggor/McGreggor.js"

export default {
	title: "Harat's Pub",
	tags: [],
	url: "https://www.facebook.com/haratsljubljana/",
	burgers: [
		McGreggor
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://ljubljana.harats.com/en/ljubljana/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/haratsljubljana"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.co.uk/Restaurant_Review-g274873-d23717258-Reviews-Harat_s_Pub-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
