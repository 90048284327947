import Prestiz from "./Prestiz/Prestiz.js"

export default {
	title: "Gostilnica in Pivnica Vič",
	tags: [],
	burgers: [
		Prestiz
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://gostilnicainpivnicavic.business.site/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/gostilnicainpivnicaVic"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d17317184-Reviews-Gostilnica_in_pivnica_Vic-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
