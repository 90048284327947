export default {
	title: "John Goat",
	rating: "3",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>aged beef patty</li>
					<li>chutney sauce (with mustard seeds, apple, and cranberries)</li>
					<li>goat cheese</li>
					<li>rucola</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Lukewarm thumbs up.",
			content: `
				<p>Interesting fella. Reminds me of Hood's <a href="/ranking/hood-burger/le-brie" class="reference">Le Brie</a> for some reason. Except it has very different sauce. And cheese.</p>
				<p>...</p>
				<p>Actually, it's not really like Le Brie at all. Fuck. OK, forget it. Let's start from scratch.</p>
				<p>The sauce in this is quite sweet. I suppose I shouldn't be surprised, given the inclusion of apple and cranberries. The goat cheese is also quite strong and makes its presence known. Both of these are unfortunately also what ultimately contributes to this burger's not very high score. The ingredients aren't very harmonious with one another - you constantly feel like you're being pulled in different directions. Or, you know, some shit like that.</p>
				<p>In spite of that, I would still tepidly recommend it if the ingredients look interesting to you.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | January 2019"
		},
		{
			type: "image",
			url: require("./John Goat.jpg")
		}
	]
}
