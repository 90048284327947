import PulledBeef from "./Pulled Beef/Pulled Beef.js"
import UtrganaSvinja from "./Utrgana Svinja/Utrgana Svinja.js"
import ClassiqueCheeseMkI from "./Classique Cheese [Mk. I]/Classique Cheese [Mk. I].js"
import DirtySanchezMkI from "./Dirty Sanchez [Mk. I]/Dirty Sanchez [Mk. I].js"
import JohnnyMkI from "./Johnny [Mk. I]/Johnny [Mk. I].js"
import MetropolitancMkI from "./Metropolitan'c [Mk. I]/Metropolitan'c [Mk. I].js"
import Alora from "./Alora/Alora.js"
import Pohanc from "./Pohanc/Pohanc.js"
import Tiskarna from "./Tiskarna/Tiskarna.js"
import TiskarnaMkI from "./Tiskarna [Mk. I]/Tiskarna [Mk. I].js"
import ClassiqueCheese from "./Classique Cheese/Classique Cheese.js"
import DirtySanchez from "./Dirty Sanchez/Dirty Sanchez.js"

export default {
	title: "Kavarna Tiskarna",
	tags: [],
	burgers: [
		DirtySanchez,
		PulledBeef,
		UtrganaSvinja,
		Alora,
		Pohanc,
		Tiskarna,
		DirtySanchezMkI,
		ClassiqueCheese,
		TiskarnaMkI,
		ClassiqueCheeseMkI,
		MetropolitancMkI,
		JohnnyMkI
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/kavarnaTiskarna/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d7604472-Reviews-Kavarna_Tiskarna-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: `
		<p style="font-weight: 800;">An (Inexplicably) Silent* Revision</p>
		<p>Everyone who knows me or reads my reviews knows there is not a lot of love lost between Tiskarna and I. I've <a href="/editorial/kavarna-tiskarna" class="reference">written about it before</a> and have been a fairly outspoken critic of their burgers from the start.</p>
		<p>But that's all in the past babyyy!</p>
		<p>I don't know why it happened and when (if I were to take a guess it would be sometime during the COVID pandemic of 2020-2021) but Tiskarna changed the ingredients in their burgers. Given my earlier paragraph you can probably imagine I don't exactly frequent their establishment - though that's about to change - so I don't know when exactly the changes took place. But they most assuredly did. And they are most welcome.</p>
		<p>To be honest, I almost didn't notice. I grumpily joined my coworkers one day when they decided to visit. As I always do at Tiskarna, I ordered my default non-burger option, Pulled Beef. As we waited in line, I glanced over the menu to see what others were ordering. Reading the specs, something seemed... off. As if those weren't the ingredients I remembered. I checked this trusty resource of mine and sure enough, the burgers had been revised.</p>
		<p>The first major change was the <em>cheese</em> - at least on their more "classic" burgers. It's now the good ol' processed Cheddar. I don't know what kind of cheese they were using before but it wasn't that. And it wasn't good. Well - that line of criticism is now entirely defunct. <a href="https://www.urbandictionary.com/define.php?term=based" class="reference">Based</a>.</p>
		<p>The second major change was the <em>meat blend</em>. This was the big one. The old patties were average at best. They are now much improved. Still not at the level of the top dogs but undeniably well above average. There is one critique I would levy, however: the patties are pretty thin. But it's not a major issue.</p>
		<p>There may be other changes but these are the big ones I noticed. And they are enough to put the joint in my good graces.</p>
		<p>Welcome to the big leagues, Kavarna Tiskarna.</p>
		<hr style="margin-top: 20px; opacity: 0.5;" />
		<p><strong>UPDATE:</strong> After writing the above, I remembered instead of guessing which changes were made, I could just ask the people working at Kavarna. Groundbreaking notion, I know. So I talked to the cashier. He told me that everything is still the same as it ever was. The only change he was aware of is the new <a href="/ranking/kavarna-tiskarna/classique-cheese" class="reference">Classique Cheese</a> no longer including onions. Because the people tended to order the burger without them, apparently.</p>
		<p>Now, before you succumb to your inner hater and call me a dumb fuck, simply look at the image of the <a href="/ranking/kavarna-tiskarna/dirty-sanchez-mk-i" class="reference">old</a> vs. <a href="/ranking/kavarna-tiskarna/classique-cheese" class="reference">new</a>. Does that look like the same cheese to you? I DIDN'T THINK SO, BITCH!</p>
		<p>To be clear, I am not saying the cashier lied to me. That would be a silly claim.</p>
		<p>The truth is, this is a conspiracy that clearly stems from the highest echelons of Kavarna Tiskarna. The revision was so insidious and secretive their own rank and file don't even know about it. It took someone like me, a seasoned and grizzled veteran, to unveil the subterfuge.</p>
		<hr style="margin-top: 20px; opacity: 0.5;" />
		<div style="font-size: 80%; line-height: 15px; font-style: italic;">* About the lede: I don't actually know if they advertised the changes or not. But consider this: if a tree falls in a forest and I'm not around to hear it, does it make a sound? That's a rhetorical question. The answer is no. Therefore, the lede stays.</div>
	`
}
