export default {
	title: "Jack the Tartuf",
	rating: "2",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>mustard & BBQ sauce</li>
					<li>Gorgonzola cheese</li>
					<li>truffles</li>
					<li>bacon</li>
					<li>rucola</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "This is, unequivocally, the most personally annoying name for a food product I have ever seen.",
			content: `
				<p>To put this in perspective: there is a meat product in Japan (obviously) called <a href="https://www.google.si/search?q=Homo+Sausage&source=lnms&tbm=isch&sa=X&ved=0ahUKEwjgh-2u6JrSAhUGDSwKHdSyB8AQ_AUICCgB&biw=1920&bih=983" class="reference">Homo Sausage</a>. I'm not kidding, follow the link (well... maybe not if you're at work). But do you know what even the name 'Homo Sausage' doesn't contain? Words that don't exist in the English language. You know, like 'Tartuf'.</p>
				<p><a href="https://www.youtube.com/watch?v=XvWDNPriUY8" class="reference">ENGLISH, MOTHERFUCKER, DO YOU SPEAK IT?!</a></p>
				<p>Just to be clear: I don't demand all product names with words of existing concepts have English translations. All I'm saying is: pick the fucking language and stick to it.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | Junij 2017"
		},
		{
			type: "image",
			url: require("./Jack the Tartuf.jpg")
		}
	]
}
