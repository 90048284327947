export default {
	title: "Klasik Burger",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>house sauce</li>
					<li>Cheddar cheese</li>
					<li>bacon</li>
					<li>lettuce</li>
					<li>tomato</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Expectation exceeded.",
			content: `
				<p>I was expecting a middle of the road burger but this was a pleasant surprise. It's a fairly simple burger but there was one ingredient of higher than expected quality which elevated it: the meat. Turns out a good beef makes good burgers. Crazy.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | November 2021"
		},
		{
			type: "image",
			url: require("./Klasik.png")
		}
	]
}
