export default {
	title: "Lars Burger",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>house BBQ sauce</li>
					<li>Cheddar cheese</li>
					<li>lettuce</li>
					<li>tomato</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries (during select hours)</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Not a fan.",
			content: `
				<p>It was this burger that made me realize what bothers me about L&S: their sauce. I don't care for it. I didn't notice it in <a href="/ranking/lars-sven/sven-burger" class="reference">Sven Burger</a> because the bacon probably clouded my judgement. Lars, however, has no such fancy tricks up its sleeve. As a result, its flaws are laid bare.</p>
				<p><span class="emphasized">*Cue the furious <a href="https://www.youtube.com/watch?v=3qwU1LQZA5g" target="_blank" class="callback">screeching</a> of diehard L&S fans.*</span></p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2017"
		},
		{
			type: "image",
			url: require("./Lars.jpg")
		}
	]
}
