export default {
	title: "Fried Chicken Burger",
	rating: "3",
	tags: ["discontinued"],
	dish_type: "sandwich",
	meat_types: ["chicken"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>fried chicken</li>
					<li>house sauces</li>
					<li>rucola</li>
					<li>tomato</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The search continues.",
			content: `
				<p>I ordered this because I was looking for a replacement to Americano's <a href="/ranking/americano/chicken-grill-burger" class="reference">Chicken Grill Burger</a> now that I no longer travel to Kranj on a daily basis. Unfortunately, this wasn't it. It's not bad but it fails to impress me in any way.</p>
				<p>The sauce they use works well on their beef burgers (or as they are otherwise known: burgers; which this is not, despite the name) does not go nearly as well with their fried chicken. And neither does rucola, if you ask me (which, by reading this, you legally have).</p>
				<p>I would recommend.</p>
				<p>... skipping it, especially since Dežela Okusov has <a href="/ranking/dezela-okusov/classic-burger" class="reference">an amazing burger.</a> 
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2017"
		}
	]
}
