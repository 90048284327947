export default {
	title: "Pohanc",
	rating: "3.5",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["chicken"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>white bread bun</li>
					<li>fried chicken</li>
					<li>tartar sauce</li>
					<li>tomatoes</li>
					<li>lettuce</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Weirdly not weird.",
			content: `
				<p>The spec sheet of this sandwich is almost quaint. I'm not used to seeing straightforward ingredients in a sandwich when it comes to Kavarna Tiskarna. Still, I suppose I should be grateful. Given their track record...</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | June 2022"
		},
		{
			type: "image",
			url: require("./Pohanc.png")
		}
	]
}
