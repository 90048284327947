export default {
	title: "Black Angus Premium Burger",
	rating: "3.5",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>Black Angus beef patty</li>
					<li>house BBQ sauce</li>
					<li>Cheddar cheese</li>
					<li>lettuce</li>
					<li>tomato</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A delectable misnomer.",
			content: `
				<p>This is the same as Soba 102's own <a href="/ranking/soba-102/black-angus-bacon-burger" class="reference">Black Angus Bacon Burger</a> minus the bacon and marinated onions. Which... what exactly do you mean by "Premium" then? How is the burger with exactly the same specs <span class="emphasized">plus</span> bacon and caramelized onions <span class="emphasized">not</span> the premium one? That makes no sense.</p>
				<p>Be that as it may, this burger is good on its own but ultimately not worth it. Skip this and spring for the bacon and onions (you know, the <span class="emphasized">premium</span> components).</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018"
		},
		{
			type: "image",
			url: require("./Black Angus Premium.jpg")
		}
	]
}
