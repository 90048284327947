export default {
	title: "Classic Burger",
	rating: "3",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>dressing</li>
					<li>Cheddar cheese</li>
					<li>lettuce</li>
					<li>onions</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Generally fine.",
			content: `
				</p>However, Fany & Mary's own <a href="/ranking/fany-mary/krpan-bacon-burger" class="reference">Krpan Bacon Burger</a> is superior because bacon.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | August 2016"
		},
		{
			type: "image",
			url: require("./Classic.jpg")
		}
	]
}
