export default {
	title: "Mind Blower",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>house sauce</li>
					<li>Cheddar cheese</li>
					<li>bacon</li>
					<li>lamb's lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The burger certainly lives up to half of its name.",
			content: `
				<p>When I saw the plaque at Pivo & Burger Fest, the choice was removed from my hands as higher forces took over. A burger with a name like that? I had to order it. It was my destiny.</p>
				<p>I would say I regret this turn of events but that would imply I expected this burger to be good.</p>
				<p>Next time, maybe channel some of that energy into the recipe instead of wasting it on hubris.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | September 2022"
		},
		{
			type: "image",
			url: require("./Mind Blower.jpg")
		}
	]
}
