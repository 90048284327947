export default {
	title: "Tretja Smena",
	rating: "1",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>house smoked sauce</li>
					<li>double cheese</li>
					<li>lettuce</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "This burger made me feel dead inside.",
			content: `
				<p>Its name translates to what is commonly referred to as <span class="emphasized">the graveyard shift</span>. Which I suppose is apt.</p>
				<p>One sad little burger, this one. The mayo-esque sauce and cheese were not very good (yet probably the best part of this burger). The patty was so thin you could probably see through it. And to top it all off, the pickle - which tasted like the ones found in pre-made sandwiches one buys at a gas station - overpowered everything in terms of taste. SKIP.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | October 2017 (Uncertain)"
		},
		{
			type: "image",
			url: require("./Tretja Smena.jpg")
		}
	]
}
