export default {
	title: "Taprav",
	rating: "1.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>piccadilly sauce</li>
					<li>lettuce</li>
					<li>tomatoes</li>
					<li>pickles</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Easy: no.",
			content: `
				</p>I've said this before and I'll say it again: I like it when people make my reviews easy.</p>
				<p>What do I mean by this? Well, it's very easy not to recommend this burger.</p>
				<p>The portions are small. The patty is ridiculously thin and the meat is substandard. The other ingredients aren't much better either. THERE'S NO. FUCKING. CHEESE.</p>
				<p>See? Easy.
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | September 2020"
		},
		{
			type: "image",
			url: require("./Taprav.jpg")
		}
	]
}
