export default {
	title: "Beerger",
	rating: "3",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>house sauce</li>
					<li>smoked cheese</li>
					<li>bacon</li>
					<li>onion marmelade</li>
					<li>fresh tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Expectations vs. reality.",
			content: `
				<p>There is a mismatch here that shouldn't be possible. On paper, this burger looks legit. Unfortunately, it didn't translate into practice.</p>
				<p>First, the patty. It was so dry I could feel the humidity around me plummeting as I brought it closer to my mouth. I understand that some of it is due to the venue. But still - either learn to grill it less or rethink the fat/lean ratio.</p>
				<p>Second, the seasoning. Everything was bland. Blander than it had any right to be. Smoked cheese, bacon and onion jam should not result in such blandness.</p>
				<p>I'm being more critical than I would usually be for a burger I gave three stars to. However, this used to be a better product a few years ago and I am to some degree comparing it to what it used to be. I hate seeing it deteriorate like this.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | September 2022"
		},
		{
			type: "image",
			url: require("./Beerger.jpg")
		}
	]
}
