export default {
	title: "Hood Steak",
	rating: "3",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>hoagie bread roll</li>
					<li>aged beef rump steak slices</li>
					<li>Provolone cheese</li>
					<li>grilled onions</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Decent, though outclassed.",
			content: `
				<p>All right, listen up. Even this is <span class="emphasized">still</span> not a burger. Two words: <span class="emphasized">beef patty</span>. Hood only got the second half right.</p>
				<p>And before the apologists come out of the woodwork and start with all the "but it's called a 'steak', not a 'burger'," nonsense: it's <span class="emphasized">listed under burgers on the menu</span>. Didn't think I'd notice, did you? Well, you're WRONG!</p>
				<p>Labels aside, while this thing is worth a shot it is ultimately overshadowed by its arch nemesis: the venerable <a href="/ranking/lars-sven/philly-cheese" class="reference">Philly Cheese</a> of House Lars & Sven.</p>
				<p>I go into greater detail as to why exactly that is <a href="/ranking/lars-sven/philly-cheese" class="reference">in its review</a>. Sufficed to say: if you insist on Provolone and don't want Cheddar, you owe it to yourself try Philly Cheese one at some point. And if a different choice of cheese doesn't bother you then give Hood's <a href="/ranking/hood-burger/chilli-cheese-steak-sandwich" class="reference">Chilli Cheese Steak Sandwich</a> a shot.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | May 2019"
		},
		{
			type: "image",
			url: require("./Hood Steak.jpg")
		}
	]
}
