import BurgerBacon from "./Burger Bacon/Burger Bacon.js"

export default {
	title: "Allegro Bistro & Lounge Bar",
	tags: [],
	burgers: [
		BurgerBacon
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://bistroallegro.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/allegro.bistroloungebar/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://restaurantguru.com/Allegro-Bistro-and-Lounge-Bar-Ljubljana"
		}
	],
	review: "DO NOT COME HERE. STAY AWAY."
}
