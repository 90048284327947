export default {
	title: "Sven Burger",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>house BBQ sauce</li>
					<li>Cheddar cheese</li>
					<li>crispy bacon</li>
					<li>caramelized onions in balsamic vinegar</li>
					<li>tomato</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries (during select hours)</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The promising crown jewel of Lars & Sven undone by the same thing that plagues most of their burgers.",
			content: `
				<p>This could fairly be, I think, considered the L&S' flagship. It has it all. Bacon (+½ point). Caramelized onions. The name that implies someone named Sven was killed and ground up so this burger could be made. And it's very good (the burger, not murder). But it isn't going to make any halls of fame of mine.</p>
				<p>I know that's gonna upset some of you L&S sycophants but I don't care. That sauce ain't it, chief.</p>
				<p>Not that it's bad, to be clear. Just far below some of the competition.</p>

			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2017"
		},
		{
			type: "image",
			url: require("./Sven.jpg")
		}
	]
}
