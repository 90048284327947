import SmashedTruffleCiz from "./Smashed Truffle Ciz/Smashed Truffle Ciz.js"
import DoubleSmashedCiz from "./Double Smashed Ciz/Double Smashed Ciz.js"

export default {
	title: "OMAMI Burgers",
	tags: [],
	burgers: [
		SmashedTruffleCiz,
		DoubleSmashedCiz
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/people/OMAMI-Burgers/100068976596432/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: `
		<p>This establishment has the same owner as <a href="/ranking/tink-superfood-cafe/" class="reference">TINK Superfood Café</a>.</p>
		<p>At first I didn't realize it. The only reason it even dawned on me is because the spec sheets of the burgers looked vaguely familiar which prompted me to investigate.</p>
		<p>Having said that, this whole thing is baffling.</p>
		<p>Even though these were meant to ostensibly be the same burgers (at least based on the specs), they are, in fact, worlds apart. TINK Superfood's burgers were some of the worst I've ever eaten. OMAMI's, however, are well above average. It doesn't make any sense.</p>
		<p>The only recommendation I can give is to stay far away from Tink and go to OMAMI instead, burger-wise.</p>
	`
}
