import Burgersslovenskogovedino from "./Burger s slovensko govedino/Burger s slovensko govedino.js"

export default {
	title: "Boschitz",
	tags: [],
	burgers: [
		Burgersslovenskogovedino
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.boschtiz.si/domov"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/BoschtizLitostroj"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d11892674-Reviews-Boschtiz-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
