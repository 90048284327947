export default {
	title: "Dirty Sanchez",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>white bread bun</li>
					<li>beef patty</li>
					<li>house BBQ sauce</li>
					<li>Nacho cheese</li>
					<li>caramelized onions</li>
					<li>bacon</li>
					<li>fried egg</li>
					<li>lettuce</li>
					<li>tomatoes</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Eggy delight.",
			content: `
				<p>This is now the best burger Tiskarna makes. And it's excellent. It's that simple.</p>
				<p>Could it be improved? Of course. Like I mentioned in some other reviews of this new crop of burgers, bolder seasoning would help. I would also honestly prefer Cheddar to Nacho. But then the name wouldn't make sense, would it?</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | February 2023"
		},
		{
			type: "image",
			url: require("./Dirty Sanchez.png")
		}
	]
}
