import BurgerFoieGras from "./Burger Foie Gras/Burger Foie Gras.js"
import Burger from "./Burger/Burger.js"

export default {
	title: "Sorbara Steak House",
	tags: ["closed"],
	url: "http://www.sorbara-steakhouse.si/",
	burgers: [
		Burger,
		BurgerFoieGras
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://sorbara-steak-house.business.site/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/sorbarasteakhouse"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d8088838-Reviews-Sorbara_Steak_House-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
