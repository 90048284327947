export default {
	title: "Pulled Beef",
	rating: "4",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>white bread bun</li>
					<li>pulled beef</li>
					<li>aioli</li>
					<li>Cheddar cheese</li>
					<li>caramelized onions</li>
					<li>baby spinach</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "At long last!",
			content: `
				<p>Fellas. I have found it. After all the disappointing visits, I have finally come across a sandwich from Kavarna Tiskarna that I can wholeheartedly recommend. Oh, what a blessed day!</p>
				<p>Still, nothing is ever so simple. Sir Terry Pratchett once wrote: “Light thinks it travels faster than anything but it is wrong. No matter how fast light travels, it finds the darkness has always got there first, and is waiting for it.”
				In other words, I there are some caveats.</p>
				<p>Number one: pickle. Why? Please revisit this terrible decision.</p>
				<p>Number two: build. I don't know if this was a one-off or not but all the spinach was crammed on one side of my sandwich and all the pickles on the other. Unnecessarily lazy. Nothing like the picture, lemme tell you.</p>
				<p>Yet in the grand scheme of things, these are minor nitpicks. I'm still very happy I have an actually great go-to sandwich I can now order at Tiskarna without worry.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | April 2022"
		},
		{
			type: "image",
			url: require("./Pulled Beef.png")
		}
	]
}
