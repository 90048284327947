export default {
	title: "Burger",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>store-bought bun</li>
					<li>store-bought default beef blend</li>
					<li>store-bought mayo mixed with truffle oil</li>
					<li>store-bought BBQ sauce</li>
					<li>Chester cheese</li>
					<li>crispy bacon</li>
					<li>homemade caramelized onions</li>
					<li>tomato</li>
					<li>lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Nice burger if you can get it.",
			content: `
				<p>This burger was somewhat of a pain in the ass to procure. First, I had to infiltrate a close-knit group of freshmen at the Faculty of Chemistry and Chemical Technology in Ljubljana in order to deviously befriend Filip, the linchpin of my Master Plan. Then, I had to strategically bide my time for about ten years and wait for Filip to obtain a grill. Which he did, exactly as I predicted. After that, it was only a matter of waiting for an invitation to a luncheon with some friends over the weekend. Which dutifully arrived exactly according to my calculations.</p>
				<p>"But was the wait worth it," I hear you ask. Well, to be perfectly honest, for the amount of time (and brainpower) this scheme took to pull off, it would have taken a culinary miracle to get a five star review out of me. That being said, my expectations were still exceeded by a substantial margin. This was no doubt due to my brilliant proposal a few days prior when I suggested some caramelized onions ought to be prepared beforehand. The idea proved to be absolutely crucial to the entire effort and singlehandedly saved the Saturday gathering. Thusly enhanced burgers prepared under my masterful tutelage turned out absolutely delightful.</p>
				<p>Oh, and Filip also helped I guess.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | May 2021"
		}
	]
}
