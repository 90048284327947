import AmericanClassic from "./American Classic/American Classic.js"

export default {
	title: "Raca GastroBar",
	tags: [],
	burgers: [
		AmericanClassic
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/raca.bar.3"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: "Not even three months since I ordered from them on Wolt and they're already gone. I assume they still exist, just don't deliver via Wolt. Annoying."
}
