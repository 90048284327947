export default {
	title: "Trgana Svinjina",
	rating: "1",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["pig"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>pulled pork</li>
					<li>spicy mayo</li>
					<li>red onions</li>
					<li>tomato</li>
					<li>crunchy lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A waste of pork.",
			content: `
				<p>This was just plain bad, no to ways about it. The mix of ingredients was ill-considered and not well put together. The sauce was overpowering. Probably some other shit my mind has already erased. Avoid.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		},
		{
			type: "image",
			url: require("./Trgana Svinjina.jpg")
		}
	]
}
