export default {
	title: "Black Angus Roastbeef Steak",
	rating: "4.5",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>hoagie bread roll</li>
					<li>Aussie free range Black Angus beef steak</li>
					<li>Italian Provolone cheese</li>
					<li>caramelized onions in balsamic vinegar</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries (during select hours)</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The best steak sandwich I'm aware of. Also the best item on Lars & Sven's menu.",
			content: `
				<p>I'll start with a big asterisk, however. This thing has a fatal flaw. Well, potentially. Let me explain.</p>
				<p>I'm not sure what cut of meat they use to make this but based on the consistent cap of fat that's always present I'll assume it's a sirloin steak of some sort. I don't mind the fat but I do prefer it rendered to a certain degree. This, however, is where the problems start. For some annoying reason, L&S cuts these steaks pretty thin. This leads to an unenviable decision: you can either order the steak rare and have the fat not be rendered enough or have the fat be rendered but you have to settle for mid-rare or - more realistically - medium doneness.</p>
				<p>Having said that, the sandwich is still excellent. But it could be so much better. Such a shame.</p>
				<p>This also inevitably brings up another point of consideration: L&S also offers <a href="/ranking/lars-sven/philly-cheese" class="reference">Philly Cheese</a>. It has thinner and longer slices of beef and and usually comes medium to medium-well done. But it's cheaper and far more consistent. The Black Angus Roastbeef Steak is capable of greater hights but it can also be a bit of a hit and a miss, depending on who's preparing it. Again - thin steaks, more room for error in doneness.</p>
				<p>To this day I can't decide which one I prefer if I'm perfectly honest. I wish you better luck.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | January 2022"
		},
		{
			type: "image",
			url: require("./Black Angus Roastbeef Steak.jpg")
		}
	]
}
