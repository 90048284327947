import BaconBurger from "./Bacon Burger/Bacon Burger.js"

export default {
	title: "Fast 5 House",
	tags: [],
	burgers: [
		BaconBurger
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/people/Fast-5/100052648909731/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
