export default {
	title: "Razza Piemontese",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>Italian beef Piemontese</li>
					<li>mustard sauce with honey</li>
					<li>smoked cheese Scarmorza</li>
					<li>fried egg</li>
					<li>Grapolo tomatoes</li>
					<li>Iceberg lettuce</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries, smoked house BBQ sauce</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "It's like someone is licensing basically the same burger to all the mediocre joints out there.",
			content: `
				<p>This burger is basically what's become the new average among the many middling newcomers. It's basically a great burger that almost nobody should be disappointed by. On the flip side, it's also very unremarkable. Basically, you can get this exact flavor palette (with very slight variations) from like three or four other establishments. I'll make a list someday so we can shame them.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | November 2021"
		},
		{
			type: "image",
			url: require("./Razza Piemontese.png")
		}
	]
}
