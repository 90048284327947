export default {
	title: "Direktorski",
	rating: "3",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>	
					<li>beef patty</li>
					<li>roast beef</li>
					<li>house sauce</li>
					<li>tomato marmalade</li>
					<li>lettuce</li>
					<li>egg</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A classic case of overpromising and underdelivering.",
			content: `
				<p>I have to start this off with a complaint. The cashier who took my order at <a href="https://www.pivoinburgerfest.si/" target="_blank" class="callback">Pivo & Burger Fest</a> asked me how I wanted the meat done. I said medium-rare. She jotted it down and handed it to the chef. He looked it, verbally acknowledged the desired meat doneness, than proceeded to grill my patty to a perfect well done. If you're somehow reading this - thanks, man.</p>
				<p>That aside, the roast beef stake slices coupled with a fried egg on top of already pretty good patty left a fairly favorable impression. But it was nowhere near my much higher expectations.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | June 2017 (Uncertain)"
		},
		{
			type: "image",
			url: require("./Direktorski.jpg")
		}
	]
}
