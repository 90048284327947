export default {
	title: "Classic Burger",
	rating: "3.5",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>	
					<li>beef patty</li>
					<li>house sauce</li>
					<li>cheese</li>
					<li>lettuce</li>
					<li>tomato</li>
					<li>cabbage</li>
					<li>red onions</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A solid burger overall but not quite up to snuff when it comes to ingredient quality.",
			content: `
				<p>Compared to the famous flagships, at least.</p>
				<p>The beef blend is simply not as good, nor is the sauce anything to write home about. Still, the whole is somehow greater than the sum of its parts so it's still not a bad one.</p> 
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2017"
		},
		{
			type: "image",
			url: require("./Klasik.jpg")
		}
	]
}
