import DryAgedBurger from "./Dry Aged Burger/Dry Aged Burger.js"

export default {
	title: "OPA Resort",
	tags: [],
	burgers: [
		DryAgedBurger
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.oparesort.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/oparesort/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g21342971-d23384320-Reviews-OPA_Resort-Smartno_ob_Paki_Styria_Region.html"
		}
	],
	review: `
		<p>The name of this establishment sounds like a relaxing retreat for a sci-fi terrorist organization.</p>
		<p>(I realize very few of you will recognize this reference, but that's OK.)</p>
	`
}
