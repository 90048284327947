export default {
	title: "Cosa Nostra Burger",
	rating: "3",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>tomato sauce with basil and olive oil</li>
					<li>Mozzarela cheese</li>
					<li>pancetta</li>
					<li>rucola</li>
					<li>fresh tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Fuggedaboutit.",
			content: `
				<p>This <span class="emphasized">goombah</span> thinks he can be a wiseguy, eh? What are you doin'? Addin' tomato sauce <span class="emphasized">and</span> fresh tomatoes? A real <span class="emphasized">cugine</span> move over here. You wanna be made, pick one or the other. Both is no good.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | 2019"
		},
		{
			type: "image",
			url: require("./Cosa Nostra.jpg")
		}
	]
}
