import Texas from "./Texas/Texas.js"

export default {
	title: "Kastrola",
	tags: [],
	burgers: [
		Texas
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://kastrola.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/Kastrola/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
