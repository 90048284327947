import KraljeviKrskopoljc from "./Kraljevi Krskopoljc/Kraljevi Krskopoljc.js"
import FtrganZajc from "./Ftrgan Zajc/Ftrgan Zajc.js"
import GovejiBejkn from "./Goveji Bejkn/Goveji Bejkn.js"

export default {
	title: "Pri Babici Marici na griču",
	tags: [],
	burgers: [
		KraljeviKrskopoljc,
		GovejiBejkn,
		FtrganZajc
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/profile.php?id=100063628116227"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g8406209-d23791320-Reviews-Pri_Babici_Marici_na_gricu-Lenart_v_Slovenskih_Goricah_Styria_Region.html"
		}
	],
	review: ""
}
