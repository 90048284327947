export default {
	title: "Johnny [Mk. I]",
	rating: "1.5",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>white bread bun</li>
					<li>beef patty</li>
					<li>aioli</li>
					<li>Gorgonzola cheese</li>
					<li>caramelized onions</li>
					<li>radicchio</li>
					<li>sautéed button mushrooms</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Why?",
			content: `
				<p>Gather around, children. It is time for a life lesson. Do you remember how your mother and I told you not to trust strangers promising you candy if you enter their van? Well, forget that. This is <span class="emphasized">far</span> more important.</p>
				<p>If you ever encounter a burger whose spec sheet includes ingredients so unintuitive you may think it has to either be a work of an enlightened genius or a mad man, avoid the temptation to find out which is true. The risk is far too great.</p>
				<p>If you for some weird reason have a burger with mushrooms on your bucket list, try Hood Burger's <a href="/ranking/hood-burger/balbo" class="reference">Balbo</a> when available. A far better dish.</p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;">At some point during their existence (not sure when), Tiskarna tweaked their recipes. I now denote their old crop of burgers/sandwiches with "[Mk. I]" in their name. More thoughts and information about the change in the <a href="/ranking/kavarna-tiskarna" class="reference">Comments section</a> on the Joint page.</div>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | 2018"
		},
		{
			type: "image",
			url: require("./Johnny [Mk. I].jpg")
		}
	]
}
