export default {
	title: "Tivoli Pohanček",
	rating: "4",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["chicken"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>fried chicken</li>
					<li>house sauce</li>
					<li>Parmesan cheese</li>
					<li>onion rings</li>
					<li>coleslaw</li>
					<li>tomatoes</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Holy shit!",
			content: `
				<p>Finally - <span class="emphasized">finally!</span> - we have a fallback if Pop's Place someday decides to (unwisely) discontinue its <a href="/ranking/pops-place/chicken-sandwich" class="reference">Chicken Sandwich</a>. This is as close as anyone has ever gotten to producing a worthy rival. Praise be!</p>
				<p>The chicken was fantastic and covered in panko breadcrumbs - a delightful surprise. The sauce was fantastic and paired with the chicken exceptionally well. Coleslaw likewise rose to the task.</p>
				<p>It's hard to find faults with this sandwich. But don't worry, fam. I got u.</p>
				<p>If I were to nitpick, the tomato slices were cut slightly thicker than I prefer. Another (half-hearted) criticism I could levy would be that the chicken had a pretty thick crust (possibly double-dipped?). On its own that's of course not a problem. Pairing that with Mini Cafe Golf's thicker than average bun, however, the result was a fairly carb-forward sandwich. But again - nitpick.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | May 2022"
		},
		{
			type: "image",
			url: require("./Tivoli Pohancek.png")
		}
	]
}
