export default {
	title: "Double Cheeseburger",
	rating: "1.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>sesame burger bun</li>
					<li>dry aged beef patty</li>
					<li>sauce</li>
					<li>Cheddar</li>
					<li>onions</li>
					<li>lettuce</li>
					<li>tomatoes</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "This ain't it, chief.",
			content: `
				<p>Welcome to my Double Cheese review liveblog. Let's get right to it.</p>
				<p><span class="emphasized">[19:25:17]</span> First bite. OK I guess. 'Bit boring, really. Very middle of the road.</p>
				<p><span class="emphasized">[19:25:33]</span> WHAT. THE. FUCK. I just looked more closely at the picture on Wolt and it confirmed a most unpleasant surprise - a pickle! And it's not in their spec sheet, either. They lied to me. LIED!</p>
				<p><span class="emphasized">[19:25:34]</span> And it's the worst kind, too.</p>
				<p><span class="emphasized">[19:25:48]</span> Having promptly de-pickled the burger, we're safely and firmly back on the road to mediocrity.</p>
				<p><span class="emphasized">[19:26:41]</span> I took a bite or two on the side without much cheese/sauce and I'm realizing this meat is really bland. Also chewy.</p>
				<p><span class="emphasized">[19:27:25]</span> The onions were sauted very poorly - they're almost burnt in some places and raw in others. I don't mind either but you have to pick one or the other.</p>
				<p><span class="emphasized">[19:27:27]</span> Seriously, how do you fuck up an onion this badly?</p>
				<p><span class="emphasized">[19:27:35]</span> Fuck this.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | January 2022"
		},
		{
			type: "image",
			url: require("./Double Cheeseburger.png")
		}
	]
}
