export default {
	title: "Kraljevi Burger XXL",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>lepinja bread</li>
					<li>beef patty</li>
					<li>house sauce</li>
					<li>cheese</li>
					<li>onions</li>
					<li>lettuce</li>
					<li>tomato</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Your best isn't good enough.",
			content: `
				<p>Probably Cube's best burger. The problem is that the joint itself is simply not up to the standards of the top contenders. In light of this, their best is average to below average when compared to the rest. It's not to say their burgers suck. Simply that when given choice between, say, them and <a href="/ranking/hood-burger" class="reference">Hood Burger</a> - which is located five minutes away on foot - I'd almost never pick Cube.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018"
		},
		{
			type: "image",
			url: require("./Kraljevi.jpg")
		}
	]
}
