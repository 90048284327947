export default {
	title: "Metropolitan'c [Mk. I]",
	rating: "1.5",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>white bread bun</li>
					<li>beef patty</li>
					<li>mayo with fresh basil</li>
					<li>goat cheese</li>
					<li>rucola</li>
					<li>tomato marmalade</li>
					<li>chili</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "What a mess.",
			content: `
				<p>You know what, fuck it, this is (arguably) a review site, lemme break it down. Even though I don't really want to.</p>
				<p>The meat is good, not great. The goat cheese is almost tasteless. And I know there's a lot of it there, I fucking saw it! There was almost more rucola here than the meat. Unacceptable. And to top it all off, the tomato & chilly jam ruins what may otherwise be a passable burger.</p>
				<p>What is this? Are you playing some sick, twisted game with me? <a href="https://www.youtube.com/watch?v=sp9eClImVxw" class="reference">DAMMIT! I WANT ANSWERS!</a></p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;">At some point during their existence (not sure when), Tiskarna tweaked their recipes. I now denote their old crop of burgers/sandwiches with "[Mk. I]" in their name. More thoughts and information about the change in the <a href="/ranking/kavarna-tiskarna" class="reference">Comments section</a> on the Joint page.</div>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | 2018"
		},
		{
			type: "image",
			url: require("./Metropolitanc [Mk. I].jpg")
		}
	]
}
