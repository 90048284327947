export default {
	title: "Alora",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>white bread bun</li>
					<li>beef patty</li>
					<li>truffle mayo</li>
					<li>smoked Mozzarella cheese</li>
					<li>sautéed button mushrooms</li>
					<li>caramelized onions</li>
					<li>rucola</li>
					<li>tomatoes</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Seems familiar...",
			content: `
				<p>When I saw the spec sheet I immediately thought of Hood's <a href="/ranking/hood-burger/balbo-2022" class="reference">Balbo</a> (the upgraded 2022 edition, to be precise). Having eaten this burger, I can confidently proclaim that Balbo remains the best burger with mushrooms I've tried.</p>
				<p>But don't misunderstand: this is a great product. And it comes damn close.</p>
				<p>In fact, if they removed the tomato and (probably) rucola, I'd put it on the same level. Balbo might still maintain a slight edge due to the choice of more appropriate cheese but whatever.</p>
				<p>If unlike me you don't mind the rucola/tomato/shroom combo then this is for you.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | April 2023"
		},
		{
			type: "image",
			url: require("./Alora.png")
		}
	]
}
