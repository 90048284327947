import BaconBurger from "./Bacon Burger/Bacon Burger.js"
import OriginalBurger from "./Original Burger/Original Burger.js"

export default {
	title: "Hotspot Bar & Bistro",
	tags: [],
	burgers: [
		BaconBurger,
		OriginalBurger
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://hotspotbar.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/hotspotbar17/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d10250703-Reviews-Hotspot_Bar_Bistro-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
