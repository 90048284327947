export default {
	title: "Hrustljavčkov Burger",
	rating: "5",
	tags: ["special-edition"],
	dish_type: "sandwich",
	meat_types: ["pig"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>	
					<li>roast piglet</li>
					<li>BBQ sauce</li>
					<li>coleslaw</li>
					<li>fried onions</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Roast. Fucking. Piglet.",
			content: `
				<p>Need I say more?</p>
				<p>That was a rhetorical question. The answer is of course 'no'. However, some have argued this is a review site. So I feel compelled to string together a few more sentences.</p>
				<p>I was initially surprised more establishments don't offer sandwiches like this. However, it is admittedly a bit of a logistical challenge. The way they make this burger is by roasting an entire piglet then cutting it up and putting the pieces between bun slices. I assume this would be a bit costly to do every day. My guess is that this is also why pulled pork is a far more common way to do not-really-burgers like this. Which is a shame. Because fuck, man! It's so good!</p>
				</p>The only place I've ever seen this type of sandwich was at <a href="https://www.pivoinburgerfest.si/" target="_blank" class="callback">Pivo & Burger Fest</a>. Which, in a way, is genius. I order one whenever I attend. It's a nice change of pace when you're getting tired of all the beef.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | September 2021"
		},
		{
			type: "image",
			url: require("./Hrustljavckov.png")
		}
	]
}
