import Punkt from "./Punkt/Punkt.js"
import Dallas from "./Dallas/Dallas.js"
import Francoski from "./Francoski/Francoski.js"
import Kraski from "./Kraski/Kraski.js"
import Chicago from "./Chicago/Chicago.js"
import StejkovSendvic from "./Stejkov Sendvic/Stejkov Sendvic.js"

export default {
	title: "Punkt",
	tags: [],
	burgers: [
		Chicago,
		StejkovSendvic,
		Francoski,
		Punkt,
		Dallas,
		Kraski
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/punktkotlje/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g1900775-d11841853-Reviews-Punkt_Bistro_in_Kavarna-Ravne_na_Koroskem_Carinthia_Region.html"
		}
	],
	review: ""
}
