export default {
	title: "Cheez",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>beef patty</li>
					<li>house sauce</li>
					<li>Cheddar cheese</li>
					<li>iceberg lettuce</li>
					<li>tomato</li>
					<li>white onions</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Pretty much as good as it can possibly get with that config.",
			content: `
				<p>Looking at the specs it's basically the most standard cheeseburger config you can find. Patty with Cheddar, sauce, veggies.</p>
				<p>There is a reason for my caveat, however: <span class="emphasized">"with that config"</span>. Take the onions, for example. What are they doing there, not caramelized?</p>
				<p>Sometimes things just don't make sense.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018"
		},
		{
			type: "image",
			url: require("./Cheez.jpg")
		}
	]
}
