import Boss from "./Boss/Boss.js"
import CaliforniaClassic from "./California Classic/California Classic.js"
import KobeBeefSliders from "./Kobe Beef Sliders/Kobe Beef Sliders.js"

export default {
	title: "As Aperitivo",
	tags: [],
	burgers: [
		Boss,
		KobeBeefSliders,
		CaliforniaClassic
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "http://www.gostilnaas.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/asaperitivo/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d7197334-Reviews-AS_Aperitivo-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
