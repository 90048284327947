export default {
	title: "California Classic",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>dry-aged beef patty</li>
					<li>house sauce</li>
					<li>Cheddar cheese</li>
					<li>bacon</li>
					<li>lettuce</li>
					<li>tomato</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries & ketchup</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Life lesson: superior ingredients on paper don't always translate to real life.",
			content: `
				<p>The fans of <a href="/ranking/pops-place" class="reference">Pop's Place</a> are going to start noticing a pattern in the names of As's burgers. Not entirely coincidental. As I've mentioned elsewhere, they have the same owner.</p>
				<p>In theory, this burger should be a monumental improvement over Pop's <a href="/ranking/pops-place/original-california-classic" class="reference">Original California Classic</a>. It ditches the pickle and adds bacon. Amazing. Alas, few things in life are that simple.</p>
				<p>There were two issues with this burger that force me to rank it lower than the OG. First, the bun on this burger was simply not on par with Pop's. Second, the bacon was not prepared as well as I had hoped. It considerably lacked crisp and was just generally not baked/seared sufficiently. I hope it was just my copy but that's what I had to go on. If I revisit this in the future and detect an improvement, I shall update the score.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018"
		},
		{
			type: "image",
			url: require("./California Classic.jpg")
		}
	]
}
