import PulledBeef from "./Pulled Beef/Pulled Beef.js"

export default {
	title: "Dnevni bar pr' Mrtinet",
	tags: [],
	burgers: [
		PulledBeef
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "http://www.prmrtinet.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: ""
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g1077245-d9983877-Reviews-Dnevni_bar_Pr_Mrtinet-Grosuplje_Lower_Carniola_Region.html"
		}
	],
	review: ""
}
