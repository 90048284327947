export default {
	title: "Texas Burger",
	rating: "2",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>grilled butter bun</li>
					<li>beef patty</li>
					<li>BBQ dressing</li>
					<li>cheese</li>
					<li>bacon</li>
					<li>tomatoes</li>
					<li>lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Boo!",
			content: `
				<p>The patty was denser than a neutron star. Also severely over-salted. Equipped with a single strip of bacon which wasn't even crunchy. The BBQ sauce - the one thing no one ever fucks up - was somehow forgettable as well.</p>
				<p>Thankfully, I ordered some onion rings with which I was able to mask the burger's mediocrity.</p>
				<p>Would recommend to anyone attempting to have their day ruined.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | October 2022"
		},
		{
			type: "image",
			url: require("./Texas.png")
		}
	]
}
