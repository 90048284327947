export default {
	title: "Smokey Burger [Mk. I]",
	rating: "3",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>smoked mayonnaise</li>
					<li>maple ketchup</li>
					<li>Cheddar cheese</li>
					<li>crispy bacon</li>
					<li>onion marmalade</li>
					<li>tomato</li>
					<li>salad mix</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Whatever.",
			content: `
				<p>It's OK. I'd maybe recommend it.</p>
				<p>With that out of the way, allow me to hijack the review of this burger and turn it into a rant about some annoying things about Forum in general. First, if they improved the meat, I could easily give all their burgers +1 point which would make this one of the most highly rated joints. But I can't do that because again, the patties are a not up to snuff. Second, I always appreciate fries with my burgers. Having said that, the burgers are fairly small which makes it seem you're almost getting more fries than you are burger which might be fine if the fries weren't so painfully generic and rather flavorless.</p>
				<p><span class="emphasized" style="font-size: 80%; line-height: 15px; display: block;">(Hi, yours truly from the future here; ordinarily I would include comments from the last paragraph under <a href="/ranking/forum" class="reference">joint's Comments section</a> now that I have this fancy new website. However, this criticism no longer applies - Forum has revised all their burgers which require a different line of criticism. Which I shall provide elsewhere.)</span></p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;"><strong>Warning:</strong> this burger is NOT the same as <span class="emphasized">Smokey Burger</span> currently sold at their restaurant. Hence, I dubbed this one <span class="emphasized">Mk. I</span> to make it clear that they are different burgers. I unfortunately do not know when exactly the switcheroo took place as it was a silent revision.</div>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | October 2018"
		}
	]
}
