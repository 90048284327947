export default {
	title: "Burger",
	rating: "3.5",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bread bun</li>
					<li>dry aged beef patty</li>
					<li>Cheddar cheese</li>
					<li>emulsion of anchovies, baby cabbage, ginger, and chili</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Short-lived refreshing goodness.",
			content: `
				<p>This burger seems to be discontinued. It usually takes me a while to post these reviews and by the time I got around to it, I can't find it on the joint's menu anymore. Thankfully, I saved the picture.</p>
				<p>This was another one of those asian fusion burgers. If you're an avid reader of mine, you will know that this small category tends to punch above its weight class, so to speak. The trend continues with this one.</p>
				<p>The burger is quite spicy but not overly so. It comes with a fairly thick and very tender patty properly covered with cheese. The... sauce is also... on top...</p>
				<p>OK, I'll be honest. It's been a long time since I ate this and yes, I admit, I am basically just looking at the picture and describing it, trying to cobble a review together from memory. But who cares? The burger doesn't exist anymore. Stop wasting your time reading this and move on to a more relevant review.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | June 2022"
		},
		{
			type: "image",
			url: require("./Burger.png")
		}
	]
}
