import Chicken from "./Chicken/Chicken.js"
import BlackAngusPremium from "./Black Angus Premium/Black Angus Premium.js"
import BlackAngusBacon from "./Black Angus Bacon/Black Angus Bacon.js"

export default {
	title: "Soba 102",
	tags: ["closed"],
	burgers: [
		BlackAngusBacon,
		BlackAngusPremium,
		Chicken
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: ""
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
