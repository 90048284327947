export default {
	title: "Chicken Burger",
	rating: "3",
	tags: ["discontinued"],
	dish_type: "sandwich",
	meat_types: ["chicken"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>ground chicken patty</li>
					<li>mayo with button mushrooms</li>
					<li>lettuce</li>
					<li>tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "In Memoriam.",
			content: `
				<p>Before I get to the meat of this review (so to speak), let me get something out of the way. Hood thinks they're real' fucking clever, trying to sneak one past me. But I will not allow it. Just because you blended the meat does not make this product a burger. It's still just chicken. Mighty fine chicken, to be sure. But chicken nonetheless.</p>
				<p>It's a sandwich is what I'm saying.</p>
				<p>It's also the first thing I ever ordered at Hood. Back in the day I was hesitant to try a real burger because I wasn't sure I'd like beef patties (please don't lynch me). This provided me with a safe alternative that seemed more agreeable. And it was. During my next dozen or so visits to Hood, this was all I ordered. And it was this sandwich that ultimately convinced me to try Hood's other products. And thank god I did.</p>
				<p>Ultimately, I found many burgers and sandwiches I consider superior to this one. But I remember none of them as fondly.</p> 
				<p>You will seldom find reminiscences in my reviews. But there is a particular reason behind this one.</p>
				<p>See, this sandwich was discontinued before I started reviewing burgers. The Config section may even be wrong - unlike the others, I was going off of memory and the only picture of the sandwich I could find. I haven't done this for any others on this list. That's how much I loved this dish.</p>
				<p>In fact...</p>
				<p>I made a decision. I am about to do something I have never done before nor shall I ever do again.</p>
				<p>You may disregard the first two paragraphs of this review. For its distinguished service, I hereby proclaim this sandwich shall be granted the highest title I am able to bestow - the title of <span class="emphasized">honorary burger</span>. 
				<p>Rest in peace, old friend.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2016"
		},
		{
			type: "image",
			url: require("./Chicken.jpg")
		}
	]
}
