import Beef from "./Beef/Beef.js"

export default {
	title: "Oaza PEF",
	tags: [],
	burgers: [
		Beef
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://wolt.com/en/svn/ljubljana/restaurant/oaza-pef"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: ""
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
