export default {
	title: "Blackadder Burger",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>charcoal bread bun</li>
					<li>beef patty</li>
					<li>house fig sauce</li>
					<li>aged English Cheddar cheese</li>
					<li>crispy Istrian pancetta</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries (during select hours)</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "As good as the latter seasons of the TV show.",
			content: `
				<p>Right off the bat: this is the best <span class="emphasized">burger</span> L&S makes. I emphasize <span class="emphasized">burger</span> because they do make <a href="/ranking/lars-sven" class="reference">other, better things</a>. But that's besides the point.</p>
				<p>The reason I like this one over the others is, I suspect, the sauce. I am on the record as not being much of a fan of their sauces in general. This one pleasantly surprised me, however, peculiar as it may be. I mean, figgs? Who'd have thought. It also has bacon which means an automatic +½ point. It's a very well balanced burger overall. Fewer but more well-considered ingredients do tend to correlate with success, I find.</p>
				<p>Pro tip: If you're one of the few people who don't mind your patty grilled rare, absolutely go for it. I find that it works much better this way with this burger specifically.</p>
				<p>The only thing I'm not sold on is the black bun. They don't bother me too much but I just can't shake the feeling that simply replacing them with whites would yield a better outcome.</p>
				<p>...</p>
				<p>OK, that came out wrong. That's not what I meant.</p>

			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | March 2019"
		},
		{
			type: "image",
			url: require("./Blackadder.jpg")
		}
	]
}
