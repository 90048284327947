export default {
	title: "Original Burger",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche buns</li>
					<li>beef patties</li>
					<li>sauce</li>
					<li>Cheddar cheese</li>
					<li>red onions</li>
					<li>lettuce</li>
					<li>tomatoes</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Edible.",
			content: `
				<p>I don't really like Hotspot's sauce. That's what I learned here. Other stuff is whatever.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | March 2023"
		},
		{
			type: "image",
			url: require("./Original Burger.png")
		}
	]
}
