export default {
	title: "Kraljevi Krškopoljc",
	rating: "4",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["pig"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>dry aged Krškopolje pork</li>
					<li>demi-glace sauce</li>
					<li>Parmesan Frico</li>
					<li>sauteed onions</li>
					<li>tomatoes</li>
					<li>rucola</li>
					<li>iceberg lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Phenomenal protein almost drowned out by the numerous ingredients.",
			content: `
				<p>I have a lot of criticism for this sandwich.</p>
				<p>Look at the list of ingredients. There is way too much going on in that sandwich. Not only that, but the proportions are unbalanced - there isn't enough meat compared to everything else. I managed to take several bites with nothing but veggies, bread, and sauce. That's right! I thought I was going to die.</p>
				<p>The meat I received was also cold which means that some of the fat was not properly rendered. I understand they probably didn't want to sear it so that the beautiful pink color of the dry aged pork could stand on its own. But there has to be good midway point somewhere, surely. Chewing on cold fat is quite unpleasant.</p>
				<p>To top it all off, it's very expensive. Which is something I can generally be quite tolerant of. But when the portion of the most expensive ingredient are so small and the portions of the inexpensive (and arguably unnecessary) ingredients so comparably generous, it hits different. These are marketed as burgers, after all. Not fine dining.</p>
				<p>Despite the rant, however, I still enjoyed the sandwich. The meat is so good it's hard to stay mad.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | September 2022"
		}
	]
}
