export default {
	title: "Maister Burger",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>horseradish, ketchup, and mayo sauce</li>
					<li>Cheddar cheese</li>
					<li>crispy smoked bacon</li>
					<li>caramelized onions</li>
					<li>lettuce</li>
					<li>tomatoes</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Approved.",
			content: `
				<p>No major flaws here, if you ask me.</p>
				<p>Some might raise an eyebrow at thee very sweet caramelized onions. Not me. I liked them.</p>
				<p>If I could fix one thing, I would add more cheese. But that's about it.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | May 2023"
		},
		{
			type: "image",
			url: require("./Maister Burger.jpg")
		}
	]
}
