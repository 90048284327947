export default {
	title: "Smashed Truffle Čiz",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>sesame bread bun</li>
					<li>double smashed beef patty</li>
					<li>ketchup</li>
					<li>mustard</li>
					<li>Cheddar cheese</li>
					<li>pickles</li>
					<li>onions</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The truffles were pretty good. I couldn't taste the rest of the burger.",
			content: `
				<p>I'm only exaggerating a little bit. While I do love me some truffles, that shit was way too overpowering. Definitely hard capped the final score.</p>
				<p>It was still a pretty great burger, all things considered. Despite it being smashed, it was surprisingly juicy, indicating a decent level of competence.</p>
				<p>If you are able to taste the rest of the ingredients and like this style of burger, might I also suggest <a href="/ranking/hood-burger/le-brie" class="reference">Le Brie</a> from <a href="/ranking/hood-burger/" class="reference">Hood Burger</a>.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | September 2021"
		},
		{
			type: "image",
			url: require("./Smashed Truffle Ciz.png")
		}
	]
}
