export default {
	title: "Classic Burger",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>grass-fed beef patty</li>
					<li>Hood sauce</li>
					<li>Cheddar cheese</li>
					<li>lettuce</li>
					<li>tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The benchmark.",
			content: `
				<p>This is it. This bad boy right here is the baseline by which I judge all the other (unwitting) participants on my list. It has the most standard ingredients possible yet it is executed so well.</p>
				<p>The main reason I'm calling this my benchmark, however, is not necessarily its quality alone but rather its price/performance ratio. There are better burgers on my list. But, frankly, many of them don't impress me nearly as much. It's (theoretically) easy to throw money at the problem and emerge with a better design. However, what distinguishes Hood from its rivals are constraints they operate under - a cheaper flagship of comparable quality you will not find.
				<p>And because of Hood's flexibility it can even easily be upgraded. Pro tip: if you want the best version of this burger, ask them to add some caramelized onions and another slice of Cheddar. Thank me later.</p>
				<p>Go check it out.</p>
				<p>No, seriously. Go. Right now.</p>
				<p><a href="https://www.youtube.com/watch?v=Udt5BUe0k9E" target="_blank" class="callback">WHY ARE YOU STILL HERE?!</a></p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | January 2023"
		},
		{
			type: "image",
			url: require("./Classic.jpg")
		}
	]
}
