import Bulldog from "./Bulldog/Bulldog.js"
import Classic from "./Classic/Classic.js"

export default {
	title: "Buldog Bar",
	tags: [],
	burgers: [
		Classic,
		Bulldog
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "http://www.bulldog.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/buldogbarLJ/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d16803318-Reviews-Bulldog_Bar-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
