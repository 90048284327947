export default {
	title: "Čiz Smash",
	rating: "3",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>double smashed beef patty</li>
					<li>ketchup</li>
					<li>mayonnaise</li>
					<li>Cheddar cheese</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A massive squandered opportunity.",
			content: `
				<p>This burger pisses me off. Let me explain.</p>
				<p>Most mediocre (or worse) burgers generally tend to fuck up on step one - the meat. The meat being bad is an almost impossible hurdle to overcome, even if you use the most delicious supporting ingredients.</p>
				<p>This burger, however, has an inverse problem.  The meet blend and Cheddar combination is fantastic. The reason everything unravels from there is the rest of the burger. The sauce combo is unbelievably basic and the buns look like they were stolen from McDonald's.</p>
				<p>Why? If you are able to overcome one of the seemingly biggest hurdles of the competition and nail the fundamentals, why not reinforce success? Make some luxurious buns. Whip up an interesting sauce. Lose the pickles.</p>				
				OK, fine. That last one was a personal request, I'll admit.</p>
				<p>Still. There's a four or more stars of potential in this. What a shame.</p>
				<p>If you are looking for a slightly better version of this, check out <a href="/ranking/omami-burgers/double-smashed-ciz" class="reference">Double Smashed Čiz</a> from <a href="/ranking/omami-burgers" class="reference">OMAMI Burgers</a> (if you can find it). For a more elevated smash burger, I highly recommend <a href="/rankinghood-burger/jakenjay" class="reference">Jake'n'jay</a> from <a href="/ranking/hood-burger" class="reference">Hood Burger</a>.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | August 2021"
		},
		{
			type: "image",
			url: require("./Ciz Smash.png")
		}
	]
}
