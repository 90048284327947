import RazzaPiemontese from "./Razza Piemontese/Razza Piemontese.js"
import RexBurger from "./Rex Burger/Rex Burger.js"

export default {
	title: "Rex Burger Pivo Sport",
	tags: [],
	burgers: [
		RazzaPiemontese,
		RexBurger
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://rex-burger.qread.eu/menu"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: ""
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
