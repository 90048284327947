import KubusBurger from "./Kubus Burger/Kubus Burger.js"

export default {
	title: "Kubus - Gostilna in Pizzerija",
	tags: [],
	burgers: [
		KubusBurger
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://gostilnakubus.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/kubusgostilnainpizzeria"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
