export default {
	title: "Chicken Burger",
	rating: "0.5",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["chicken"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>fried chicken fillet</li>
					<li>lettuce</li>
					<li>tomatoes</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "It takes innate talent and years of training to fuck up fried chicken this badly.",
			content: `
				<p>Not only is it labeled incorrectly - it's not a fucking burger IF IT HAS CHICKEN IN IT! - it is also easily the worst goddamn chicken sandwich I've ever had the displeasure of ingesting in my entire life. It is actually amazing just how much they managed to fuck this up. The chicken breast was thin and fucking dry. The mayo was just plain fucking bad. The salad was fucking wilted. And the tomatoes were not fully fucking ripe.</p>
				<p>This was a worse disaster than the Trump presidency. Sadly, not unlike the January 6 riots failed to overthrow the U.S. government, I fear my rant here will likewise fail to close the doors of the restaurant that produced this abomination. But one can always dream.</p>
				<p>Oh, and did I mention it's absurdly expensive?</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | May 2021"
		},
		{
			type: "image",
			url: require("./Chicken.jpg")
		}
	]
}
