export default {
	title: "Šefov Burger",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>thousand islands sauce</li>
					<li>double Cheddar cheese</li>
					<li>bacon</li>
					<li>onion marmalade</li>
					<li>rucola</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Generic mess.",
			content: `
				<p>Aight. I'm gonna make this short.</p>
				<p>Every individual item on the spec sheet is unexciting (onion marmalade not withstanding, of course). They also don't necessarily go together very well. The whole burger just seems a bit... off.</p>
				<p>The buns being a bit stale does not help.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | June 2022"
		},
		{
			type: "image",
			url: require("./Sefov.png")
		}
	]
}
