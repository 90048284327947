export default {
	title: "7 Burger",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>house sauce</li>
					<li>cheese</li>
					<li>iceberg lettuce</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Made in a hurry.",
			content: `
				<p>Lemme tell you, I had to really restrain myself here to not give this burger a rating of one star (or, you know, burger). Having ordered it at 7 Burger's stand at <a href="https://www.pivoinburgerfest.si/" class="reference">Pivo & Burger Fest</a>, I watched with dismay as they put the cheese on the top <span class="emphasized">bun</span> (instead of on the patty a minute before taking it off the grill, like God intended) then proceeded to chaotically throw other condiments on the bottom bun before hastily assembling this poor, sad little (well, actually decent sized) burger. The end result was a burger with half the lettuce having fallen out and the cheese - unmelted, of course - almost following suit. Of course, just my luck, the pickle remained firmly entrenched.</p>
				<p>Alas, the burger was actually on the edge of acceptable in terms of taste at the end of the day. Against all odds.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | October 2017"
		},
		{
			type: "image",
			url: require("./7 Burger.jpg")
		}
	]
}
