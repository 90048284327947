export default {
	title: "Louis Lunch",
	rating: "3",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>grilled buttered toast</li>
					<li>grass-fed beef patty</li>
					<li>Creme de Fromage cheese</li>
					<li>grilled onions</li>
					<li>tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The 1900s called. They want their burger back.",
			content: `
				<p>This burger is an homage to <a href="https://en.wikipedia.org/wiki/Louis%27_Lunch" class="reference">Louis' Lunch</a>, an eatery opened in 1895(!) that is famous for being recognized by The Library of Congress as the birthplace of the hamburger. Big shoes to fill, eh?</p>
				<p>I'm not going to pretend to know what the original tastes like. All I can say is that I was honestly not particularly impressed by this one. I don't know if it was because of the beef blend that Hood uses not pairing particularly well with toast or that I've just been spoiled by buns. But the fact is that it just doesn't measure up to its brethren on the same menu.</p>
				<p>Just to be clear: I'm not saying it's bad. Just not as good as Hood's other burgers.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | July 2023"
		},
		{
			type: "image",
			url: require("./Louis Lunch.jpg")
		}
	]
}
