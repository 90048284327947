export default {
	title: 'Popeye "Spinach" Burger',
	rating: "4",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>spinach bread bun</li>
					<li>Black Angus beef patty</li>
					<li>Chimichurri sauce</li>
					<li>white Cheddar cheese</li>
					<li>baby spinach</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries (during select hours)</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Why in the everliving fuck is this not on the regular menu?",
			content: `
				<p>This is my second favorite L&S burger (right after <a href="/ranking/lars-sven/blackadder-burger" class="reference">Blackadder</a>). That makes me sad because I've only ever seen it on the menu a few times which probably makes it a limited special edition. Why?</p>
				<p>I was once again surprised by the actually good sauce (which I'm not used to in L&S burgers, broadly speaking). I've never heard of Chimichurri sauce before. I thought it was some sort of exotic plant. Not at all, it turns out:</p>
				<p><span class="emphasized">"<a href="https://www.simplyrecipes.com/recipes/chimichurri/" class="reference" target="_blank">Argentinean chimichurri</a> is made with chopped fresh parsley, oregano, garlic, olive oil, vinegar, and red pepper flakes."</span></p>
				<p>For the purposes of this burger, they mixed it with some type of aioli. Brilliant.</p>
				<p>I should try this at home at some point...</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | 2021"
		},
		{
			type: "image",
			url: require("./Popeye Spinach.jpg")
		}
	]
}
