import CizSmash from "./Ciz Smash/Ciz Smash.js"

export default {
	title: "Birdie Foodielab",
	tags: [],
	burgers: [
		CizSmash
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/profile.php?id=100076104833258"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
