export default {
	title: "Chicago",
	rating: "4.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>beef patty</li>
					<li>bacon</li>
					<li>house sauce</li>
					<li>double Cheddar cheese</li>
					<li>fried onions</li>
					<li>lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Huh. Amazing burgers exist outside Ljubljana's borders. Who knew?",
			content: `
				<p>One of the best out there. Great blend, sautéed onions, ample cheese, superior sauce, and. Crispy. Fucking. Bacon. And relatively cheap!</p>
				<p>So why - ½ point? Because the establishment responsible for making this burger is located too far away from my house. Is that fair? Perhaps not. But tough shit. My website, my rules. Stay mad.</p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;"><strong>Warning:</strong> if you are considering Punkt's offerings at <a href="https://www.pivoinburgerfest.si/" target="_blank" class="callback">Pivo & Burger Fest</a>, do yourself a favor and don't. While their burgers were some of the best in class, this unfortunately no longer applies, at least not to Burgerfests. They have decided on a brilliant strategic move of prioritizing profits at the expense of their reputation and now produce only well done and super dry meat patties. Therefore, I can no longer recommend them at any venue outside their restaurant. Disregard the existing review/score in any other context.</div>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | September 2018"
		},
		{
			type: "image",
			url: require("./Chicago.jpg")
		}
	]
}
