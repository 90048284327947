export default {
	title: "HillBilly",
	rating: "2.5",
	tags: ["discontinued", "special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>	
					<li>beef patty</li>
					<li>truffle sauce</li>
					<li>herbal sour cream</li>
					<li>Brie cheese</li>
					<li>tomato</li>
					<li>rucola</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Whatever.",
			content: `
				<p>Eh... I dunno. It's not bad per se. But if you like this style of burger, skip this one. Go to Hood Burger and order <a href="/ranking/hood-burger/le-brie" class="reference">Le Brie</a> instead.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | June 2017"
		}
	]
}
