import Gurman from "./Gurman/Gurman.js"

export default {
	title: "Gostilnica in Pivnica Šiška",
	tags: ["closed"],
	burgers: [
		Gurman
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/GostilnicaInPivnicaSiska/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d9765898-Reviews-Gostilnica_in_pivnica_SISKA-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
