export default {
	title: "De La Boca",
	rating: "3.5",
	tags: ["special-edition"],
	dish_type: "sandwich",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>hoagie bread roll</li>
					<li>aged beef ribs</li>
					<li>salsa Chimichurri</li>
					<li>Queso Fresco cheese</li>
					<li>orange pickled onions</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Different. But good different.",
			content: `
				<p>On their website, the Hood refers to this as 'De La Boca <span class="emphasized">steak sandwich</span>'. So I'm honestly tempted to give it +½ point just for the proper label. Of course, I can't do that. It would violate the sacred trust between you and me, dear reader. But the sentiment stands.</p>
				<p>Thankfully, I don't need to artificially inflate the score. It's easily their best sandwich so far <span class="emphasized" style="font-size: 80%;">(Hi, yours truly from the future here; this claim no longer holds since the introduction of <a href="/ranking/hood-burger/chilli-cheese-steak-sandwich" class="reference">Chilli Cheese Steak Sandwich</a>; even so, this is still an excellent sandwich.)</span>. Well... it's also their second sandwich so far, so that's not really a high bar, I guess.</p>
				<p>It features a delightfully fresh flavour profile if you, like myself, sometimes find yourself tired of conventional burgers. The salsa adds some slight yet delightful spiciness and the pickled onions a gentle sour aftertaste. The meat and cheese complete the equation to create an excellent balance. I should note I received my meat absurdly salty the first time, however, I believe it to be a fluke since the subsequent servings were fine.<p>
				<p>Bottom line: good. Get it if available.</p>

			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | June 2021"
		},
		{
			type: "image",
			url: require("./De La Boca.jpg")
		}
	]
}
