export default {
	title: "Big Hood",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>grass-fed thin double beef patty</li>
					<li>Big Hood Sauce (mustard-based)</li>
					<li>Cheddar cheese</li>
					<li>iceberg lettuce</li>
					<li>pickles</li>
					<li>fresh onions</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Jake'n'Jay on steroids.",
			content: `
				<p>That's how the cashier who took my order described this creation, referring to <a href="/ranking/hood-burger/jakenjay" class="reference">this burger</a> from Hood's regular menu. After the first bite I was inclined to agree. By the end of the last one, not so much.</p>
				<p>That is not at all to say that this burger is bad in any way. In fact I found it to be refreshingly different while maintaining the signature Hood taste. My point is simply to say that the sauce, the fresh onions, and the pickles (which - shockingly - I actually found quite enjoyable on this burger) formed a burger that differs from J'n'J substantially and it therefore does not constitute an upgrade in my book (which is what the phrase "on steroids" implies). In my opinion it's more of a sidegrade.</p>
				<p>If you're a bit tired of Hood's regular sauces and one based on mustard sounds appealing to you, I heartily recommend it if you see it on the menu.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | August 2019"
		},
		{
			type: "image",
			url: require("./Big Hood.jpg")
		}
	]
}
