export default {
	title: "Mejp'l Burger",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>butter bun</li>
					<li>Black Angus beef batty</li>
					<li>house maple sauce</li>
					<li>Brie cheese</li>
					<li>white Cheddar cheese</li>
					<li>maple candied bacon</li>
					<li>baby spinach</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Canada would be proud.",
			content: `
				<p>The use of maple syrup is certainly original, at least in our neck of the woods. Surprisingly, it works really well. Especially with the other chosen ingredients.</p>
				<p>It also helps that the person working the grill clearly knows their craft. That bacon was fantastic. Just the right balance of chewy and crunchy. A rare find, sadly.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | September 2022"
		},
		{
			type: "image",
			url: require("./Mejp'l.png")
		}
	]
}
