export default {
	title: "The Cuban Sandwich",
	rating: "1.5",
	tags: ["special-edition"],
	dish_type: "sandwich",
	meat_types: ["pig"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>hoagie bread roll</li>
					<li>pulled pork (marinated in orange, lime, garlic, origano, cumin, pepper, paprika)</li>
					<li>boiled ham</li>
					<li>pickles</li>
					<li>mustard</li>
					<li>Cheddar cheese</li>
					<li>sauce (with lemon, orange, mint)</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "That's gonna be a thumbs down for me dawg.",
			content: `
				<p>It's probable this is entirely because of my bias, however the the orange-colored flavor really didn't agree with me. If that sounds OK to you though, ignore this review.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | December 2019"
		},
		{
			type: "image",
			url: require("./The Cuban Sandwich.jpg")
		}
	]
}
