export default {
	title: "Original California Classic",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>beef patty</li>
					<li>thousand islands sauce</li>
					<li>Cheddar cheese</li>
					<li>lettuce</li>
					<li>tomato</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "I'm not a big fan of pickles but whatever - it's still Pop's.",
			content: `
				<p>This may be a hot take but I'm willing to bet that if you built a burger with nothing but Pop's patty and bun and nothing else, it would still probably be an above average burger on this list.</p>
				<p>In fact, the only thing that <span class="emphasized">can</span> ruin a Pop's burger, I find, is indeed the choice of ill-considered ingredients. As you can deduce from the lower rated Pop's burgers on this site.</p>
				<p>In this specific case, however, not much is done incorrectly. I am forced to remove ½ a point because of the pickle but everything else is pretty much on point.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | March 2021"
		},
		{
			type: "image",
			url: require("./Original California Classic.jpg")
		}
	]
}
