export default {
	title: "Lord",
	rating: "4.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>beef patty</li>
					<li>house BBQ sauce</li>
					<li>Cheddar cheese</li>
					<li>red onions</li>
					<li>tomato</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A leap of faith with no regrets.",
			content: `
				<p>This burger was... 'marketed' to me <span class="emphasized">very</span> aggressively by my former co-workers. I knew their tastes enough to trust it must indeed be solid. Still, I had some minor reservations.</p>
				<p>Which were entirely and rapidly extinguished after the first bite. This burger is a masterpiece, plain and simple.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018"
		},
		{
			type: "image",
			url: require("./Lord.jpg")
		}
	]
}
