export default {
	title: "d'Anger",
	rating: "3.5",
	tags: ["discontinued"],
	dish_type: "sandwich",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>sesame bread bun</li>
					<li>Black Angus tri-tip</li>
					<li>"Mišel" sauce</li>
					<li>Cheddar cheese</li>
					<li>rucola</li>
					<li>tomatoes</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A lovely twist to the sometimes uninspired steak sandwiches.",
			content: `
				<p>We have a third one, boys and girls. Another joint that managed to pull off a beef steak sandwich. Not just that, it's different enough to really stand out from the competition. Props.</p>
				<p>But of course, nothing is ever black and white. And there is a dark side to this sandwich that will shock you to the very core: they misspelled "tri-tip" on the spec sheet. It says "tri tip". I know. I'm sorry you had to see this. But I feel it is my duty not to shield you from the truth.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		}
	]
}
