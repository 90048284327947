export default {
	title: "Burger Foie Gras",
	rating: "4",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>Black Angus beef patty</li>
					<li>BBQ sauce</li>
					<li>cheese</li>
					<li>Foie gras</li>
					<li>quail egg</li>
					<li>caramelized black onions</li>
					<li>young salad</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "This is not a burger. This is a fucking experience.",
			content: `
				<p>That being said, I'm kind of torn on it. It's undeniably a great dish made with very high-quality ingredients (as the price only a dictator of an oil-rich nation wouldn't think twice about clearly suggests), but it's not exactly what I would expect out of a burger. I know that's not really a fair criticism - and I've pushed that aside when evaluating - but it may be something to keep in mind.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018 (Uncertain)"
		}
	]
}
