export default {
	title: "Bulldog Burger",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>	
					<li>beef patty</li>
					<li>bacon</li>
					<li>house sauce</li>
					<li>cheese</li>
					<li>red onions</li>
					<li>rucola</li>
					<li>tomato</li>
					<li>egg</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Is a flagship that blends in with the other ships still a flagship?",
			content: `
				<p>Very respectable flagship with a side of fries, all for a reasonable price. Recommended.</p>
				<p>I do have a gripe though. While I'm generally a fan of eggs in burgers, I'm somewhat ambivalent about the egg in this one. It didn't add anything in my opinion. But nor did it distract. So... I dunno. I spoke about this a bit in the review of <a href="/ranking/buldog-bar/classic-burger" class="reference">Classic Burger</a> as well. They are a bit too close in taste. Which should not be the case.</p>
				<p>I suppose I should order both at some point and compare. If anyone's interested in helping me, hit me up.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		},
		{
			type: "image",
			url: require("./Buldog.jpg")
		}
	]
}
