export default {
	title: "Flying Chicken Burger",
	rating: "3",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["chicken"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>chicken fillet</li>
					<li>dressing</li>
					<li>truffles</li>
					<li>lettuce</li>
					<li>onions</li>
					<li>tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Good enough.",
			content: `
				<p>Other than this not being a burger, I have no major complaints. I suppose it could always be improved by supplanting chicken with beef and adding bacon, but then isn't that true of all sandwiches? And besides, sometimes one requires something a bit... lighter. And that is just the use case this is ideal for.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | August 2016"
		},
		{
			type: "image",
			url: require("./Flying Chicken.jpg")
		}
	]
}
