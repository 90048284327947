export default {
	title: "Prasica",
	rating: "4.5",
	tags: ["special-edition"],
	dish_type: "sandwich",
	meat_types: ["pig"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>beef patty</li>
					<li>mayonnaise sauce</li>
					<li>hot sauce</li>
					<li>Cheddar cheese</li>
					<li>carrots</li>
					<li>radish</li>
					<li>baby spinach</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Fascinatingly different.",
			content: `
				<p>True to their brand, Meat Business continues to amaze. Right off the bat, this is the best ground pork patty sandwich I've ever had. But it's the reason behind it that's interesting.</p>
				<p>Admittedly, there aren't many ground pork-based "burgers" I'm aware of - I've had two so far (funnily enough, they were both named "Smokey"). One of them was dreadfully bland. The other one thankfully wasn't but looking back at it with the benefit of hindsight, it didn't really push any boundaries.
				<p>Not so with Prasica. They decided to go all the way with the porkiness - the patty is seasoned like a pork sausage. Like a version of bratwurst. And it's juicy, too. I imagine ground pork must be cooked more than beef but the guys at Meat Business seem to have thought of this - they added some extra fat to compensate. Super!</p>
				<p>It isn't all rainbows and sunshine, however.</p>
				<p>A dark cloud hangs above this dish. Meat Business have an even better product in their arsenal: <a href="/ranking/meat-business/stara-krava" class="reference">Stara Krava</a>. A beef burger (which is to say a <em>real</em> burger). Beef burgers are just plain better than any imitators. And I just can't bring myself to recommend Prasica as long as Stara Krava remains available.</p>
				<p>Fortunately, an elegant solution exists: buy both.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | April 2023"
		}
	]
}
