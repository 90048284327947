export default {
	title: "Pulled Pork Burger",
	rating: "4.5",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["pig"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>pulled pork shoulder</li>
					<li>house Tartar sauce</li>
					<li>pickled onions</li>
					<li>baby spinach</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Find a better pulled pork sandwich. I dare you. I double dare you, motherfucker!",
			content: `
				<p>Trappa actually started - and is still primarily - a pizzeria (they make outstanding Neapolitan pizzas, check them out sometime). That being the case, it is actually insane that they managed to casually produce one of the best pulled pork sandwiches I've ever eaten. It stands as an embarrassment to some establishments I won't be naming here who fancy themselves burger joints or even steakhouses who can't even begin to compare. And it's like 50% cheaper than the competition. Frankly, the only real problem with this dish is that they called it a <span class="emphasized">burger</span>. No, I will never stop pointing this out.</p>
				<p>As for the substantive review portion, shout out to pickled onions. I was skeptical at first but the gentle acidity perfectly balances out the strong flavour of pulled pork.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | July 2021"
		},
		{
			type: "image",
			url: require("./Pulled Pork.jpg")
		}
	]
}
