export default {
	title: "Rex Burger",
	rating: "2",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>Slovenian beef</li>
					<li>house tomato Chutney sauce</li>
					<li>caramelized red onions</li>
					<li>iceberg lettuce</li>
					<li>Grapolo tomatoes</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries with BBQ sauce</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A ripoff.",
			content: `
				<p>Review: I won't sugarcoat it: this burger is basically a scam. It was one of the saddest burgers I've ever seen, as if it was thrown together with no care whatsoever.</p>
				<p>Nor were the ingredients anything to gush over. The meat was super average and somewhat bland, the sauce was at best OK, and the caramelized onions were so few and far between I initially wondered if they were there at all.</p>
				<p>Not that the price reflected that, mind you. For what it was, I found it extremely expensive.</p>
				<p>Thumbs down on this one, chief.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | April 2022"
		},
		{
			type: "image",
			url: require("./Rex Burger.png")
		}
	]
}
