export default {
	title: "Holesterol",
	rating: "4.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>cream sauce</li>
					<li>horseradish mayo</li>
					<li>Gorgonzola cheese</li>
					<li>smoked bacon</li>
					<li>onion marmalade</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "This artery clogging lookin' motherfucker lives up to its name.",
			content: `
				<p>This was the best burger at <a href="https://www.pivoinburgerfest.si/" target="_blank" class="callback">Pivo & Burger fest</a> that year. I honestly can't think of ways to improve it. You could try adding veggies to balance it out but that would defeat the purpose. It's named that way for a reason.</p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;"><strong>Warning:</strong> if you are considering Stari Pisker's offerings at <a href="https://www.pivoinburgerfest.si/" target="_blank" class="callback">Pivo & Burger Fest</a>, do yourself a favor and don't. While their burgers were some of the best in class, this unfortunately no longer applies, at least not to Burgerfests. They have decided on a brilliant strategic move of prioritizing profits at the expense of their reputation and now produce only well done and super dry meat patties. Therefore, I can no longer recommend them at any venue outside their restaurant. Disregard the existing review/score in any other context.</div>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | April 2016 (Uncertain)"
		},
		{
			type: "image",
			url: require("./Holesterol.jpg")
		}
	]
}
