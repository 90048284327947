import Cheeseburger from "./Cheeseburger/Cheeseburger.js"
import Chicken from "./Chicken/Chicken.js"

export default {
	title: "Svetilnik",
	tags: [],
	burgers: [
		Cheeseburger,
		Chicken
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "http://www.svetilnik.eu/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/SvetilnikLjubljana/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d14960269-Reviews-Svetilnik-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
