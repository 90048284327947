export default {
	title: "Kraški",
	rating: "3",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>potato bread bun</li>
					<li>beef patty</li>
					<li>sauce with olives</li>
					<li>goat cheese</li>
					<li>crispy prosciutto</li>
					<li>onions in Teran</li>
					<li>lettuce</li>
					<li>tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Look at the name. Look at the specs. They really leaned into the theme here, didn't they?",
			content: `
				<p>After trying their <a href="/ranking/punkt/chicago" class="reference">Chicago</a> burger, I had really hoped all Punkt's burgers would be amazing. Having eaten this burger, I am sorry to say that is not the case.</p>
				<p>Make no mistake though. This is a solid entry. And I'm guessing - having only eaten this at Burgerfest - it's even better at their joint. Looking over the ingredients again, they seem too... ambitious to be sold from a stand. So depending on where you're ordering this from, you may wanna skip it.</p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;"><strong>Warning:</strong> if you are considering Punkt's offerings at <a href="https://www.pivoinburgerfest.si/" target="_blank" class="callback">Pivo & Burger Fest</a>, do yourself a favor and don't. While their burgers were some of the best in class, this unfortunately no longer applies, at least not to Burgerfests. They have decided on a brilliant strategic move of prioritizing profits at the expense of their reputation and now produce only well done and super dry meat patties. Therefore, I can no longer recommend them at any venue outside their restaurant. Disregard the existing review/score in any other context.</div>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | September 2018 (Uncertain)"
		},
		{
			type: "image",
			url: require("./Kraski.jpg")
		}
	]
}
