export default {
	title: "Chicken Grill Burger",
	rating: "3.5",
	tags: ["discontinued"],
	dish_type: "sandwich",
	meat_types: ["chicken"],
	sections: [
		{
			type: "html",
			title: "Review",
			lede: "Excellent chicken sandwich with too many sauces.",
			content: `
				<p>This is a very, very good chicken <span class="emphasized">sandwich</span>. It is not a burger, because its main ingredient is <span class="emphasized">chicken</span>. Are we clear? Good. Because it's listed under <span class="emphasized">burgers</span>. That ain't right.</p>
				<p>You should still give it a try if you're in the area though. The portions are sizeable and it's very tasty. I used to eat this once a week at a minimum while going to school in Kranj. I never got tired of it.</p>
				<p>Pro tip: skip the ketchup. Too overpowering. And besides, there are <span class="emphasized">two other sauces</span> in this thing. Enough is enough.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2015"
		}
	]
}
