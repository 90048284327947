export default {
	title: "4UM Burger",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>mustard sauce</li>
					<li>Parmesan cheese</li>
					<li>onion jam</li>
					<li>rucola</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Unwelcome downgrade.",
			content: `
				<p>I've (famously) discussed this before in the review of Hood Burger's <a href="/ranking/hood-burger/balbo-2022" class="reference">Balbo [2022]</a>: I <span class="emphasized">hate</span> silent revisions. If you change a longstanding burger's ingredients, for the love of Allah, tell me about it! There's few things I hate more than surprises like this.</p>
				<p>Actually, I just thought of one: I hate when the revision is actually worse than <a href="/ranking/forum/4um-burger-mk-i" class="reference">the original</a>. Surprise!</p>
				<p>The meat blend in this burger was unimpressive and chewy. That's new. Everything else balances out in the worst way possible. The onion jam elevates the experience slightly only for the ill-chosen cheese and the sauce to swiftly revert it back to the baseline.
				<p>Splendid showing there, Forum.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | May 2022"
		},
		{
			type: "image",
			url: require("./4UM Burger.png")
		}
	]
}
