export default {
	title: "Fatboy Slim",
	rating: "3",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>grass-fed beef patty</li>
					<li>peanut butter, mayo, and honey sauce</li>
					<li>Cheddar cheese</li>
					<li>pancetta</li>
					<li>crisply pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Peanut butter? Huh.",
			content: `
				<p>When I read the words "peanut butter" and "pickles" in the same spec sheet, I was all but convinced that someone in Hood's R&D labs has gone insane.</p>
				<p>While that may indeed be the case (I'm still investigating), this burger was - all things considered - a relatively pleasant surprise. Not as pleasant as some other Hood Specials, but still.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | April 2019"
		},
		{
			type: "image",
			url: require("./Fatboy Slim.jpg")
		}
	]
}
