import HillBilly from "./HillBilly/HillBilly.js"

export default {
	title: "HillBilly's",
	tags: ["closed"],
	burgers: [
		HillBilly
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: ""
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
