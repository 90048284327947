export default {
	title: "Boss Burger",
	rating: "2.5",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Review",
			lede: "A very impressive-sounding spec sheet left a lot to be desired.",
			content: `
				<p>The burger was fairly bland. Does not live up to the name.</p>
				<p>For the one true Jefe on this list, check <a href="/ranking/pops-place/the-boss" class="reference">The Boss</a> from Pop's Place.</p>
				<span class="emphasized" style="font-size: 80%; display: block; margin: 14px 0;">Hi, yours truly from the future here; the truly observant among you may have noticed that this burger is missing a spec sheet. Which makes this statement extra confusing. Well, you see, this mini-review was written in the very earliest days of this project. At the time I was not recording the ingredients. A mistake in retrospect but I did not know at the time how expansive this site was going to become. Regardless, I'm leaving the review as-is as I can't remember what this burger was like well enough to rewrite it.</span>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | 2016"
		}
	]
}
