export default {
	title: "Pandolin",
	rating: "4",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>beef patty</li>
					<li>beet mayonnaise with horseradish</li>
					<li>smoked ketchup with mustard seeds</li>
					<li>Cheddar cheese</li>
					<li>iceberg lettuce</li>
					<li>tomatoes</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "If you're tired of conventional burgers, try this. It's an interesting departure.",
			content: `
				<p>There was supposed to be this fancy lettuce inside (looks like frisée in the picture maybe?). Instead it was a regular ol' iceberg. Lies and deceit!</p>
				<p>Still. This was excellent.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | January 2022"
		},
		{
			type: "image",
			url: require("./Pandolin.png")
		}
	]
}
