export default {
	title: "The Godfather Burger",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>dry aged beef patty</li>
					<li>house BBQ sauce</li>
					<li>mustard</li>
					<li>Nacho cheese</li>
					<li>crispy bacon</li>
					<li>fried egg</li>
					<li>tomato</li>
					<li>lettuce</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "How much is too much?",
			content: `
				<p>I'm going to say something extremely controversial: this burger is too big.</p>
				<p>Before I'm flogged for blasphemy, permit me to clarify. The burgers in this joint come with two patties <span class="emphasized">by default</span>. In fairness, this one - listed under 'special' burgers - only came with one, however it was so thick that it was effectively the same by volume to the other burgers with two. Now, there's nothing wrong with a double-decker from time to time. I perfectly understand the desire, nay need for hedonism. But you should offer a more humble option.</p>
				<p>Otherwise solid. Could use a pickle-reduction surgery though.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		}
	]
}
