export default {
	title: "The Duke",
	rating: "2",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>grass-fed beef patty</li>
					<li>pulled duck</li>
					<li>Asiago cheese</li>
					<li>red celery</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A lack of commitment resulted in a weird chimera.",
			content: `
				<p>The duck was pretty good, I can't deny that. I'm just disappointed they didn't fully commit and simply ditch the beef. I would have preferred a pulled duck sandwich over a duck & beef burger. Or, on the flip side, just a burger. But not both. Thy simply clash too much.</p>
				<hr style="margin-top: 20px; opacity: 0.5;" />
				<div style="font-size: 80%; line-height: 15px;">This is the 1st version of The Duke. Annoyingly, Hood has opted for a stupidly confusing naming scheme for this dish. See my editorial <a href="/editorial/a-royal-mess" class="reference">'A Royal Mess'</a> and <a href="/editorial/a-royal-mess-2" class="reference">'A Royal Mess 2: The Reckoning'</a> for more information on this topic.</div>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | November 2017"
		},
		{
			type: "image",
			url: require("./The Duke 2017.jpg")
		}
	]
}
