export default {
	title: "Frks Burger",
	rating: "2",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Review",
			lede: "Meh.",
			content: `
				<p>Meh.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2016"
		},
		{
			type: "image",
			url: require("./Frks.jpg")
		}
	]
}
