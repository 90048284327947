export default {
	title: "d'Klasik",
	rating: "3",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>sesame bread bun</li>
					<li>beef patty</li>
					<li>house sauce</li>
					<li>Cheddar cheese</li>
					<li>tomato</li>
					<li>lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Inoffensive. But that's as high as my praise goes.",
			content: `
				<p>It fails to be excite or stand out in any way. Especially when you consider that you get the far superior <a href="/ranking/dburger/dslaninc" class="reference">d'Slaninc</a></span> for only slightly more money.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		},
		{
			type: "image",
			url: require("./d'Klasik.png")
		}
	]
}
