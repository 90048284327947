export default {
	title: "Cankarjev Burger",
	rating: "1",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["pig"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>smoked pork belly</li>
					<li>mayonnaise sauce</li>
					<li>mustard with honey</li>
					<li>Brie cheese</li>
					<li>marinated cabbage</li>
					<li>caramelized onions</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Leave it to Romansa to fuck up a spec sheet this promising.",
			content: `
				<p>My criticism of this burger is a bit more personal in nature than with the others. I can't quite pinpoint precisely what offended my taste buds but I think it was the mustard sauce. I normally like those on burgers but this one was simply no bueno. It overpowered almost everything else. I couldn't even finish it. I'm not going to shit on it too hard for this reason but if your tastes are similar to mine, you should probably avoid it too. If for no other reason then how insanely overpriced it is.</p>
				<p>Incidentally, for a better version of a similar concept, try Trappa's <span class="reference">Porchetta</span>.</p>
				<p><span class="emphasized" style="font-weight: bold">A note from the future:</span><br />Aight, gloves off. Since my initial review of this bullshit, Porchetta has been discontinued. Which means this is now the only pork belly sandwich still available (that I'm aware off). It's a horrible representation of the genre. It needs to go.</p>
				<p>I will be filing a lawsuit to suppress its production. I'll let you know how it goes.</p>
				<p><span class="emphasized" style="font-weight: bold">A note from the future #2:</span><br />The judge laughed me out of the room. The system is rigged.</p>

			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | May 2021"
		},
		{
			type: "image",
			url: require("./Cankarjev.jpg")
		}
	]
}
