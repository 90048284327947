export default {
	title: "Black Angus Burger",
	rating: "4.5",
	tags: ["discontinued", "special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>Black Angus beef patty</li>
					<li>black pepper aioli</li>
					<li>Cheddar cheese</li>
					<li>red onion jam</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "My biggest regret.",
			content: `
				<p>The avid perusers of this website probably noticed that I tend not to substantially change my reviews often. Unless a burger I've previously reviewed receives an overhaul or the quality of its ingredients changes, I tend not to touch my old reviews. I like to leave them up as a sort of snapshot in time. This is also the reason I leave the discontinued burgers - and their reviews - alone.</p>
				<p>Having said that, this is a bit of a special case. I shall explain.</p>
				<p>I have seen Tokyo Piknik often at <a href="https://www.pivoinburgerfest.si/" target="_blank" class="callback">Pivo & Burger Fest</a> over the years. However, glancing at their menu, spotting their <a href="/ranking/tokyo-piknik/gourmet-burger" class="reference">Gourmet</a> burger and noticing <span class="emphasized">beetroot</span> on the spec sheet I initially dismissed them as some eccentric Asian fusion joint. However, at some point I caved and ordered this burger (since it had no eyebrow-raising ingredients).</p>
				This burger gives <a href="/ranking/pops-place" class="reference">Pop's Place's</a> offerings a run for their money - which is just about the highest praise I can bestow without venturing into blasphemous territory. The meat was basically perfect, the aioli and the onion jam complementing it perfectly.</p>
				<p>I tend to write these reviews on a bit of a delay. And since this burger was so delicious - best new burger I ate at the event, if not that year - I wanted to give it another shot at the actual restaurant. I therefore went to Tokyo Piknik to do just that. When it came time to order, I received some of the most soul crushing news of my life: Tokyo Piknik has stopped selling burgers in order to focus on Japanese dishes (as their name implied).</p>
				<p>Needless to say, this was the worst news anyone has received in 2019. And it haunts me to this day.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | September 2019"
		}
	]
}
