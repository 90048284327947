export default {
	title: "Burger",
	rating: "0.5",
	tags: ["special-edition"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>house sauce</li>
					<li>lettuce</li>
					<li>tomato</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The best part of that burger was the leaf of lettuce.",
			content: `
				<p>In order to gain access to this meal, certain conditions have to be satisfied.</p>
				<p>First (and most importantly), you need to gain employment at the Slovenian Postal Service's main logistics centre in Ljubljana. Second, you need to wait until it shows up on the employee cafeteria's menu. Third, you need to go to lunch early, because there is a limited supply most of the time.</p>
				<p>As to whether or not that is worth (potentially) weeks of effort... well, you read the intro.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2017"
		}
	]
}
