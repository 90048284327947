export default {
	title: "Bilijarder",
	rating: "2",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>BBQ sauce</li>
					<li>Cheddar cheese</li>
					<li>crunchy smoked bacon</li>
					<li>crunchy lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Yeah... No.",
			content: `
				<p>"Bitter disappointment" is how I would describe this burger in a nutshell. A friend and I visited the joint and ordered this burger based off of a picture on Facebook. Let's just say that the real thing does not do it justice.</p>
				<p>I mean that in a negative way.</p>
				<p>You may think me naive for falling for a promotional picture (again). That is your prerogative. However, allow me to point out that there are many better joints where they take honest photos. I guess Biljardna Hiša has opted not to operate that way.</p>
				<p>But OK, we're professionals here (heh), let's evaluate this devoid of first impressions.</p>
				<p>The burger is edible I guess, the meat and cheese taste very generic but the barbecue sauce picks up some of the slack.</p>
				<p>The thing is though, this burger costs as much or more than most 4+ star burgers on this list. So... you know. No.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		},
		{
			type: "image",
			url: require("./Bilijarder.jpg")
		}
	]
}
