import Cankarjev from "./Cankarjev/Cankarjev.js"
import Maxim from "./Maxim/Maxim.js"
import Republika from "./Republika/Republika.js"

export default {
	title: "Romansa 1971",
	tags: [],
	url: "https://www.maxi.si/sl/maxi-gourmet/romansa-1971/",
	burgers: [
		Republika,
		Cankarjev,
		Maxim
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.maxi.si/sl/maxi-gourmet/romansa-1971/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/profile.php?id=1991938157540195"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d12499319-Reviews-Restavracija_Romansa_1971-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
