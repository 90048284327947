import HisaPodGradom from "./Hisa Pod Gradom/Hisa Pod Gradom.js"

export default {
	title: "Hiša Pod Gradom",
	tags: [],
	burgers: [
		HisaPodGradom
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.hisapodgradom.si/gostilna/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/RestaurantHisaPodGradom/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d5508614-Reviews-Restaurant_Hisa_Pod_Gradom-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
