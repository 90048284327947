export default {
	title: "Republika Burger",
	rating: "1.5",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>pulled beef</li>
					<li>mayonnaise sauce</li>
					<li>BBQ sauce</li>
					<li>Cheddar cheese</li>
					<li>cream cheese</li>
					<li>fried onion rings</li>
					<li>rucola</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Never trust the marketing material.",
			content: `
				<p>This is one of those <span class="emphasized">sandwiches</span> whose image on the internet convinced me to try it. And just like in most of those cases, reality failed to live to the expectation in spectacular ways.</p>
				<p>Before I lean into my criticism, let me say that this burger did not necessarily taste too bad. But this is as high a praise as I can give. Starting off, the burger looked three times thinner than the images. This was somewhat expected as I ordered it on Wolt but I still expected more. The onion rings in it were very sad. There was no crispiness to them whatsoever. And before anyone cries foul - I ordered Pop's <span class="reference">Silverton</span> on Wolt in the past and received crispy onions. So I know it can be done. But by far the most disappointing aspect - and the main reason for the score - was the ridiculous meat quantity. I'm only slightly exaggerating when I say the layer of Cheddar was about as thick as the actual beef patty. That is simply not acceptable. Especially not at their prices. Never again.</p>
				<p>If you would like a roughly similarly configured but far superior build, try Texas BBQ Ljubljana's <span class="reference">Vič King</span>.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | May 2021"
		},
		{
			type: "image",
			url: require("./Republika.jpg")
		}
	]
}
