import TheDarkVader from "./The Dark Vader/The Dark Vader.js"
import TheGodfather from "./The Godfather/The Godfather.js"

export default {
	title: "Brunch",
	tags: [],
	burgers: [
		TheGodfather,
		TheDarkVader
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "http://www.brunch.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/Brunch.si/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d6156697-Reviews-Brunch-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
