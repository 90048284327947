export default {
	title: "Rog Burger",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>ketchup</li>
					<li>mustard</li>
					<li>Cheddar cheese</li>
					<li>crispy bacon</li>
					<li>sautéed onions</li>
					<li>iceberg lettuce</li>
					<li>"Lušt" tomatoes</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "I'm running out of ways to say 'average'.",
			content: `
				<p>It's one of those newfangled copycats. You taste one, you've tasted them all.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | August 2021"
		},
		{
			type: "image",
			url: require("./Rog.jpg")
		}
	]
}
