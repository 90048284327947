export default {
	title: "Cheeseburger",
	rating: "3",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>cheese</li>
					<li>ketchup*</li>
					<li>mayo*</li>
					<li>tomatoes*</li>
					<li>onions*</li>
					<li>lettuce*</li>
					<li>cabbage*</li>
				</ul>
				<div class="extras"><em>*Optional</em></div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Punching way above its weight class.",
			content: `
				<p>Let's not mince words here. This is a burger from an old(ish) school fast food place. A kebab joint, primarily. Not exactly one of those gastro pubs that have been cropping up over the past few years.</p>
				<p>So how the fuck does it blow most of those places out of the water?</p>
				<p>Make no mistake: this burger is never going to compete in the higher leagues. But it was never meant to. It knows what it is and it's good enough to make you not hungry.</p>
				<p>Which is actually a far higher praise than it sounds. There are some burgers on this list where, if offered to me to save my life, I might consider starving to death. This one is far from that.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | May 2022"
		}
	]
}
