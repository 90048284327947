export default {
	title: "TopChef",
	rating: "1.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>sauce</li>
					<li>Cheddar cheese</li>
					<li>caramelized onions</li>
					<li>apple slice</li>
					<li>lettuce</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Fail.",
			content: `
				<p>The apple slice inside does more harm than good. If you're after that kind of taste, ignore this and go straight to Pop's Place's <a href="/ranking/pops-place/pops-original-burger" class="reference">Pop's Original</a> or As Aperitivo's <a href="/ranking/as-aperitivo/boss" class="reference">Boss</a>. Don't waste your money on this.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | 2020"
		},
		{
			type: "image",
			url: require("./TopChef.jpg")
		}
	]
}
