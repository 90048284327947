export default {
	title: "Kubus Burger",
	rating: "3",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>sweet chilli sauce</li>
					<li>Cheddar cheese</li>
					<li>caramelized onions</li>
					<li>lettuce</li>
					<li>tomatoes</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries with house sauce</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "It feels like I've eaten this burger countless times. At other joints.",
			content: `
				<p>Yep. We've got another one. Another lazy, uninspired burger that just copies what already exists with no differentiation whatsoever.</p>
				<p>Well, to be fair, it's <span class="emphasized">slightly</span> above those other ones in that the meat patty isn't actually chewy. That's something at least. +½ point for that I guess. It's also fairly big so it won't leave you hungry.</p>
				<p>Then again, it's also expensive.</p>
				<p>At the end of the day, it's hard to recommend.</p>
				<p>If you find yourself in the area, might I suggest going to the closeby Lars & Sven instead.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | January 2022"
		},
		{
			type: "image",
			url: require("./Kubus Burger.png")
		}
	]
}
