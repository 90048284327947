export default {
	title: "Country Burger",
	rating: "2",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>	
					<li>beef patty</li>
					<li>horseradish and mustard sauce</li>
					<li>coleslaw cabbage</li>
					<li>onions</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Where does preference end and bias begin?",
			content: `
				<p>This is a question I've been asking myself after I finished eating. I ultimately came to the conclusion that it doesn't matter. I still hate this burger.</p>
				The coleslaw, horseradish in the sauce, and the raw onion created a really aggressive taste that practically drowned out the meat.</p>
				<p>And don't even get me started on the pickles.</p>
				<p>I would skip this one, especially since F&M offer some much better options.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | May 2022"
		},
		{
			type: "image",
			url: require("./Country.png")
		}
	]
}
