import TretjaSmena from "./Tretja Smena/Tretja Smena.js"
import Direktorski from "./Direktorski/Direktorski.js"

export default {
	title: "Stara Fabrka",
	tags: [],
	burgers: [
		Direktorski,
		TretjaSmena
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://starafabrka.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/starafabrka/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g1238226-d12822846-Reviews-Stara_fabrka-Domzale_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
