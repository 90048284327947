export default {
	title: "Prestiž",
	rating: "3",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>Black Angus steak slices</li>
					<li>truffle mayo</li>
					<li>BBQ sauce</li>
					<li>Brie cheese</li>
					<li>melted butter</li>
					<li>onion marmalade</li>
					<li>rucola</li>
					<li>tomatoes</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: 'When the marketing people came up with "Buy one, get one free," this is not what they were talking about.',
			content: `
				<p>Look. This burger's good, alright? Almost great. But that's as high as my praise goes. There's way too much happening here. You could unironically split the ingredients down the line and end up with two better products - a mayo/brie/rucola/tomato steak sandwich and a BBQ sauce/onion marmelade/butter burger (or something along these lines). That's just stupid.</p>
				<p>In conclusion: I don't not recommend this burger.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		},
		{
			type: "image",
			url: require("./Prestiz.jpg")
		}
	]
}
