export default {
	title: "Bacon Cheese Burger",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>beef patty</li>
					<li>thousand islands sauce</li>
					<li>Cheddar cheese</li>
					<li>smoked pancetta</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Bacon AND cheese? WHAT SORCERY IS THIS?",
			content: `
				<p>Make no mistake: this is stil a fan-fucking-tastic burger. It is extremely difficult to fuck up a Pop's burger, after all. I will note, however, that there is one potential flaw to this burger the: choice of pancetta.</p>
				<p>Don't get me wrong. Pancetta as such is one of the greatest gifts God has ever bestowed upon his children. But in this burger it's just ever so slightly overpowering. Specifically, it's too salty. It overpowers the taste of beef to a far greater extent than regular bacon ever could. But hey - it's smoked. Fucking. Pancetta. Hard to complain too much.</p>	
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | September 2019"
		},
		{
			type: "image",
			url: require("./Bacon Cheese.jpg")
		}
	]
}
