export default {
	title: "Chilli Cheese Steak Sandwich",
	rating: "4",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>hoagie bread roll</li>
					<li>aged beef rump steak slices</li>
					<li>Cheddar cheese</li>
					<li>grilled onions</li>
					<li>jalapeños</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Not even jalapeño could ruin this for me.",
			content: `
				<p>Not gonna lie. I'm quite averse to spiciness. Not my thing. Still, this thing is damn good. And labeled correctly as a sandwich, not a burger, so extra points for that.</p>
				<p>If you don't mind the aggressiveness of this, I would easily recommend it over <a href="/ranking/hood-burger/hood-steak" class="reference">Hood Steak</a>. Come to think of it, I would recommend it even if you don't like jalapeños - just order it without them. The reasoning comes down to the onions. Because of the seasoning used, I think Cheddar goes better with them than Provolone, in my opinion.</p>
				</p>Actually, here's a bit of a pro tip for ya: order this with the cheese doubled. Sure, the balance is thrown off slightly, but I mean, come on... Double. Cheese.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | September 2021"
		},
		{
			type: "image",
			url: require("./Chilli Cheese Steak Sandwich.jpg")
		}
	]
}
