export default {
	title: "Roastbeef Steak",
	rating: "4.5",
	tags: ["discontinued"],
	dish_type: "sandwich",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>hoagie bread roll</li>
					<li>free range beef steak</li>
					<li>Italian Provolone cheese</li>
					<li>caramelized onions in balsamic vinegar</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries (during select hours)</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Steak sandwich to beat.",
			content: `
				<p>This is the one, fellas. This is how you make a steak sandwich. Everyone else should learn from this.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2017"
		},
		{
			type: "image",
			url: require("./Roastbeef Steak.jpg")
		}
	]
}
