export default {
	title: "Premium Burger",
	rating: "2.5",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef steak slices</li>
					<li>BBQ sauce</li>
					<li>cheese</li>
					<li>fried egg</li>
					<li>onion rings</li>
					<li>tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Acceptable, though hardly premium.",
			content: `
				<p>This burger - beef sandwich with a bun, actually - was not inedible by any means. While the meat was well done and a bit dry, everything else was basically OK. Having said that, it also isn't even remotely close to the higher tier burgers on this list.</p>
				<p>There's a big ol' catch, however. About 50m away from the joint where I ate this sandwich there is a <a href="/ranking/hood-burger" class="reference">Hood Burger</a> stand. Out of the two, picking Ajda would be extremely difficult to justify. In fact, I would judge you for it severely.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		},
		{
			type: "image",
			url: require("./Premium.jpg")
		}
	]
}
