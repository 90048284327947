export default {
	title: "Chicken Sandwich",
	rating: "4.5",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["chicken"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>marinated Panko fried chicken</li>
					<li>ranch dressing</li>
					<li>lettuce</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Hands down the best fried chicken sandwich out there.",
			content: `
				<p>And it's even labelled correctly - as a sandwich, not a burger. Which is a rarity.</p>
				<p>So why half a point shy from a perfect score? Two reasons. First, there are pickle slices in this otherwise perfect creation. Second - and more unforgivable - the pickles are not listed in the spec sheet. That's outrageous. If this sandwich wasn't as good as it is I'd deduct half a point. But I simply can't bring myself to do it.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | January 2022"
		},
		{
			type: "image",
			url: require("./Chicken Sandwich.jpg")
		}
	]
}
