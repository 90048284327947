import Klasik from "./Klasik/Klasik.js"
import Prasica from "./Prasica/Prasica.js"
import Eksotik from "./Eksotik/Eksotik.js"

export default {
	title: "Meat Business",
	tags: [],
	burgers: [
		Klasik,
		Prasica,
		Eksotik
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/meatbusinessclub/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
