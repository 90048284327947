import Senor from "./Senor/Senor.js"
import Mejpl from "./Mejp'l/Mejp'l.js"
import Klasik from "./Klasik/Klasik.js"

export default {
	title: "Bazen Bar & BBQ",
	tags: [],
	burgers: [
		Mejpl,
		Senor,
		Klasik
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://bazen-kranj.si/en/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/BazenBarBBQ/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g644300-d10644153-Reviews-Bazen_Bar_BBQ-Kranj_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
