export default {
	title: "Ftrgan Zajc",
	rating: "3",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["rabbit"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>pulled wild rabbit</li>
					<li>meat sauce</li>
					<li>cheese</li>
					<li>marinated onions</li>
					<li>lamb's lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "In search of identity.",
			content: `
				<p>I never ate pulled rabbit meat before. I don't have a baseline. Therefore, I can't tell if this one tasted below or above average. However, this was somewhat underwhelming in my opinion. I don't know if it was poor seasoning or ingredients that didn't complement the meat like they were supposed to but I'm struggling to find a reason to get this over more traditional pulled meats.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | September 2022"
		}
	]
}
