export default {
	title: "Double Smashed Čiz",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>sesame bread bun</li>
					<li>double smashed beef patty</li>
					<li>ketchup</li>
					<li>mustard</li>
					<li>Cheddar cheese</li>
					<li>pickles</li>
					<li>onions</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Basic doesn't have to mean bad.",
			content: `
				<p>Let's be honest: there's nothing really innovative about this burger. The ingredients are as standard as it gets. Plus it's smashed, meaning it's even easier to make.</p>
				<p>And yet, it was delicious. And fairly cheap!</p>
				<p>If only more would follow this example.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | September 2021"
		},
		{
			type: "image",
			url: require("./Double Smashed Ciz.png")
		}
	]
}
