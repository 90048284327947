export default {
	title: "Chicken Burger",
	rating: "2",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["chicken"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>fried chicken fillet</li>
					<li>Alabama sauce</li>
					<li>Gouda cheese</li>
					<li>sweet pickles</li>
					<li>lettuce</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "One of those peculiar cases where the whole is somehow lesser then the sum of its parts.",
			content: `
				<p>First, the pickles. They exist. In this sandwich. Not good.</p>
				<p>Second, the crust on that chicken. It's way to thick. On it's own the chicken would have been fine but not in a burger. It's like having double buns. Not ideal.</p>
				<p>Third, the Alabama sauce. It's slightly too spicey for me but that's a highly personal preference.</p>
				<p>All in all it's not great. If you're looking for a chicken sandwich, I'd go elsewhere. Pop's Place's <a href="/ranking/pops-place/chicken-sandwich" class="reference">Chicken Sandwich</a> or Lars & Sven's <a href="/ranking/lars-sven/crispy-chicken-burger" class="reference">Crispy Chicken Burger</a> spring to mind.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | November 2021"
		},
		{
			type: "image",
			url: require("./Piscancji.png")
		}
	]
}
