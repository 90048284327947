export default {
	title: "Pork-a-Madona Pulled Burger",
	rating: "4",
	tags: ["discontinued"],
	dish_type: "sandwich",
	meat_types: ["pig"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>	
					<li>smoked pulled pork</li>
					<li>house BBQ sauce</li>
					<li>lettuce</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Hate the pun. Love the product.",
			content: `
				<p>One of the better ones out there. The pickle rears its ugly head once again, sure, but it's quite tolerable. The wisely subdued BBQ sauce is noticeable but thankfully does not overpower everything.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2016"
		},
		{
			type: "image",
			url: require("./Pork-a-Madona Pulled Burger.jpg")
		}
	]
}
