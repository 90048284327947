export default {
	title: "Burger z Istrijanom",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>aged beef patty</li>
					<li>truffle sauce</li>
					<li>Brie cheese</li>
					<li>fresh truffles</li>
					<li>fig marmalade</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "I really hope no humans were harmed in the making of this burger...",
			content: `
				<p>For those wondering what the hell I'm talking about, "Istrijan" in Slovenian means "a person from <a href="https://en.wikipedia.org/wiki/Istria" class="reference">Istria</a>". So, you know. I'm worried...</p>
				<p>Anyway.</p>
				<p>To one extent or another, all the burgers at <a href="https://www.pivoinburgerfest.si/" target="_blank" class="callback">Pivo & Burger fest</a> are of lower quality than if you ordered them on their home turf - at the joint. Maybe this one got hit by this effect more than the others.</p>
				<p>Or at least that's what I keep telling myself. Because looking at the specs, this bad boy should have been god damn amazing. Alas.</p>
				<p>While the meat was acceptable and the truffle sauce was fine (though maybe a bit overpowering), I remember exactly one good bite where I was just barely able to taste the fig marmalade. That is a travesty, ladies and gentlemen!</p> 
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | 2018"
		}
	]
}
