import Cheeseburger from "./Cheeseburger/Cheeseburger.js"
import BeefBurger from "./Beef Burger/Beef Burger.js"

export default {
	title: "Burek Olimpija Šiška",
	tags: [],
	burgers: [
		Cheeseburger,
		BeefBurger
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.burekolimpija.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/burekolimpija/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
