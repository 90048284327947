export default {
	title: "Beef Burger",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>BBQ sauce</li>
					<li>maryrose sauce</li>
					<li>Cheddar cheese</li>
					<li>bacon</li>
					<li>onion jam</li>
					<li>tomato</li>
					<li>lettuce</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> Roasted potatoes</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Boring boilerplate burger with one highly peculiar saving grace.",
			content: `
				<p>Right of the bat, I have some stylistic critiques of this as a menu item.</p>
				<p>This burger's official full name is "Beef burger, roasted potatos <span class="emphasized">[sic]</span> and BBQ sauce". There are so many things wrong with this it's hard to pick where to begin. First, you're missing an Oxford comma. To be completely transparent, I barely know how to place commas properly in English sentences. That last one was probably incorrect. Maybe. But I know about Oxford comma, dammit! And I will take every opportunity possible to smugly point out when one is missing so as to appear more scholarly. Second, "potatos" is misspelled. Third, for the love of god, just come up with a real fucking name and slap it on that burger, will you? No normal person likes ordering a dish with a paragraph-long name. And on a final note, this burger came with a pink bun. I don't necessarily mind - looked kind of interesting to be honest - but it conflicts with the picture of the bun they used on Wolt. That's just false advertising.</p>
				<p>Quibbles finally aside, the burger wasn't terrible. It was fairly well balanced and the onion marmalade - as it so often tends to be - was delicious. Plus, it's pretty big.</p>
				<p>But there were some weird... quirks. The bottom bun was weirdly tough to chew through and the beef patty was straight up dense, for the lack of a better word. That's far from desireable. Nor can I help but wonter what this burger would taste like without the BBQ sauce. As it so often does, it tends to obscure most of the nuances I might have otherwise nitpicked.</p>
				<p>At the end of the day, the aforementioned issues make it nigh-impossible to recommend this burger.</p>
				<p>With one highly specific exception. If you're someone who orders burgers as a side dish for their fries - assuming such a weirdo exists - their roasted potatoes were (inexplicably) quite possibly the most delicious I've ever eaten. So there's that.
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | August 2021"
		},
		{
			type: "image",
			url: require("./Beef.jpg")
		}
	]
}
