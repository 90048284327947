import Brgr from "./Brgr/Brgr.js"
import Pandolin from "./Pandolin/Pandolin.js"

export default {
	title: "Pr' Pandi",
	tags: ["closed"],
	burgers: [
		Brgr,
		Pandolin
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://wolt.com/en/svn/ljubljana/restaurant/pr-pandi"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: ""
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: ""
}
