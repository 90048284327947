export default {
	title: "Popeye",
	rating: "4.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>beef patty</li>
					<li>mustard sauce</li>
					<li>Cheddar cheese</li>
					<li>caramelized onions</li>
					<li>fresh spinach</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Best burger I've had in Kranj. Easily.",
			content: `
				<p>Knocked it out of the fucking park. It's honesly hard to decide which one between this and <a href="/ranking/projekt-burger/lord" class="reference">Lord</a> from Projekt is better. For me, Popeye pulls ahead by (very) narrow margin but it is ultimately a metter of preference - which spec sheet appeals to you more.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018"
		},
		{
			type: "image",
			url: require("./Popeye.jpg")
		}
	]
}
