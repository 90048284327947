export default {
	title: "Hiša Pod Gradom",
	rating: "2",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>	
					<li>beef patty</li>
					<li>BBQ sauce</li>
					<li>Nacho cheese</li>
					<li>seared bacon</li>
					<li>caramelized onions</li>
					<li>lettuce</li>
					<li>tomatoes</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Incredibly substandard.",
			content: `
				<p>The only saving grace here was the actually excellently flavoured BBQ sauce. But that's basically cheating in my book. BBQ sauce fixes most things.</p>
				<p>Also insanely priced for how good it isn't.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | May 2021"
		},
		{
			type: "image",
			url: require("./Hisa Pod Gradom.jpg")
		}
	]
}
