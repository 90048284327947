import Taprav from "./Taprav/Taprav.js"

export default {
	title: "Park Žibert Drive-In",
	tags: [],
	burgers: [
		Taprav
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.parkzibertdrivein.com/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/parkzibert/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d4495135-Reviews-Park_Zibert_Drive_in-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
