export default {
	title: "Goveji Bejkn",
	rating: "3",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>BBQ sauce</li>
					<li>cheese</li>
					<li>bacon</li>
					<li>sautéed onions</li>
					<li>rucola</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Flavors unknown.",
			content: `
				<p>Surprisingly - for P&BF burgers - I received a medium rare patty. Nice.</p>
				<p>My praise ends there.</p>
				<p>I wish I could describe what the burger actually tasted like. However, all I can say on that topic is that the BBQ sauce tasted decent. Which is good I guess, seeing how that's all I was able to taste.</p>
				<p>This is a review of texture(s) more than anything else I guess.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | May 2023"
		}
	]
}
