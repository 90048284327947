export default {
	title: "Big Boss Burger",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>Slovenian beef batty</li>
					<li>Paraguayan Black Angus roast beef slices</li>
					<li>truffle mayo</li>
					<li>roasted apple slice</li>
					<li>caramelized onions</li>
					<li>crispy onion bits</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French or sweet potato fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "The whole is greater than the sum of its parts. But I have complaints abt most of those parts.",
			content: `
				<p>I guess I'll do these in order of importance:</p>
				<ol>
					<li>The patty was insufficiently soft. Chewy. Decent seasoning though.</li>
					<li>There were literally two roast beef slices in there. For shame!</li>
					<li>The sauce, though decent-tasting, was very heavy. Deadass consistency of mayo taken straight from the jar. You gotta thin it out, guys.</li>
					<li>I could not detect any crispy onion bits.</li>
				</ol>
				<p>Even so, on the whole, the burger worked. Somehow.</p>
				<p>Would not not recommend.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | July 2023"
		},
		{
			type: "image",
			url: require("./Big Boss.png")
		}
	]
}
