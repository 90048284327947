export default {
	title: "The Principal",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>	
					<li>beef patty</li>
					<li>thousand islands sauce</li>
					<li>Cheddar cheese</li>
					<li>pancetta</li>
					<li>lettuce</li>
					<li>tomato</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "True to its name, many of Zbornica's competitors could stand to learn a thing or two from this excellent burger.",
			content: `
				<p>About a week before this review, I have never heard of Zbornica before. Which is baffling to me considering <span class="emphasized">The Principal</span> here would probably make my top 10 burgers list <span class="emphasized" style="font-size: 80%;">(Hi, yours truly from the future here; while the "top 10" claim no longer holds, this is still an excellent burger.)</span>. I've been asking around and nobody I know (except for the friend who told me about them) knows about this place. It's weird.</p>
				<p>Anyway. The burger is nothing special in terms of specs. However, the chefs at Zbornica bring everything together as well as can be expected. One could tweak the meat blend a bit or enhanced the sauce somehow but these are, at the end of the day, nitpicks.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | June 2021"
		},
		{
			type: "image",
			url: require("./The Principal.jpg")
		}
	]
}
