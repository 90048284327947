import Klasik from "./Klasik/Klasik.js"
import Piscancji from "./Piscancji/Piscancji.js"

export default {
	title: "Biljardnica Direkt",
	tags: [],
	burgers: [
		Klasik,
		Piscancji
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/Biljardnica-Direkt-428517040854109/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g274873-d8731385-Reviews-Biljardnica_Direkt_Billiards_Pub-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
