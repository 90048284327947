export default {
	title: "Smokey Burger",
	rating: "3.5",
	tags: [],
	dish_type: "sandwich",
	meat_types: ["pig"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>smoked ground pork</li>
					<li>BBQ sauce</li>
					<li>pancetta</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Ground pork sandwich that's actually good? A rare find indeed.",
			content: `
				<p>Go look at my review of <a href="/ranking/lars-sven/smokey-burger" class="reference">Smokey Burger</a> from Lars & Sven. This bad boy is exactly the opposite.</p>
				<p>It has half the ingredients and twice the flavour. The patty is fucking great. The barbecue sauce is just the way I like it. Pancetta (instead of bacon) gives it an extra kick. And even the pickle, the menace that plagues many a sandwich (this is not really a burger, <span class="emphasized">#neverforget</span>) actually complements the taste here. If you don't like beef for some reason try this. But also never talk to me again.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2019"
		},
		{
			type: "image",
			url: require("./Smokey.jpg")
		}
	]
}
