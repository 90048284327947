export default {
	title: "Pop's Original Burger",
	rating: "4",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>brioche bun</li>
					<li>beef patty</li>
					<li>thousand islands sauce</li>
					<li>Stilton cheese</li>
					<li>apple slice</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A delightful change of pace.",
			content: `
				<p>The mention of apple slices on the spec sheet caught my inner skeptic's eye. After a brief internal struggle I gave in and ordered the burger. That decision was swiftly proven correct.
				<p>The apple slice adds a nice, rare, sweet (obviously) twist. It's actually a surprisingly welcome addition, especially if - like me - you sometimes tire of same old standard burger tastes.</p>
				<p>If you don't want to order Pop's flagship, <a href="/ranking/pops-place/the-boss" class="reference">The Boss</a>, because you dislike caramelized onions or the smell of truffles... well, you should reevaluate your life, actually. But in the meantime, try this. It has a more mainstream sauce but retains Pop's superior beef patty and bun.<p>
				<p>No regrets.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | February 2023"
		},
		{
			type: "image",
			url: require("./Pop's Original.jpg")
		}
	]
}
