import Bilijarder from "./Bilijarder/Bilijarder.js"
import TrganaSvinjina from "./Trgana Svinjina/Trgana Svinjina.js"

export default {
	title: "Biljardna Hiša Ljubljana",
	tags: [],
	burgers: [
		Bilijarder,
		TrganaSvinjina
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://www.biljardna-hisa.com"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/biljardnaSlovenska/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Attraction_Review-g274873-d20063024-Reviews-Biljardna_hisa_Ljubljana_Slovenska-Ljubljana_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
