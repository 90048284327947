export default {
	title: "Angus Burger",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>charcoal bread bun</li>
					<li>Angus beef patty</li>
					<li>BBQ sauce</li>
					<li>Brie cheese</li>
					<li>Kras bacon</li>
					<li>caramelized onions</li>
					<li>rucola</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Unremarkable.",
			content: `
				<p>Not much to say. No more than average by today's standards. The caramelized onions are the only slightly noteworthy thing about it</p>
				<p>It is a big boi though. Which would have been a point in its favour. But it's a wash. Because it's expensive. Very. It might actually be the most expensive burger I've ever ordered (would have to check). It's to the point where this is one of those rare cases where I'm actually deducting ½ point for price/performance deficit. Which I guess contradicts my initial intro. This ultimately <span class="emphasized">is</span> a remarkable burger. Remarkably not worth it.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | November 2021"
		},
		{
			type: "image",
			url: require("./Angus.png")
		}
	]
}
