export default {
	title: "Bacon Burger",
	rating: "2.5",
	tags: ["discontinued"],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Review",
			lede: "Naaah.",
			content: `
				<p>It's a decent enough burger, I guess. Again, obligatory +½ point for bacon, but honestly, I found it a bit... fast food-y. The beef bland (Freudian slip) is unremarkable and the rest of the ingredients hardly bring it to life. More like drown it out, if anything - it has ketchup, after all. Which is a problem in and of itself, I would argue.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2015"
		},
		{
			type: "image",
			url: require("./Bacon.jpg")
		}
	]
}
