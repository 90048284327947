export default {
	title: "Mr. Big",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>house sauce</li>
					<li>Cheddar cheese</li>
					<li>onion jam</li>
					<li>lettuce</li>
					<li>pickles</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "On the edge of adequate.",
			content: `
				<p>Nothing I haven't already tasted elsewhere. The meat was a bit chewy, albeit they did manage to grill it medium rare. Everything else was boringly unobjectionable (pickles not withstanding).</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | May 2022"
		},
		{
			type: "image",
			url: require("./MrBig.png")
		}
	]
}
