export default {
	title: "Mangoop Burger",
	rating: "3",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>mangoop sauce</li>
					<li>Cheddar cheese</li>
					<li>crispy bacon</li>
					<li>tomato</li>
					<li>onions</li>
					<li>pickles</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Solid.",
			content: `
				<p>... is the word that comes to mind as one nibbles on this. It doesn't offend in any way, the ingredients work well and it's properly prepared. It also doesn't stand out in any way. Snobs better look elsewhere. For everyone else, this is acceptable.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | 2019 (Uncertain)"
		},
		{
			type: "image",
			url: require("./Mangoop.jpg")
		}
	]
}
