import Frks from "./Frks/Frks.js"

export default {
	title: "Frks",
	tags: ["closed"],
	burgers: [
		Frks
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "https://frks.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/frks.si/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: ""
		}
	],
	review: "Meh."
}
