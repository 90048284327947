export default {
	title: "Porchetta Burger",
	rating: "4",
	tags: ["discontinued"],
	dish_type: "sandwich",
	meat_types: ["pig"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>baked crunchy pork belly</li>
					<li>burnt apple mayonnaise</li>
					<li>cracknels</li>
					<li>roasted cabbage</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "If there was ever an argument for central planning it's that the market does not organically offer enough pork belly sandwiches.",
			content: `
				<p>The specs of this <span class="emphasized">sandwich</span> were so fancy-sounding I couldn't not order this immediately. Burnt apple mayonnaise? I've never even seen words like these in the same paragraph! I grew cautiously optimistic.</p>
				<p>The dish greatly exceeded my expectations. The pork belly was fantastic and all the other ingredients played together almost perfectly. And it's cheap to boot!</p>
				<p>The only reason I did not give it a higher score is that Trappa's own <a href="/ranking/trappa/pulled-pork-burger" class="reference">Pulled Pork Burger</a> (also actually a sandwich) overall outpaces it slightly. But make no mistake - this is a winner.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | July 2021"
		},
		{
			type: "image",
			url: require("./Porchetta.jpg")
		}
	]
}
