export default {
	title: "Krpan Bacon Burger",
	rating: "3.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>beef patty</li>
					<li>dressing</li>
					<li>Cheddar cheese</li>
					<li>lettuce</li>
					<li>tomato</li>
					<li>onions</li>
					<li>pancetta</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "A pleasant surprise.",
			content: `
				<p>I caught a glimpse of a picture of a burger in Fany & Mary's window as I was walking by. I did not know they made burgers and thus my curiosity was piqued.</p>
				<p>I was expecting a generic but passable fast food burger. I primarily pegged Fany & Mary for a pub, after all. Instead, I got this bad boy. This burger is based on their own <a href="/ranking/fany-mary/classic-burger" class="reference">Classic Burger</a> but with a crucial difference: it comes equipped with tomato and - *drumroll* - bacon. That, I would argue, makes it their flagship. And a worthy one at that.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Delivery | August 2021"
		},
		{
			type: "image",
			url: require("./Krpan Bacon.jpg")
		}
	]
}
