import BurgerzIstrijanom from "./Burger z Istrijanom/Burger z Istrijanom.js"

export default {
	title: "Urban Piqniq",
	tags: [],
	burgers: [
		BurgerzIstrijanom
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: ""
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/urban.piqniq.bonifika.koper/"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g312722-d10792465-Reviews-Urban_Piqniq-Koper_Slovenian_Istria_Slovenian_Littoral_Region.html"
		}
	],
	review: ""
}
