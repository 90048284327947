export default {
	title: "Angus Burger",
	rating: "2.5",
	tags: [],
	dish_type: "burger",
	meat_types: ["cow"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>Angus beef patty</li>
					<li>bacon</li>
					<li>sauce</li>
					<li>cheese</li>
					<li>sautéed onions</li>
					<li>tomato</li>
					<li>lettuce</li>
				</ul>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Painfully run of the mill.",
			content: `
				<p>It's not terrible but I would go for something else given choice.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "Pivo & Burger Fest | October 2018"
		},
		{
			type: "image",
			url: require("./Angus.jpg")
		}
	]
}
