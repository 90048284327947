import Bastardo from "./Bastardo/Bastardo.js"
import Cheez from "./Cheez/Cheez.js"
import Lord from "./Lord/Lord.js"
import Popeye from "./Popeye/Popeye.js"

export default {
	title: "Projekt Burger",
	tags: [],
	burgers: [
		Popeye,
		Lord,
		Cheez,
		Bastardo
	],
	links: [
		{
			icon: "icon_WEB",
			title: "Website",
			url: "http://www.projektburger.si/"
		},
		{
			icon: "icon_FB",
			title: "Facebook",
			url: "https://www.facebook.com/projektburger.si"
		},
		{
			icon: "icon_TA",
			title: "Tripadvisor",
			url: "https://www.tripadvisor.com/Restaurant_Review-g644300-d8683682-Reviews-Projekt_Burger-Kranj_Upper_Carniola_Region.html"
		}
	],
	review: ""
}
