export default {
	title: "Pulled Pork",
	rating: "3.5",
	tags: ["special-edition"],
	dish_type: "sandwich",
	meat_types: ["pig"],
	sections: [
		{
			type: "html",
			title: "Config",
			content: `
				<ul>
					<li>bread bun</li>
					<li>pulled pork</li>
					<li>coleslaw</li>
					<li>iceberg lettuce</li>
				</ul>
				<div class="extras"><strong>Extras:</strong> French fries</div>
			`
		},
		{
			type: "html",
			title: "Review",
			lede: "Gratifying, though very rarely available.",
			content: `
				<p>This is pretty close to perfect, in all honesty. The pork is top notch, the coleslaw is nearly perfect, the bun is exactly what it should be. But it lacks a bit of... oomph.</p>
				<p>Yeah, that's right. I said "oomph". Would I care to be more specific? No. That would require further in-depth analysis that I'm not being compensated for. In fact, I'm not being compensated for any of this! Which means that by reading this, you're basically a freeloader. Shame on you.</p>
			`
		},
		{
			type: "text",
			title: "Last tested",
			content: "On Location | 2018"
		}
	]
}
